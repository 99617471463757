import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ConfettiExplosion from "react-confetti-explosion";
import styles from "./styles.module.scss";
import { useGetUserInfoQuery } from "../../services/auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import RewardDetail from "./RewardDetail";
import { useGetRewardListQuery } from "../../services/luckyWheel";
import { useWindowSize } from "@react-hook/window-size";
import MobileModal from "../Common/MobileModal";

const RewardModal = ({ openModal, handleClose, reward, particleCount }) => {
	const [width] = useWindowSize();
	const [t] = useTranslation("common");
	const { data: rewardListData } = useGetRewardListQuery();
	const { data: user } = useGetUserInfoQuery();

	const navigate = useNavigate();

	const getPrizeLocation = (prize) => {
		switch (prize) {
			case "TOKEN":
				return (
					<div>
						<span>{t("luckyWheel.sentPrize")}</span>{" "}
						<a href="/wallet-page">{t("yourWallet")}</a>
					</div>
				);
			case "AVATAR":
				return (
					<div>
						<span>{t("luckyWheel.sentPrize")}</span>{" "}
						<a href="/account-page">
							{t("luckyWheel.yourProfile")}
						</a>
					</div>
				);
			case "POINT":
				return (
					<div>
						<span>{t("luckyWheel.sentPrize")}</span>{" "}
						<a href="/account-page">
							{t("luckyWheel.yourProfile")}
						</a>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<>
			{width > 1200 ? (
				<Modal show={openModal} onHide={handleClose}>
					<div className={styles.rewardModal}>
						<ConfettiExplosion
							force={0.6}
							duration={2500}
							width={1000}
							zIndex={9999999999}
							particleCount={particleCount}
						/>
						<div className={styles.rewardModalDetail}>
							<div>{t("luckyWheel.congrats")}</div>
							<RewardDetail reward={reward} />
							{getPrizeLocation(reward?.type)}
							<div>{t("luckyWheel.check")}</div>
						</div>
						{user ? (
							<button className="btnDark" onClick={handleClose}>
								{t("luckyWheel.gotIt")}
							</button>
						) : (
							<button
								className="btnDark"
								onClick={() => navigate("/sign-up")}
							>
								{t("luckyWheel.registerToClaim")}
							</button>
						)}
					</div>
				</Modal>
			) : (
				<MobileModal
					open={openModal}
					close={handleClose}
					title={t("luckyWheel.luckyWheel")}
				>
					<div className={styles.rewardModal}>
						<ConfettiExplosion
							force={0.6}
							duration={2500}
							width={1000}
							zIndex={9999999999}
							particleCount={particleCount}
						/>
						<div className={styles.rewardModalDetail}>
							<div>{t("luckyWheel.congrats")}</div>
							<RewardDetail reward={reward} />
							{getPrizeLocation(reward?.type)}
							<div>{t("luckyWheel.check")}</div>
						</div>
						{user ? (
							<button className="btnDark" onClick={handleClose}>
								{t("luckyWheel.gotIt")}
							</button>
						) : (
							<button
								className="btnDark"
								onClick={() => navigate("/sign-up")}
							>
								{t("luckyWheel.registerToClaim")}
							</button>
						)}
					</div>
				</MobileModal>
			)}
		</>
	);
};

export default RewardModal;
