import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import TooltipComponent from "../../Component/Common/TooltipComponent";
import PNLOverview from "../../Component/PNL/PNLOverview";
import { useNavigate } from "react-router";
import PNLCharts from "../../Component/PNL/PNLCharts";
import { useGetUserInfoQuery } from "../../services/auth";
import LoadingDot from "../../Component/Animation/LoadingDot";
import autoAnimate from "@formkit/auto-animate";

const PNL = () => {
	const [t] = useTranslation("wallet");
	const navigate = useNavigate();

	const { data: user } = useGetUserInfoQuery();

	const parent = useRef(null);

	useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent]);

	return (
		<div className={styles.pnlContainer} ref={parent}>
			<div onClick={() => navigate("/wallet-page")}>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<mask
						id="mask0_803_5915"
						maskUnits="userSpaceOnUse"
						x="0"
						y="0"
						width="24"
						height="24"
					>
						<rect width="24" height="24" fill="#D9D9D9" />
					</mask>
					<g mask="url(#mask0_803_5915)">
						<path
							d="M12.2742 19.3C12.4576 19.1167 12.5536 18.8833 12.5622 18.6C12.5702 18.3167 12.4826 18.0833 12.2992 17.9L7.39922 13H18.5742C18.8576 13 19.0952 12.904 19.2872 12.712C19.4786 12.5207 19.5742 12.2833 19.5742 12C19.5742 11.7167 19.4786 11.479 19.2872 11.287C19.0952 11.0957 18.8576 11 18.5742 11H7.39922L12.2992 6.1C12.4826 5.91667 12.5702 5.68334 12.5622 5.4C12.5536 5.11667 12.4576 4.88334 12.2742 4.7C12.0909 4.51667 11.8576 4.425 11.5742 4.425C11.2909 4.425 11.0576 4.51667 10.8742 4.7L4.27422 11.3C4.17422 11.3833 4.10322 11.4873 4.06122 11.612C4.01989 11.7373 3.99922 11.8667 3.99922 12C3.99922 12.1333 4.01989 12.2583 4.06122 12.375C4.10322 12.4917 4.17422 12.6 4.27422 12.7L10.8742 19.3C11.0576 19.4833 11.2909 19.575 11.5742 19.575C11.8576 19.575 12.0909 19.4833 12.2742 19.3Z"
							fill="#939393"
						/>
					</g>
				</svg>

				<span>{t("pnl.back")}</span>
			</div>
			<div>
				<TooltipComponent text={t("pnl.titleTooltip")}>
					<div className={styles.title}>
						<div>{t("pnl.title")}</div>

						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2Z"
								fill="#FDFCFF"
								stroke="#363638"
							/>
							<circle cx="12" cy="7" r="1" fill="#363638" />
							<rect
								x="11"
								y="10"
								width="2"
								height="8"
								rx="1"
								fill="#363638"
							/>
						</svg>
					</div>
				</TooltipComponent>
			</div>

			{user ? (
				<>
					<PNLOverview />
					<PNLCharts />
				</>
			) : (
				<LoadingDot />
			)}
		</div>
	);
};

export default PNL;
