import React from "react";
import { Link } from "react-router-dom";
import Img1m from "../../assets/images/not_found.svg";

function ErrorPage() {
	return (
		<>
			<div className="body d-flex p-0 p-xl-5">
				<div className="container">
					<div className="d-flex align-items-center justify-content-center">
						<img src={Img1m} className="img-fluid" alt="#" />
					</div>
				</div>
			</div>
		</>
	);
}

export default ErrorPage;
