import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import Table from "../Common/Table";
import DataTable from "react-data-table-component";
import { useGetWalletTransactionsQuery } from "../../services/wallet";
import { numberFormat } from "../../utils/formatNumber";
import CopyComponent from "../Common/CopyComponent";
import { useWindowWidth } from "@react-hook/window-size";

const TransactionHistory = ({ type }) => {
	const [t] = useTranslation("wallet");
	const width = useWindowWidth();
	const [page, setPage] = useState(1);
	const { data: walletTxs, refetch } = useGetWalletTransactionsQuery({
		skip: (page - 1) * 10,
		types: type,
	});
	const paginationOptions = {
		noRowsPerPage: true,
		rangeSeparatorText: "/",
	};
	const TransactionData = {
		columns: [
			{
				name: t("time"),
				selector: (row) => row.createdAt,
				sortable: true,
				maxWidth: "350px",
				// cell: (row) => <a href="#!" className="fw-bold text-secondary">{row.id}</a>
			},
			{
				name: "Coin",
				selector: (row) => row.tokenSymbol,
				sortable: true,
				maxWidth: "200px",
				cell: (row) => (
					<span className="heading5">{row.tokenSymbol}</span>
				),
			},
			{
				name: t("quantity"),
				selector: (row) => numberFormat(row.tokenAmount),
				sortable: true,
				maxWidth: "250px",
			},
			{
				name: t("type"),
				selector: (row) =>
					row.type === "IN" ? t("deposit") : t("withdraw"),
				sortable: true,
				maxWidth: "200px",
				cell: (row) => (
					<>
						<span
							className={
								row.type === "IN" ? "buyBadge" : "sellBadge"
							}
						>
							{row.type === "IN" ? t("deposit") : t("withdraw")}
						</span>
					</>
				),
			},

			{
				name: "Hash",
				selector: (row) => row.txnHash,
				cell: (row) => (
					<CopyComponent
						text={
							row.txnHash
								? `${row.txnHash.substring(0, 5)}...
							${row.txnHash.substring(row.txnHash.length - 5)}`
								: ""
						}
						copyText={row.txnHash}
					/>
				),
				sortable: true,
			},
		],
	};

	const handlePageChange = (newPage) => {
		setPage(newPage);
	};

	useEffect(() => {
		const timer = setInterval(() => {
			refetch();
		}, 10000);
		return () => clearInterval(timer);
	}, []);

	return (
		<>
			{width > 1200 ? (
				<DataTable
					pagination
					paginationServer
					paginationComponentOptions={paginationOptions}
					title={TransactionData.title}
					columns={TransactionData.columns}
					data={walletTxs?.data}
					defaultSortField="title"
					selectableRows={false}
					highlightOnHover={true}
					className="historyTable"
					paginationTotalRows={walletTxs?.count}
					onChangePage={handlePageChange}
				/>
			) : (
				<div>
					{walletTxs?.data?.map((data) => (
						<HistoryItem key={data?.id} data={data} />
					))}
				</div>
			)}
		</>
	);
};

const HistoryItem = ({ data }) => {
	const [t] = useTranslation("wallet");

	return (
		<div className={styles.listItem}>
			<div>
				<div>
					<div>{data?.tokenSymbol}</div>
					<span
						className={
							data.type === "IN" ? "buyBadge" : "sellBadge"
						}
					>
						{data.type === "IN" ? t("deposit") : t("withdraw")}
					</span>
				</div>
			</div>
			<div>
				<div>
					<div>{t("time")}</div>
					<div>{data?.createdAt}</div>
				</div>
				<div>
					<div>{t("quantity")}</div>
					<divd>{numberFormat(data?.tokenAmount)}</divd>
				</div>
				<div>
					<div>Hash</div>
					<CopyComponent
						text={
							data.txnHash
								? `${data.txnHash.substring(0, 5)}...
							${data.txnHash.substring(data.txnHash.length - 5)}`
								: ""
						}
						copyText={data.txnHash}
					/>
				</div>
			</div>
		</div>
	);
};

export default TransactionHistory;
