import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getBuyOrder, updateBuyOrder, updateSellOrder } from "../../Store/spot";
import { connectToSocket } from "./ConnectWss";
import { updateOrderDetail } from "../../Store/spot";
import { insertSorted } from "../../utils/array";
import styles from "./styles.module.scss";
import { numberFormat } from "../../utils/formatNumber";

function OrderBook(props) {
	const dispatch = useDispatch();
	const [askOrderTrans, setAskOrderTrans] = useState([]);
	const [bidsOrderTrans, setBidsOrderTrans] = useState([]);
	const [depth, setDepth] = useState({});

	const [lastUpdateId, setLastUpdateId] = useState(0);
	const [asks, setAsks] = useState([]);
	const [bids, setBids] = useState([]);
	const [updateData, setUpdateData] = useState();

	const balance = useSelector((state) => state.spot.balance);

	const { currentPrice } = useSelector((state) => state.spot.priceChange24h);

	const [price, setPrice] = useState(0);
	const [previousPrice, setPreviousPrice] = useState(0);

	const [t] = useTranslation("trade");

	const modifyBids = (orders) => {
		return orders
			.slice(-10)
			.map((order) => ({
				price: financial(
					order[0],
					order[0].toString().split(".")[1].length
				),
				quantity: financial(order[1], 2),
				total: financial(order[0] * order[1], 3),
			}))
			.reverse();
	};

	const modifyAsks = (orders) => {
		return orders.slice(-10).map((order) => ({
			price: financial(
				order[0],
				order[0].toString().split(".")[1].length
			),
			quantity: financial(order[1], 2),
			total: financial(order[0] * order[1], 3),
		}));
	};

	useEffect(() => {
		setPreviousPrice(price);
		setPrice(currentPrice);
	}, [currentPrice]);

	useEffect(() => {
		const getDepth = async () => {
			const res = await fetch(
				`https://api.binance.com/api/v3/depth?symbol=${props.symbolOrderBook}&limit=1000`
			);
			// Get original depth snapshot
			const data = await res.json();

			setLastUpdateId(data.lastUpdateId);
			setAsks(
				data.asks.sort((a, b) => {
					return parseFloat(b[0]) - parseFloat(a[0]);
				})
			);
			setBids(
				data.bids.sort((a, b) => {
					return parseFloat(a[0]) - parseFloat(b[0]);
				})
			);
		};
		getDepth();
	}, []);

	useEffect(() => {
		const ws = new WebSocket(
			`wss://stream.binance.com:9443/ws/${props.symbolOrderBook.toLowerCase()}@depth`
		);

		ws.onmessage = (e) => {
			const data = JSON.parse(e.data);

			if (data.e === "depthUpdate" && data.u > lastUpdateId) {
				setUpdateData(data);
			}
		};

		return () => {
			if (ws.readyState === 1) {
				ws.close();
			}
		};
	}, []);

	useEffect(() => {
		if (updateData) {
			const newAsks = [...updateData.a];
			const newBids = [...updateData.b];
			let currA = [...asks];
			let currB = [...bids];
			if (currA?.length > 0) {
				newAsks.forEach((element) => {
					const idx = currA.findIndex((el) => el[0] === element[0]);
					if (idx > 0) {
						if (parseFloat(element[1]) === 0.0) {
							currA.splice(idx, 1);
						} else {
							currA[idx] = element;
						}
					} else if (parseFloat(element[1]) !== 0.0) {
						currA = insertSorted([...currA], element, (a, b) => {
							return parseFloat(b[0]) - parseFloat(a[0]);
						});
					}
				});
			}
			if (currB?.length > 0) {
				newBids.forEach((element) => {
					const idx = currB.findIndex((el) => el[0] === element[0]);
					if (idx > 0) {
						if (parseFloat(element[1]) === 0) {
							currB.splice(idx, 1);
						} else {
							currB[idx] = element;
						}
					} else if (parseFloat(element[1]) !== 0.0) {
						currB = insertSorted([...currB], element, (a, b) => {
							return parseFloat(a[0]) - parseFloat(b[0]);
						});
					}
				});
			}

			setAsks(currA);
			setBids(currB);
		}
	}, [updateData]);

	useEffect(() => {
		setAskOrderTrans(modifyAsks(asks));
	}, [asks]);

	useEffect(() => {
		setBidsOrderTrans(modifyBids(bids));
	}, [bids]);

	function financial(x, n) {
		while (1) {
			if (Number.parseFloat(x).toFixed(n) > 0) {
				return Number.parseFloat(x).toFixed(n);
			}
			n += 1;
		}
	}

	function totalQuantityAsk() {
		let total = 0;
		for (let i = 0; i < askOrderTrans.length; i++) {
			total += Number(askOrderTrans[i].quantity);
		}
		return total;
	}

	function totalQuantityBids() {
		let total = 0;
		for (let i = 0; i < bidsOrderTrans.length; i++) {
			total += Number(bidsOrderTrans[i].quantity);
		}
		return total;
	}

	function getPercentageAsk(data) {
		let fillPercentage = (Number(data) / totalQuantityAsk()) * 100;
		fillPercentage = Math.min(fillPercentage, 100); // Percentage can't be greater than 100%
		fillPercentage = Math.max(fillPercentage, 0); // Percentage can't be smaller than 0%
		return fillPercentage;
	}

	function getPercentageBids(data) {
		let fillPercentage = (Number(data) / totalQuantityBids()) * 100;
		fillPercentage = Math.min(fillPercentage, 100); // Percentage can't be greater than 100%
		fillPercentage = Math.max(fillPercentage, 0); // Percentage can't be smaller than 0%
		return fillPercentage;
	}

	const setPriceBuy = (row) =>
		dispatch(
			updateOrderDetail({
				price: parseFloat(row.price),
				quantity: 0,
				range: 0,
				total: 0,
				tradeType: "LIMIT",
			})
		);

	const setPriceSell = (row) =>
		dispatch(
			updateOrderDetail({
				price: parseFloat(row.price),
				quantity: 0,
				range: 0,
				total: 0,
				tradeType: "LIMIT",
			})
		);

	const OrderbookDataAsk = {
		columns: [
			{
				id: "price",
				name: t("price"),
				selector: (row) => row.price,

				cell: (row) => (
					<span
						className="priceDown"
						onClick={() => setPriceSell(row)}
					>
						{numberFormat(row.price)}
					</span>
				),
			},
			{
				name: t("amountLabel"),
				selector: (row) => row.quantity,

				center: true,
			},
			{
				name: t("total"),
				selector: (row) => row.total,

				right: true,
				cell: (row) => (
					<div
						className="color-row-orderbook"
						onClick={() => setPriceSell(row)}
					>
						<div className="z-top">{numberFormat(row.total)}</div>
						<div
							className="fill-ask"
							style={{
								width: getPercentageAsk(row.quantity) * 3 + "%",
							}}
						></div>
					</div>
				),
			},
		],
	};

	const OrderbookDataBids = {
		columns: [
			{
				id: "price",
				selector: (row) => row.price,

				cell: (row) => (
					<span className="priceUp" onClick={() => setPriceBuy(row)}>
						{numberFormat(row.price)}
					</span>
				),
			},
			{
				selector: (row) => row.quantity,
				center: true,
			},
			{
				selector: (row) => row.total,
				right: true,
				cell: (row) => (
					<div
						className="color-row-orderbook"
						onClick={() => setPriceBuy(row)}
					>
						<div className="z-top">{numberFormat(row.total)}</div>
						<div
							className="fill-bid"
							style={{
								width:
									getPercentageBids(row.quantity) * 3 + "%",
							}}
						></div>
					</div>
				),
			},
		],
	};

	return (
		<div className="contentContainerMedium">
			<div className={styles.orderbook}>
				<div>
					{askOrderTrans?.length > 0 ? (
						<DataTable
							columns={OrderbookDataAsk.columns}
							data={askOrderTrans}
							defaultSortField="title"
							selectableRows={false}
							highlightOnHover={true}
							onRowClicked={(row) => {
								setPriceSell(row);
							}}
							className="depthTable"
						/>
					) : null}
				</div>
				<div className="orderbook-ticker">
					<span
						className={`price${
							price >= previousPrice ? "Up" : "Down"
						}`}
					>
						{numberFormat(price)}
					</span>
				</div>
				<div>
					{bidsOrderTrans?.length > 0 ? (
						<DataTable
							columns={OrderbookDataBids.columns}
							data={bidsOrderTrans}
							defaultSortField="price"
							defaultSortAsc={false}
							selectableRows={false}
							onRowClicked={(row) => {
								setPriceBuy(row);
							}}
							highlightOnHover={true}
							className="depthTable"
						/>
					) : null}
				</div>
			</div>
		</div>
	);
}

export default OrderBook;
