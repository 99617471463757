import { createSlice } from "@reduxjs/toolkit";

export const Common = createSlice({
	name: "common",
	initialState: {
		changeData: [],
	},
	reducers: {
		updatePriceChange: (state, action) => {
			state.changeData = action.payload;
		},
	},
});

export const { updatePriceChange } = Common.actions;

export default Common.reducer;
