import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useLocation } from "react-router";
import {
	formatPercent,
	numberFormatShort,
	numberFormat,
} from "../../utils/formatNumber";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { changeProMode } from "../../Store/spot";
import TooltipComponent from "../Common/TooltipComponent";
import SelectPair from "./SelectPair";
import { togglePairSelect } from "../../Store/spot";

const ProPriceDetail = () => {
	const isOpenPairSelect = useSelector(
		(state) => state.spot.isOpenPairSelect
	);
	const [t] = useTranslation("trade");
	const search = useLocation().search;

	const [baseAsset, quoteAsset] = new URLSearchParams(search)
		.get("token")
		.split("-");

	const isPro = useSelector((state) => state.spot.isPro);
	const dispatch = useDispatch();

	return (
		<>
			<div className={styles.proHead}>
				<TooltipComponent text={t("change")}>
					<button
						className={styles.changePairBtn}
						onClick={() => dispatch(togglePairSelect(true))}
					>
						<span>{baseAsset + "/" + quoteAsset}</span>
						<svg
							width="22"
							height="20"
							viewBox="0 0 22 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M16.9704 19.1872C17.1092 19.326 17.2889 19.3954 17.4726 19.3954C17.6481 19.3954 17.8277 19.33 17.9625 19.1953L20.7916 16.4274C20.9263 16.2968 20.9998 16.1171 20.9998 15.9253C20.9998 15.7334 20.9223 15.5578 20.7916 15.4231L17.9625 12.6552C17.6849 12.3858 17.244 12.3898 16.9704 12.6634C16.701 12.941 16.7051 13.3819 16.9786 13.6554L18.5789 15.2231H16.803C15.7212 15.2231 14.7047 14.7454 14.0066 13.9167L7.84204 5.52722C6.89899 4.19225 5.3599 3.39209 3.72691 3.39209H1.70201C1.31417 3.39209 1.00391 3.70644 1.00391 4.09427C1.00391 4.48211 1.31826 4.79238 1.70201 4.79238H3.72691C4.90674 4.79238 6.02126 5.368 6.70711 6.34371L12.8961 14.7658C12.9043 14.7781 12.9125 14.7863 12.9206 14.7985C13.8841 15.962 15.2966 16.6315 16.8071 16.6315H18.583L16.9827 18.1992C16.701 18.4686 16.6969 18.9095 16.9704 19.1872Z"
								fill="currentColor"
								stroke="currentColor"
								strokeWidth="0.75"
							/>
							<path
								d="M20.7877 3.59237L17.9422 0.804049C17.6646 0.534606 17.2237 0.538688 16.9502 0.812214C16.6808 1.08982 16.6848 1.53073 16.9584 1.80425L18.5791 3.39233H16.8032C15.2968 3.39233 13.8802 4.05777 12.9167 5.22128C12.9086 5.22944 12.9004 5.24169 12.8922 5.25394L11.7328 6.83385C11.5042 7.14412 11.5695 7.58502 11.8839 7.81364C12.0104 7.90346 12.1533 7.94836 12.2962 7.94836C12.5126 7.94836 12.7248 7.85038 12.8596 7.66259L14.0067 6.10309C14.7008 5.27027 15.7173 4.7967 16.8032 4.7967H18.5791L16.9584 6.38478C16.6808 6.65422 16.6767 7.09921 16.9502 7.37682C17.089 7.51562 17.2686 7.58502 17.4523 7.58502C17.6279 7.58502 17.8075 7.51971 17.9422 7.38498L20.7877 4.59666C20.9224 4.46602 20.9959 4.28639 20.9959 4.09452C20.9959 3.90264 20.9224 3.72301 20.7877 3.59237Z"
								fill="currentColor"
								stroke="currentColor"
								strokeWidth="0.75"
							/>
							<path
								d="M7.83813 14.4968L8.85466 13.1169C9.08328 12.8066 9.01796 12.3657 8.70361 12.1371C8.39335 11.9085 7.95244 11.9738 7.72382 12.2882L6.7032 13.6803C6.69912 13.6884 6.69504 13.6925 6.69096 13.7007C6.00919 14.656 4.89875 15.2275 3.723 15.2275H1.6981C1.31027 15.2275 1 15.5419 1 15.9297C1 16.3175 1.31435 16.6278 1.6981 16.6278H3.723C5.35599 16.6278 6.89508 15.8317 7.83813 14.4968Z"
								fill="currentColor"
								stroke="currentColor"
								strokeWidth="0.75"
							/>
						</svg>
					</button>
				</TooltipComponent>
				<ChangeDetail />

				<div>
					<Form.Check
						type="switch"
						id="pro-switch"
						onChange={() => {
							dispatch(changeProMode(!isPro));
						}}
						checked={true}
					/>
					<span>Pro Mode</span>
				</div>
			</div>
			{isOpenPairSelect ? (
				<>
					<SelectPair
						isOpen={isOpenPairSelect}
						close={() => dispatch(togglePairSelect(false))}
					/>
				</>
			) : null}
		</>
	);
};

const ChangeDetail = () => {
	const search = useLocation().search;

	const [baseAsset, quoteAsset] = new URLSearchParams(search)
		.get("token")
		.split("-");
	const [t] = useTranslation("trade");

	const [previousPrice, setPreviousPrice] = useState(0);

	const [price, setPrice] = useState(0);

	const {
		currentPrice,
		priceChangePercent,
		highPrice,
		lowPrice,
		volume,
		quoteVolume,
	} = useSelector((state) => state.spot.priceChange24h);

	useEffect(() => {
		setPrice(currentPrice);
		setPreviousPrice(price);
	}, [currentPrice]);
	return (
		<div>
			<div>
				<span
					className={previousPrice >= price ? "priceDown" : "priceUp"}
				>
					{numberFormat(currentPrice)}
				</span>
			</div>
			<div className={styles.change24hItem}>
				<span>{t("24hchange")}</span>
				<span
					className={
						priceChangePercent[0] === "-" ? "priceDown" : "priceUp"
					}
				>
					{formatPercent(priceChangePercent)}
				</span>
			</div>
			<div className={styles.change24hItem}>
				<span>{t("24hhigh")}</span>
				{/* <span>{`${numberFormat(highPrice)}`}</span> */}
				<span>{highPrice}</span>
			</div>
			<div className={styles.change24hItem}>
				<span>{t("24hlow")}</span>
				<span>{lowPrice}</span>
			</div>
			<div className={styles.change24hItem}>
				<span>{`${t("24hvolume")} (${baseAsset})`}</span>
				<span>{volume}</span>
			</div>
			<div className={styles.change24hItem}>
				<span>{`${t("24hvolume")} (${quoteAsset})`}</span>
				<span>{quoteVolume}</span>
			</div>
		</div>
	);
};

export default ProPriceDetail;
