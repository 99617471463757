import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import Lite from "./Lite";
import Pro from "./Pro";

import { useSelector, useDispatch } from "react-redux";

import { useLocation } from "react-router";

import { updatePriceChange24h } from "../../Store/spot";
import SpotTradeStatus from "../../Component/Exchange/SpotTradeStatus";
import SelectPair from "../../Component/Exchange/SelectPair";
import autoAnimate from "@formkit/auto-animate";

import {
	numberFormat,
	convertNumString,
	numberFormatShort,
} from "../../utils/formatNumber";

import { useGet24HChangeQuery } from "../../services/market";

const SpotTrade = () => {
	const isPro = useSelector((state) => state.spot.isPro);

	const search = useLocation().search;
	const symbol = new URLSearchParams(search).get("token").split("-").join("");
	const { data: changeData } = useGet24HChangeQuery(symbol);
	const dispatch = useDispatch();

	// const [isOpenSelectMarket, setIsOpenSelectMarket] = useState(false);
	const parent = useRef(null);

	useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent]);

	useEffect(() => {
		if (changeData) {
			const data = changeData[0];
			document.title = `${numberFormat(
				parseFloat(data.lastPrice)
			)}  |  ${symbol}  |  Frypto`;
			dispatch(
				updatePriceChange24h({
					currentPrice: convertNumString(
						numberFormat(parseFloat(data.lastPrice))
					),
					highPrice: numberFormat(parseFloat(data.highPrice)),
					lowPrice: numberFormat(parseFloat(data.lowPrice)),
					priceChangePercent: numberFormat(
						parseFloat(data.priceChangePercent)
					),
					quoteVolume: numberFormat(parseFloat(data.quoteVolume)),
					volume: numberFormat(parseFloat(data.volume)),
					tickSize: parseFloat(data.tickSize),
					stepSize: parseFloat(data.stepSize),
				})
			);
		}
	}, [changeData]);

	useEffect(() => {
		localStorage.setItem("isPro", isPro);
	}, [isPro]);

	useEffect(() => {
		const wssPrice = new WebSocket("wss://stream.binance.com:9443/ws");
		const lowCaseToken = symbol.toLowerCase();
		wssPrice.onopen = (event) => {
			wssPrice.send(
				JSON.stringify({
					method: "SUBSCRIBE",
					params: [lowCaseToken + "@ticker"],
					id: 2,
				})
			);
		};

		wssPrice.onmessage = (event) => {
			let price24hData = JSON.parse(event?.data);

			if (price24hData.e === "24hrTicker") {
				document.title = `${numberFormat(
					price24hData.c
				)}  |  ${symbol}  |  Frypto`;
				dispatch(
					updatePriceChange24h({
						currentPrice: convertNumString(
							numberFormat(price24hData.c)
						),
						highPrice: numberFormat(price24hData.h),
						lowPrice: numberFormat(price24hData.l),
						priceChangePercent: numberFormat(price24hData.P),
						quoteVolume: numberFormatShort(price24hData.q),
						volume: numberFormatShort(price24hData.v),
					})
				);
			}
		};
		return () => {
			wssPrice.close();
		};
	}, [symbol]);

	return (
		<div ref={parent}>
			{isPro ? <Pro /> : <Lite />}
			<SpotTradeStatus />
		</div>
	);
};

export default SpotTrade;
