import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from "react-bootstrap";
import { useGetMarketListQuery } from "../../services/market";
import Table from "../Common/Table";
import { useDispatch } from "react-redux";
import { updatePriceChange } from "../../Store/common";
import LoadingDot from "../../Component/Animation/LoadingDot";
import { useWindowSize } from "@react-hook/window-size";
import MobileModal from "../Common/MobileModal";
import PairList from "./PairList";

const SelectPair = ({ isOpen, close }) => {
	const [t] = useTranslation("common");
	const [width] = useWindowSize();

	return (
		<>
			{width > 1200 ? (
				<Modal
					show={isOpen}
					onHide={close}
					className={styles.selectPairModal}
				>
					<Modal.Header closeButton>
						<Modal.Title>{t("choosePair")}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<PairList />
					</Modal.Body>
				</Modal>
			) : (
				<MobileModal
					open={isOpen}
					close={close}
					title={t("choosePair")}
				>
					<PairList />
				</MobileModal>
			)}
		</>
	);
};

export default SelectPair;
