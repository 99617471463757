import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./config";

// const tokenUrl = process.env.REACT_APP_FRYPTO_API + "/tokens";
// const assetUrl = process.env.REACT_APP_FRYPTO_API + "/wallet/assets";

export const walletService = createApi({
	reducerPath: "walletService",
	baseQuery: baseQuery,
	endpoints: (builder) => ({
		getTokenList: builder.query({
			query: () => `tokens`,
		}),
		getWalletAssets: builder.query({
			query: () => `wallet/assets`,
		}),
		getWalletTransactions: builder.query({
			query: (data) => {
				const take = data?.take || 10;
				const skip = data?.skip || 0;
				const searchParams = new URLSearchParams({
					take,
					skip,
					types: data?.types || "",
				}).toString();
				return `wallet/transactions?` + searchParams;
			},
		}),
		getDepositWallet: builder.query({
			query: (network) => `/wallet/deposit/address/${network}`,
		}),

		withdrawCrypto: builder.mutation({
			query: (data) => ({
				url: `/wallet/withdraw`,
				method: "POST",
				body: data,
			}),
		}),
		transferCrypto: builder.mutation({
			query: ({ from, data }) => ({
				url:
					from === "FUNDING"
						? `/wallet/assets/transfer/spot`
						: "/spot/assets/transfer/wallet",
				method: "POST",
				body: data,
			}),
		}),
		getPNLDetail: builder.query({
			query: ({ start, end, token = "" }) =>
				`/wallet/pnl?start=${start}&end=${end}&token=${token}`,
		}),
	}),
});

export const {
	useGetTokenListQuery,
	useGetWalletAssetsQuery,
	useGetWalletTransactionsQuery,
	useGetDepositWalletQuery,
	useWithdrawCryptoMutation,
	useTransferCryptoMutation,
	useGetPNLDetailQuery,
} = walletService;
