import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useGetReferralInfoQuery } from "../../services/auth";

const Achievements = () => {
	const [t] = useTranslation("common");

	const { data } = useGetReferralInfoQuery();
	return (
		<div className={styles.achievements}>
			<div className="contentContainerMedium">
				<div className="contentHead">{t("myAchievements")}</div>
				<div>
					<div className="contentContainerMedium">
						<div>{t("totalRef")}</div>
						<div>{data?.registerUsers || 0}</div>
					</div>
					<div className="contentContainerMedium">
						<div>{t("successRef")}</div>
						<div>{data?.qualifiedUsers || 0}</div>
					</div>
					<div className="contentContainerMedium">
						<div>{t("refReward")}</div>
						<div>{data?.totalReward || 0}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Achievements;
