import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./styles.module.scss";
import { useGetUserInfoQuery } from "../../services/auth";
import { useTranslation } from "react-i18next";
import { useChangeUserInfoMutation } from "../../services/auth";
import { useWindowSize } from "@react-hook/window-size";
import MobileModal from "../../Component/Common/MobileModal";
import { toast } from "react-toastify";

import { toastCfg } from "../../config";

const AvatarModal = ({ openModal, handleClose }) => {
	const { data: user, refetch: refetchUserInfo } = useGetUserInfoQuery();
	const [changeUserInfo] = useChangeUserInfoMutation();
	const [t] = useTranslation("auth");
	const [selectedAvatar, setSelectedAvatar] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [width] = useWindowSize();

	const changeAvatar = async () => {
		setIsLoading(true);
		const res = await changeUserInfo({ selectedAvatar });
		if (res?.data) {
			refetchUserInfo();
			toast.success("Your avatar has been changed", toastCfg);
		} else {
			toast.error("Something went wrong! Try again later.", toastCfg);
		}
		setIsLoading(false);
		handleClose();
	};

	useEffect(() => {
		if (user?.selectedAvatar) setSelectedAvatar(user?.selectedAvatar);
	}, [user]);

	return (
		<>
			{width > 1200 ? (
				<Modal show={openModal} onHide={handleClose}>
					<div className={styles.avaModal}>
						<div>{t("avaCollection")}</div>
						<div>
							{user?.avatars?.map((avatar) => (
								<div
									key={avatar.avatar}
									onClick={() =>
										setSelectedAvatar(avatar?.avatar)
									}
									className={
										avatar.avatar === selectedAvatar
											? styles.selectedAvatar
											: undefined
									}
								>
									<img
										alt=""
										src={require(`../../assets/images/avatar-${avatar?.avatar}.png`)}
										width={120}
									/>
									<div>
										<div>{avatar?.avatar}</div>
										<div>{`x${avatar?.amount}`}</div>
									</div>
								</div>
							))}
						</div>
						<button
							className="btnLight"
							disabled={!selectedAvatar || isLoading}
							onClick={changeAvatar}
						>
							Select Avatar
						</button>
					</div>
				</Modal>
			) : (
				<MobileModal
					open={openModal}
					close={handleClose}
					title={t("avaCollection")}
				>
					<div className={styles.avaModal}>
						<div>{t("avaCollection")}</div>
						<div>
							{user?.avatars?.map((avatar) => (
								<div
									key={avatar.avatar}
									onClick={() =>
										setSelectedAvatar(avatar?.avatar)
									}
									className={
										avatar.avatar === selectedAvatar
											? styles.selectedAvatar
											: undefined
									}
								>
									<img
										alt=""
										src={require(`../../assets/images/avatar-${avatar?.avatar}.png`)}
										width={120}
									/>
									<div>
										<div>{avatar?.avatar}</div>
										<div>{`x${avatar?.amount}`}</div>
									</div>
								</div>
							))}
						</div>
						<div>
							<button
								className="btnLight"
								disabled={!selectedAvatar || isLoading}
								onClick={changeAvatar}
							>
								Select Avatar
							</button>
						</div>
					</div>
				</MobileModal>
			)}
		</>
	);
};

export default AvatarModal;
