import { Dropdown } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { requestOptions } from "../../config";
import Modal2FA from "../Auth/2FA";

import {
	useGetTokenListQuery,
	useGetWalletAssetsQuery,
} from "../../services/wallet";
import { useTranslation } from "react-i18next";

import { useGetUserInfoQuery } from "../../services/auth";
import styles from "./styles.module.scss";
import { compactNumber, numberFormat } from "../../utils/formatNumber";
import { toastCfg } from "../../config";
import { Form } from "react-bootstrap";
import {
	useWithdrawCryptoMutation,
	useGetWalletTransactionsQuery,
} from "../../services/wallet";
import TooltipComponent from "../Common/TooltipComponent";

function WithdrawCrypto() {
	const [t] = useTranslation("wallet");
	const [networks, setNetworks] = useState({});
	const [tokens, setTokens] = useState([]);
	const [selectedToken, setSelectedToken] = useState();
	const [address, setAddress] = useState("");
	const [amount, setAmount] = useState(0);
	const [selectedNetwork, setSelectedNetwork] = useState({});
	const [loading, setLoading] = useState(false);
	const [isOpen2FA, setIsOpen2FA] = useState(false);
	const [code, setCode] = useState();

	const [selectedTokenDetail, setSelectedTokenDetail] = useState();

	const [withdrawCrypto] = useWithdrawCryptoMutation();

	const { data: tokenList, refetch: refetchTokenList } =
		useGetTokenListQuery();
	const { data: assets, refetch: refetchAssets } = useGetWalletAssetsQuery();

	const { data: user } = useGetUserInfoQuery();

	const { refetch: refetchHistory } = useGetWalletTransactionsQuery({
		type: "OUT",
		page: 1,
	});

	useEffect(() => {
		if (assets && tokenList) {
			const filtered = assets?.filter(
				(token) => parseFloat(token.amount) > 0
			);
			if (filtered?.length === 0) {
				setTokens(
					tokenList.map((token) => {
						return { tokenSymbol: token.id };
					})
				); 
			} else {
				setTokens(filtered);
			}
			// setTokens(filtered);
			// console.log(tokenList);
			// console.log(assets);
		}
	}, [assets, tokenList]);

	useEffect(() => {
		if (tokens?.length > 0) {
			setSelectedToken(tokens[0]?.tokenSymbol);
			setSelectedTokenDetail(
				tokens?.filter(
					(token) => token.tokenSymbol === selectedToken
				)[0]
			);
		}
	}, [tokens]);

	useEffect(() => {
		if (tokenList?.length > 0) {
			setNetworks(
				tokenList?.filter((token) => token.id === selectedToken)[0]
					?.networks
			);
		}
		if (tokens?.length > 0) {
			setSelectedTokenDetail(
				tokens?.filter(
					(token) => token.tokenSymbol === selectedToken
				)[0]
			);
		}
	}, [selectedToken, tokenList, tokens]);

	useEffect(() => {
		if (networks?.length > 0) {
			setSelectedNetwork(networks[0]);
		}
	}, [networks]);

	const canWithdraw = () => {
		if (
			!user?.twoFAEnabled ||
			!user?.isEmailConfirmed ||
			user?.kycStatus !== "APPROVED" ||
			parseFloat(amount) <= 0 ||
			amount < parseFloat(selectedNetwork.withdrawMin) ||
			amount > parseFloat(selectedNetwork.withdrawMax) ||
			!address ||
			parseFloat(amount) > parseFloat(selectedTokenDetail?.amount)
		) {
			return false;
		}
		return true;
	};

	const withdraw = async () => {
		setLoading(true);
		const data = {
			tokenSymbol: selectedToken,
			amount: amount,
			address,
			network: selectedNetwork?.binanceNetworkCode,
			twoFACode: code,
		};

		const response = await withdrawCrypto(data);
		const result = response.data;
		try {
			if (result?.statusCode === "SUCCESS") {
				toast.success(t("withdrawSuccess"), toastCfg);
			} else {
				toast.error(response?.error?.data?.message, toastCfg);
			}
		} catch (error) {
			console.log("error", error);
			toast.error(t("error"), toastCfg);
		}
		setLoading(false);
		setIsOpen2FA(false);
		refetchAssets();
		refetchTokenList();
		refetchHistory();
	};

	return (
		<>
			<Modal2FA
				isOpen={isOpen2FA}
				requestCall={withdraw}
				close={() => {
					setIsOpen2FA(false);
				}}
				handleChangeCode={(value) => setCode(value)}
				loading={loading}
				code={code}
			/>

			<div className="contentContainerMedium">
				<div className={styles.walletFormContainer}>
					<div className={styles.depositForm}>
						<div>
							<div>{t("chooseYourCrypto")}</div>
							<Form.Select
								className={styles.customSelect}
								value={selectedToken}
								onChange={(e) =>
									setSelectedToken(e.target.value)
								}
							>
								{tokens?.map((token) => (
									<option
										key={token?.tokenSymbol}
										value={token?.tokenSymbol}
									>
										{token?.tokenSymbol}
									</option>
								))}
							</Form.Select>
							<div>
								<span>{t("transferToFunding")}</span>
							</div>
						</div>
						<div>
							<TooltipComponent
								text={t("tooltip.depositCaution")}
							>
								<div className={styles.chooseNetworkHead}>
									<div>{t("chooseNetwork")}</div>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2Z"
											fill="#FDFCFF"
											stroke="#363638"
										/>
										<circle
											cx="12"
											cy="7"
											r="1"
											fill="#363638"
										/>
										<rect
											x="11"
											y="10"
											width="2"
											height="8"
											rx="1"
											fill="#363638"
										/>
									</svg>
								</div>
							</TooltipComponent>

							<Form.Select
								className={styles.customSelect}
								value={selectedNetwork}
								onChange={(e) =>
									setSelectedNetwork(e.target.value)
								}
							>
								{networks?.length > 0 &&
									networks?.map((network) => (
										<option key={network.id}>
											{network.metadata.name}
										</option>
									))}
							</Form.Select>
						</div>
						<div>
							<div>{t("withdrawAddress")}</div>
							<div className="inputContainer">
								<input
									className="customInput"
									value={address}
									onChange={(e) => setAddress(e.target.value)}
								/>
							</div>
							<div>
								<span>{t("withdrawAddressDes")}</span>
							</div>
						</div>
						<div>
							<div>{t("quantity")}</div>
							<div className={styles.customQuantityContainer}>
								<input
									className="customInput"
									type="number"
									style={{ textAlign: "right" }}
									value={amount}
									onChange={(e) => setAmount(e.target.value)}
									min="0"
									step="any"
								/>
								<div
									onClick={() =>
										setAmount(
											parseFloat(
												selectedTokenDetail?.amount
											)
										)
									}
								>
									{t("max")}
								</div>
							</div>
							<div>
								<span>{t("available") + ":"}</span>
								<span>
									{`${numberFormat(
										parseFloat(selectedTokenDetail?.amount)
									)} ${selectedToken || ""}`}
								</span>
							</div>
						</div>
					</div>
					<div className={styles.depositCheckout}>
						<div>
							<div>
								<span>{t("minWithdraw")}</span>
								<span>{`${selectedNetwork?.withdrawMin || 0} ${
									selectedToken || ""
								}`}</span>
							</div>
							<div>
								<span>{t("maxWithdraw")}</span>

								<span>{`${compactNumber(
									parseFloat(
										selectedNetwork?.withdrawMax || 0
									)
								)} ${selectedToken || ""}`}</span>
							</div>
							<div>
								<span>{t("withdrawFee")}</span>
								<span>{`${selectedNetwork?.withdrawFee || 0} ${
									selectedToken || ""
								}`}</span>
							</div>
						</div>
						{user?.twoFAEnabled &&
						user?.isEmailConfirmed &&
						user?.kycStatus === "APPROVED" ? (
							""
						) : (
							<div className={styles.caution}>
								{t("withdrawCaution")}{" "}
								<a href="/account-page">{t("toAccount")}</a>
							</div>
						)}
						<button
							className="btnDark"
							onClick={() => setIsOpen2FA(true)}
							disabled={!canWithdraw()}
						>
							{t("withdraw")}
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default WithdrawCrypto;
