import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from "react-bootstrap";
import { useGetMarketListQuery } from "../../services/market";
import Table from "../Common/Table";
import { useDispatch } from "react-redux";
import { updatePriceChange } from "../../Store/common";
import LoadingDot from "../../Component/Animation/LoadingDot";

const PairList = ({
	showTab = true,
	tableType = "PAIRSELECT",
	tableClassName = "historyTable",
}) => {
	const [t] = useTranslation("common");
	const [search, setSearch] = useState();
	const [activeTab, setActiveTab] = useState(1);
	const { data: rawData, isLoading, error } = useGetMarketListQuery();
	const [data, setData] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		const wssPrice = new WebSocket("wss://stream.binance.com:9443/ws");
		wssPrice.onopen = (event) => {
			wssPrice.send(
				JSON.stringify({
					method: "SUBSCRIBE",
					params: ["!miniTicker@arr"],
					id: 1,
				})
			);
		};

		wssPrice.onmessage = (event) => {
			let currentPriceObj = JSON.parse(event.data);
			dispatch(updatePriceChange(currentPriceObj));
		};
		return () => {
			wssPrice.close();
		};
	}, []);

	useEffect(() => {
		const filterData = (data, type) => {
			switch (type) {
				case "1":
					return data;
				case "2":
					return data?.filter((item) => item.quoteAsset === "BUSD");
				case "3":
					return data?.filter(
						(item) =>
							item.quoteAsset === "BTC" ||
							item.baseAsset === "BTC"
					);
				case "4":
					return data?.filter(
						(item) =>
							item.quoteAsset !== "BTC" &&
							item.baseAsset !== "BTC"
					);
				default:
					return data;
			}
		};
		if (rawData?.data?.length > 0) {
			let newData;
			if (search) {
				newData = filterData(rawData?.data, activeTab).filter((item) =>
					item.id.toUpperCase().includes(search.toUpperCase())
				);
			} else {
				newData = filterData(rawData?.data, activeTab);
			}

			setData(newData);
		}
	}, [search, activeTab, rawData]);

	return (
		<>
			<div className="inputContainer inputContainerShadow">
				<div className={styles.searchPair}>
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M12.2582 13.6706C10.9895 14.5474 9.43742 15.0631 7.76115 15.0631C3.47487 15.0631 0 11.6911 0 7.53154C0 3.37199 3.47487 0 7.76115 0C12.0474 0 15.5223 3.37199 15.5223 7.53154C15.5223 9.4607 14.7747 11.2205 13.5456 12.553L19.7475 18.5714C20.0842 18.8982 20.0842 19.4281 19.7475 19.7549C19.4107 20.0817 18.8647 20.0817 18.5279 19.7549L12.2582 13.6706ZM13.7976 7.53154C13.7976 10.7668 11.095 13.3894 7.76115 13.3894C4.42733 13.3894 1.7247 10.7668 1.7247 7.53154C1.7247 4.29633 4.42733 1.67368 7.76115 1.67368C11.095 1.67368 13.7976 4.29633 13.7976 7.53154Z"
							fill="#939393"
						/>
					</svg>
					<input
						className="customInput"
						placeholder={t("searchPlaceholder")}
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
				</div>
			</div>
			{showTab ? (
				<div>
					<Tabs
						activeKey={activeTab}
						onSelect={(key) => setActiveTab(key)}
						className={styles.pairTabs}
					>
						<Tab eventKey={1} title={<span>{t("all")}</span>}></Tab>
						<Tab eventKey={2} title={<span>BUSD</span>}></Tab>
						<Tab eventKey={3} title={<span>BTC</span>}></Tab>
						<Tab eventKey={4} title={<span>ALTS</span>}></Tab>
					</Tabs>
				</div>
			) : null}
			<div className={styles.pairsContainer}>
				{isLoading ? (
					<LoadingDot />
				) : (
					<Table
						data={data}
						defaultSortField="title"
						selectableRows={false}
						highlightOnHover={true}
						className={tableClassName}
						type={tableType}
					/>
				)}
			</div>
		</>
	);
};

export default PairList;
