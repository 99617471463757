import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import Task1 from "../../assets/images/wheel-task1.svg";
import Task2 from "../../assets/images/wheel-task2.svg";
import Task3 from "../../assets/images/wheel-task3.svg";
import Task4 from "../../assets/images/wheel-task4.svg";
import Task5 from "../../assets/images/wheel-task5.svg";
import Task6 from "../../assets/images/wheel-task6.svg";
import TaskComp from "../../assets/images/task-comp.svg";

import { useTranslation } from "react-i18next";
import { useGetUserInfoQuery } from "../../services/auth";

const Tasks = () => {
	const [t] = useTranslation("common");
	const { data: user } = useGetUserInfoQuery();

	return (
		<div className={styles.tasksContainer}>
			<p>{t("luckyWheel.completeTasks")}</p>

			<div className={styles.tasks}>
				<div className="task-glow1">
					<img alt="" src={Task1} />
					<div>
						<img src={TaskComp} alt="" />
						<div>{`+${user ? 1 : 0}`}</div>
					</div>
					<div>{t("luckyWheel.registerTask")}</div>
				</div>
				<div className="task-glow2">
					<img alt="" src={Task2} />
					<div>
						<img src={TaskComp} alt="" />
						<div>{`+${user?.referreeAmount || 0}`}</div>
					</div>
					<div>{t("luckyWheel.inviteTask")}</div>
				</div>
				<div className="task-glow3">
					<img alt="" src={Task3} />
					<div>
						<img src={TaskComp} alt="" />
						<div>{`+${
							parseFloat(user?.deposit || 0) > 0 ? 1 : 0
						}`}</div>
					</div>
					<div>{t("luckyWheel.depositTask")}</div>
				</div>
				<div className="task-glow6">
					<img alt="" src={Task4} />
					<div>
						<img src={TaskComp} alt="" />
						<div>{`+${
							parseFloat(user?.tradingVolumn || 0) > 0 ? 1 : 0
						}`}</div>
					</div>
					<div>{t("luckyWheel.tradeTask")}</div>
				</div>
				<div className="task-glow5">
					<img alt="" src={Task5} />
					<div>
						<img src={TaskComp} alt="" />
						<div>{`+${parseInt(
							parseFloat(user?.tradingVolumn || 0) / 1000
						)}`}</div>
					</div>
					<div>{t("luckyWheel.tradeVolumeTask")}</div>
				</div>
				<div className="task-glow4">
					<img alt="" src={Task6} />
					<div>
						<img src={TaskComp} alt="" />
						<div>+∞</div>
					</div>
					<div>{t("luckyWheel.finalTask")}</div>
				</div>
			</div>
		</div>
	);
};

export default Tasks;
