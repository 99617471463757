import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const SuccessIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
				fill="#2EBD56"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.28973 16.2905L5.69973 12.7005C5.30973 12.3105 5.30973 11.6805 5.69973 11.2905C6.08973 10.9005 6.71973 10.9005 7.10973 11.2905L9.99973 14.1705L16.8797 7.29055C17.2697 6.90055 17.8997 6.90055 18.2897 7.29055C18.6797 7.68055 18.6797 8.31055 18.2897 8.70055L10.6997 16.2905C10.3197 16.6805 9.67973 16.6805 9.28973 16.2905Z"
				fill="#FEFEFE"
			/>
		</svg>
	);
};

const ErrorIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2Z"
				fill="#DD4C56"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.2997 16.2997C15.9097 16.6897 15.2797 16.6897 14.8897 16.2997L11.9997 13.4097L9.10973 16.2997C8.71973 16.6897 8.08973 16.6897 7.69973 16.2997C7.30973 15.9097 7.30973 15.2797 7.69973 14.8897L10.5897 11.9997L7.69973 9.10973C7.30973 8.71973 7.30973 8.08973 7.69973 7.69973C8.08973 7.30973 8.71973 7.30973 9.10973 7.69973L11.9997 10.5897L14.8897 7.69973C15.2797 7.30973 15.9097 7.30973 16.2997 7.69973C16.6897 8.08973 16.6897 8.71973 16.2997 9.10973L13.4097 11.9997L16.2997 14.8897C16.6797 15.2697 16.6797 15.9097 16.2997 16.2997Z"
				fill="#FEFEFE"
			/>
		</svg>
	);
};

const InfoIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2Z"
				fill="#2563EB"
			/>
			<circle cx="12" cy="7" r="1" fill="#FDFCFF" />
			<rect x="11" y="10" width="2" height="8" rx="1" fill="#FDFCFF" />
		</svg>
	);
};

const StatusText = ({ status }) => {
	const [t] = useTranslation("wallet");
	const statusOrder = () => {
		switch (status) {
			case "NEW":
				return t("orderStatus.new");
			case "FILLED":
				return t("orderStatus.filled");
			case "CANCELED":
				return t("orderStatus.cancelled");
			case "PROCESSING":
				return t("orderStatus.processing");
			default:
				return t("orderStatus.error");
		}
	};
	const getClass = () => {
		switch (status) {
			case "NEW":
				return "info";
			case "FILLED":
				return "priceUp";
			case "CANCELED":
				return "priceDown";
			case "PROCESSING":
				return "info";
			default:
				return "priceDown";
		}
	};

	return (
		<div className={styles.orderStatus}>
			{status === "FILLED" && <SuccessIcon />}
			{(status === "CANCELED" || status === "REJECTED") && <ErrorIcon />}
			{(status === "NEW" || status === "PROCESSING") && <InfoIcon />}

			<span className={getClass()}>{statusOrder(status)}</span>
		</div>
	);
};

export default StatusText;
