export const convertNumString = (numStr) => {
	if (!numStr || typeof numStr !== "string" || numStr === "") return 0;
	return parseFloat(numStr.replaceAll(",", ""));
};

export const numberFormat = (num) => {
	if (!num) return 0;
	return new Intl.NumberFormat("en-US", {
		maximumFractionDigits: 8,
		minimumFractionDigits: 2,
	}).format(num);
};

export const numberFormatShort = (num) => {
	if (!num) return 0;
	return new Intl.NumberFormat("en-US", {
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	}).format(num);
};

export const compactNumber = (num) => {
	if (!num) return 0;
	return new Intl.NumberFormat("en-US", {
		notation: "compact",
		compactDisplay: "short",
	}).format(num);
};

export const formatPercent = (valueString) => {
	const isDown = valueString[0] === "-";
	if (!isDown) valueString = "+" + valueString;
	return valueString + "%";
};

export const formatNumberBySize = (num, size, isRoundDown = true) => {
	const n = parseFloat(size) > 0 ? `${size}`?.split(".")[1]?.length || 0 : 0;
	const d = Math.pow(10, n);
	return isRoundDown
		? parseFloat(parseFloat(Math.floor(Number(num) * d) / d).toFixed(n))
		: parseFloat(Number(num).toFixed(n));
};
