import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Form } from "react-bootstrap";
import { useGetSpotAssetsQuery } from "../../services/spot";
import {
	useGetWalletAssetsQuery,
	useTransferCryptoMutation,
} from "../../services/wallet";
import { numberFormat } from "../../utils/formatNumber";
import { toast } from "react-toastify";
import { toastCfg } from "../../config";
import LoadingDot from "../Animation/LoadingDot";

const TransferCrypto = () => {
	const [t] = useTranslation("wallet");
	const { data: spotAssets, refetch: refetchSpot } = useGetSpotAssetsQuery();
	const { data: fundingAssets, refetch: refetchFunding } =
		useGetWalletAssetsQuery();

	const [from, setFrom] = useState("SPOT");
	const [to, setTo] = useState("FUNDING");
	const [assetsList, setAssetsList] = useState([]);
	const [selectedToken, setSelectedToken] = useState({});
	const [amount, setAmount] = useState(0);
	const [isTransferring, setIsTransferring] = useState(false);

	const [transferCrypto] = useTransferCryptoMutation();

	const onChangeFrom = async (e) => {
		const value = e.target.value;
		setFrom(value);
		setAmount(0);
		if (value === "FUNDING") {
			setTo("SPOT");
		} else {
			setTo("FUNDING");
		}
	};

	const onChangeTo = async (e) => {
		const value = e.target.value;
		setTo(value);
		setAmount(0);
		if (value === "FUNDING") {
			setFrom("SPOT");
		} else {
			setFrom("FUNDING");
		}
	};

	const transfer = async () => {
		setIsTransferring(true);
		const data = {
			tokenSymbol: selectedToken,
			amount: parseFloat(amount),
		};

		const result = await transferCrypto({ from: from, data });
		console.log(result);

		if (result?.data?.statusCode === "SUCCESS") {
			toast.success(
				t("internalTransfer", {
					amount: amount,
					token: selectedToken,
					from: from,
					to: from === "FUNDING" ? "SPOT" : "FUNDING",
				}),
				toastCfg
			);
			refetchSpot();
			refetchFunding();
			setAmount(0);
		} else {
			toast.error(
				result.error.data.message || "Some thing went wrong!",
				toastCfg
			);
		}
		setIsTransferring(false);
	};

	useEffect(() => {
		if (from === "SPOT")
			setAssetsList(
				spotAssets
					? spotAssets.filter(
							(token) => parseFloat(token?.amount) > 0
					  )
					: []
			);
		if (from === "FUNDING")
			setAssetsList(
				fundingAssets
					? fundingAssets.filter(
							(token) => parseFloat(token?.amount) > 0
					  )
					: []
			);
	}, [from, spotAssets, fundingAssets]);

	useEffect(() => {
		if (assetsList?.length > 0) {
			setSelectedToken(assetsList[0]?.tokenSymbol);
		}
	}, [assetsList]);

	return (
		<div className="contentContainerMedium">
			<div className={styles.walletFormContainer}>
				<div className={styles.transferForm}>
					<div className={styles.transferElement}>
						<div>
							<div className={styles.formLabel}>{t("from")}</div>
							<Form.Select
								className={styles.customSelect}
								value={from}
								onChange={onChangeFrom}
							>
								<option value="SPOT">SPOT</option>
								<option value="FUNDING">FUNDING</option>
							</Form.Select>
						</div>
						<div>
							<svg
								width="25"
								height="25"
								viewBox="0 0 25 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<mask
									id="mask0_571_3444"
									maskUnits="userSpaceOnUse"
									x="0"
									y="0"
									width="25"
									height="25"
								>
									<rect
										x="0.297852"
										y="0.966797"
										width="24"
										height="24"
										fill="#D9D9D9"
									/>
								</mask>
								<g mask="url(#mask0_571_3444)">
									<path
										d="M16.5979 14.2416C16.4146 14.0583 16.3229 13.8249 16.3229 13.5416C16.3229 13.2583 16.4146 13.0249 16.5979 12.8416L18.4729 10.9666H12.2979C12.0146 10.9666 11.7772 10.8706 11.5859 10.6786C11.3939 10.4873 11.2979 10.2499 11.2979 9.9666C11.2979 9.68327 11.3939 9.4456 11.5859 9.2536C11.7772 9.06227 12.0146 8.9666 12.2979 8.9666H18.4729L16.5979 7.0916C16.4146 6.90827 16.3229 6.6706 16.3229 6.3786C16.3229 6.08727 16.4146 5.84993 16.5979 5.6666C16.7812 5.48327 17.0146 5.3916 17.2979 5.3916C17.5812 5.3916 17.8146 5.48327 17.9979 5.6666L21.5979 9.2666C21.6979 9.3666 21.7689 9.47493 21.8109 9.5916C21.8522 9.70827 21.8729 9.83327 21.8729 9.9666C21.8729 10.0999 21.8522 10.2249 21.8109 10.3416C21.7689 10.4583 21.6979 10.5666 21.5979 10.6666L17.9979 14.2666C17.8146 14.4499 17.5812 14.5373 17.2979 14.5286C17.0146 14.5206 16.7812 14.4249 16.5979 14.2416ZM6.5979 20.2666L2.9979 16.6666C2.8979 16.5666 2.82723 16.4583 2.7859 16.3416C2.7439 16.2249 2.7229 16.0999 2.7229 15.9666C2.7229 15.8333 2.7439 15.7083 2.7859 15.5916C2.82723 15.4749 2.8979 15.3666 2.9979 15.2666L6.5979 11.6666C6.78123 11.4833 7.01457 11.3916 7.2979 11.3916C7.58123 11.3916 7.81457 11.4833 7.9979 11.6666C8.18123 11.8499 8.2729 12.0873 8.2729 12.3786C8.2729 12.6706 8.18123 12.9083 7.9979 13.0916L6.1229 14.9666H12.2979C12.5812 14.9666 12.8189 15.0623 13.0109 15.2536C13.2022 15.4456 13.2979 15.6833 13.2979 15.9666C13.2979 16.2499 13.2022 16.4873 13.0109 16.6786C12.8189 16.8706 12.5812 16.9666 12.2979 16.9666H6.1229L7.9979 18.8416C8.18123 19.0249 8.2729 19.2583 8.2729 19.5416C8.2729 19.8249 8.18123 20.0583 7.9979 20.2416C7.81457 20.4249 7.58123 20.5206 7.2979 20.5286C7.01457 20.5373 6.78123 20.4499 6.5979 20.2666Z"
										fill="#939393"
									/>
								</g>
							</svg>
						</div>

						<div>
							<div className={styles.formLabel}>{t("to")}</div>
							<Form.Select
								className={styles.customSelect}
								value={to}
								onChange={onChangeTo}
							>
								<option value="SPOT">SPOT</option>
								<option value="FUNDING">FUNDING</option>
							</Form.Select>
						</div>
					</div>
					<div>
						<div className={styles.formLabel}>
							{t("chooseYourCrypto")}
						</div>
						<Form.Select
							className={styles.customSelect}
							value={selectedToken}
							onChange={(e) => setSelectedToken(e.target.value)}
						>
							{assetsList?.map((token) => (
								<option
									key={token?.tokenSymbol}
									value={token?.tokenSymbol}
								>
									{token?.tokenSymbol}
								</option>
							))}
						</Form.Select>
					</div>
					<div>
						<div className={styles.formLabel}>{t("quantity")}</div>
						<div className={styles.customQuantityContainer}>
							<input
								className="customInput"
								type="number"
								style={{ textAlign: "right" }}
								value={amount}
								onChange={(e) => setAmount(e.target.value)}
								min="0"
								step="any"
							/>
							<div
								onClick={() =>
									setAmount(
										parseFloat(
											assetsList?.filter(
												(token) =>
													token?.tokenSymbol ===
													selectedToken
											)[0]?.amount
										) || 0
									)
								}
							>
								{t("max")}
							</div>
						</div>
						<div className={styles.formDes}>
							<span>{t("available") + ":"}</span>
							<span>
								{`${numberFormat(
									parseFloat(
										assetsList?.filter(
											(token) =>
												token?.tokenSymbol ===
												selectedToken
										)[0]?.amount
									)
								)} ${
									assetsList?.filter(
										(token) =>
											token?.tokenSymbol === selectedToken
									)[0]?.tokenSymbol || ""
								}`}
							</span>
						</div>
					</div>
				</div>
				<button className="btnDark" onClick={() => transfer()}>
					{isTransferring ? <LoadingDot /> : t("confirm")}
				</button>
			</div>
		</div>
	);
};

export default TransferCrypto;
