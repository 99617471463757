import React from "react";
import styles from "./styles.module.scss";
import InviteLuckyWheel from "../../assets/images/invite-lucky-wheel.svg";
import { useTranslation } from "react-i18next";
import CopyComponent from "../../Component/Common/CopyComponent";
import { useGetUserInfoQuery } from "../../services/auth";

import { useCreateInviteLinkMutation } from "../../services/luckyWheel";

const InviteFriend = () => {
	const [t] = useTranslation("common");
	const { data: user, refetch: refetchUserInfo } = useGetUserInfoQuery();
	const [createInviteLink] = useCreateInviteLinkMutation();

	const createInvitation = async () => {
		const res = await createInviteLink();
		if (!res?.data?.message === "SUCCESS") return;
		refetchUserInfo();
	};

	return (
		<div className={styles.invite}>
			<img src={InviteLuckyWheel} alt="invite" />
			<div className="contentContainer">
				<div>{t("luckyWheel.inviteForMore")}</div>
				<div>
					{user ? (
						<>
							{user?.invitationSpinId ? (
								<div className={styles.inviteContent}>
									<div>
										<p>{t("luckyWheel.link")}</p>
										<CopyComponent
											text={
												(
													window.location.hostname +
													"/lucky-wheel?inviteCode=" +
													user?.invitationSpinId
												).slice(0, 48) + "..."
											}
											copyText={
												window.location.hostname +
												"/lucky-wheel?inviteCode=" +
												user?.invitationSpinId
											}
										/>
									</div>
									<div>
										<p>
											{t("luckyWheel.resgiterFromInvite")}
										</p>
										<p>{user?.referreeAmount || 0}</p>
									</div>
									<div>
										<p>{t("luckyWheel.inviteSpinsLeft")}</p>
										<p>{`${user?.invitationSpin}/10`}</p>
									</div>
								</div>
							) : (
								<button
									className="btnDark"
									onClick={createInvitation}
								>
									Create Invite Link
								</button>
							)}
						</>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default InviteFriend;
