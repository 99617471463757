export const formatDate = (timeStamp) => {
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const date = new Date(timeStamp);
	const locale = localStorage.getItem("lang") === "vi" ? "vi-VN" : "en-GB";
	return new Intl.DateTimeFormat(locale, {
		month: "short",
		day: "numeric",
		hour: "numeric",
		minute: "2-digit",
		hour12: false,
		timeZone: timeZone,
	}).format(date);
};

export const getPastDate = (dateDiff, forRequest = false) => {
	const destinationTimestamp = Date.now() - dateDiff * 86400 * 1000;
	const locale = forRequest
		? "vi-VN"
		: localStorage.getItem("lang") === "vi"
		? "vi-VN"
		: "en-GB";
	return new Intl.DateTimeFormat(locale).format(destinationTimestamp);
};

export const getPastDateRaw = (dateDiff) => {
	const destinationTimestamp = Date.now() - dateDiff * 86400 * 1000;
	return new Date(destinationTimestamp);
};
export const formatDateNotime = (date) => {
	const locale = localStorage.getItem("lang") === "vi" ? "vi-VN" : "en-GB";
	return new Intl.DateTimeFormat(locale).format(date);
};

export const getListPastDate = (dateDiff, rate = 1) => {
	let list = [];
	for (let i = 1; i <= dateDiff; i++) {
		if (i % rate === 0) list.push(getPastDate(i));
	}
	return list;
};

export const getDateFromRespond = (dateString) => {
	//ex:28/02/2023 02:07
	const data = dateString.split(" ")[0].split("/");
	return formatDateNotime(
		new Date(
			data[2],
			parseInt(data[1]) === 0 ? data[1] : parseInt(data[1] - 1),
			data[0]
		)
	);
};
