export const toastCfg = {
	position: "top-right",
	autoClose: 5000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	theme: "colored",
};

const access_token = localStorage.getItem("access_token");
export const requestOptions = {
	method: "GET",
	headers: {
		"Content-Type": "application/json",
		Authorization: `Bearer ${access_token}`,
	},
};

export const LANG_LIST = [
	{ code: "en", name: "English" },
	{ code: "vi", name: "Tiếng Việt" },
];
