import React from "react";
import { useNavigate } from "react-router";
import styles from "./styles.module.scss";
import NavButtons from "./NavButtons";
import { useWindowSize } from "@react-hook/window-size";

const WalletHead = ({ title }) => {
	const navigate = useNavigate();
	const [width] = useWindowSize();
	return (
		<div className="d-flex justify-content-between">
			<div className={styles.walletTitle}>
				<div onClick={() => navigate("/wallet-page")}>
					<svg
						width="13"
						height="22"
						viewBox="0 0 13 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M10.1277 21.2442L0.643082 11.7877C0.530505 11.6751 0.450951 11.5532 0.404419 11.4218C0.357137 11.2905 0.333496 11.1498 0.333496 10.9997C0.333496 10.8496 0.357137 10.7089 0.404419 10.5775C0.450951 10.4462 0.530505 10.3242 0.643082 10.2116L10.1277 0.727027C10.3904 0.464347 10.7187 0.333008 11.1127 0.333008C11.5068 0.333008 11.8445 0.473729 12.1259 0.755171C12.4074 1.03661 12.5481 1.36496 12.5481 1.74022C12.5481 2.11548 12.4074 2.44383 12.1259 2.72527L3.85153 10.9997L12.1259 19.2741C12.3886 19.5368 12.52 19.8602 12.52 20.2445C12.52 20.6295 12.3792 20.9627 12.0978 21.2442C11.8163 21.5256 11.488 21.6663 11.1127 21.6663C10.7375 21.6663 10.4091 21.5256 10.1277 21.2442Z"
							fill="#939393"
						/>
					</svg>
				</div>

				<div>{title}</div>
			</div>

			{width > 1200 ? <NavButtons /> : null}
		</div>
	);
};

export default WalletHead;
