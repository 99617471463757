import React from "react";
import MainIndex from "./Screen/MainIndex";

function App(props) {
	const activekey = () => {
		var res = window.location.pathname;
		var baseUrl = process.env.PUBLIC_URL;
		baseUrl = baseUrl.split("/");
		res = res.split("/");
		res = res.length > 0 ? res[baseUrl.length] : "/";
		res = res ? "/" + res : "/";
		const activeKey1 = res;
		return activeKey1;
	};
	return (
		<div id="cryptoon-layout" className="theme-light">
			<MainIndex activekey={activekey()} />
		</div>
	);
}

export default App;
