import React, { useRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { numberFormat, compactNumber } from "../../utils/formatNumber";

import { useNavigate } from "react-router";
import { useState } from "react";

import Chart from "react-apexcharts";

import { useGetKLinesQuery } from "../../services/market";
import { useSelector } from "react-redux";
import { parse } from "date-fns";
import RewardDetail from "../LuckyWheel/RewardDetail";

const Table = ({
	columns,
	type,
	pnlOptions = {},
	pnlTokenSelection,
	defaultSortFieldId,
	...otherProps
}) => {
	const [t] = useTranslation("common");

	const navigate = useNavigate();

	const getCol = () => {
		if (type === "PAIRSELECT") return liteCol;
		if (type === "SIDEPAIRSELECT") return sideLiteCol;
		if (type === "SPOTASSETSLIST") return assetsColPNL;
		if (type === "ASSETSLIST") return assetsCol;
		if (type === "REF") return refCol;
		if (type === "PRIZE") return prizeCol;
		if (type === "MOBILE_DEF") return mobileDefaultCol;
		return defaultCol;
	};

	const getCustomClass = () => {
		switch (type) {
			case "PRIZE":
				return "prizeTable";
			default:
				return undefined;
		}
	};

	const mobileDefaultCol = [
		{
			name: t("table.name"),
			selector: (row) => row.name,
			cell: (row) => (
				<div
					className={styles.mobilePairName}
					onClick={() =>
						(window.location = `/spot-trade?token=${row.baseAsset}-${row.quoteAsset}`)
					}
				>
					<span>{row.baseAsset}</span>
					<span>/</span>
					<span>{row.quoteAsset}</span>
				</div>
			),
			// width: "fit-content",
		},
		{
			name: t("table.price"),
			selector: (row) => parseFloat(row.lastPrice),
			sortable: true,
			cell: (row) => (
				<PriceItem
					startPrice={row.lastPrice}
					// newPrice={getNewPrice(row.id)}
					symbol={row.id}
				/>
			),

			right: true,
		},
		{
			name: t("table.change"),
			selector: (row) => Number(row.priceChangePercent),
			sortable: true,
			cell: (row) => (
				<span className={styles.tableDetail}>
					<span
						className={
							row.priceChangePercent[0] === "-"
								? "priceDown"
								: "priceUp"
						}
					>{`${row.priceChangePercent[0] === "-" ? "" : "+"}${
						row.priceChangePercent
					}%`}</span>
				</span>
			),
			right: true,
		},
		{
			id: "marketcap",
			name: t("table.marketcap"),
			selector: (row) => row.marketCapInUSD,
			omit: true,
		},
	];

	const defaultCol = [
		{
			name: t("table.name"),
			selector: (row) => row.name,
			cell: (row) => (
				<div
					className={styles.pairName}
					onClick={() =>
						(window.location = `/spot-trade?token=${row.baseAsset}-${row.quoteAsset}`)
					}
				>
					<div>
						<img
							src={require(`../../assets/images/coin/${row.quoteAsset}.png`)}
							alt=""
							width={20}
						/>
						<img
							src={require(`../../assets/images/coin/${row.baseAsset}.png`)}
							alt=""
							width={32}
						/>
					</div>
					<span>
						<span>{row.baseAsset}</span>
						<span>/</span>
						<span>{row.quoteAsset}</span>
					</span>
				</div>
			),
			sortable: true,

			width: "150px",
		},
		{
			name: t("table.price"),
			selector: (row) => parseFloat(row.lastPrice),
			sortable: true,
			width: "110px",
			cell: (row) => (
				<PriceItem
					startPrice={row.lastPrice}
					// newPrice={getNewPrice(row.id)}
					symbol={row.id}
				/>
			),
		},
		{
			name: t("table.24hpricechange"),
			selector: (row) => Number(row.priceChangePercent),
			sortable: true,
			width: "125px",
			cell: (row) => (
				<span className={styles.tableDetail}>
					<span
						className={
							row.priceChangePercent[0] === "-"
								? "priceDown"
								: "priceUp"
						}
					>{`${row.priceChangePercent[0] === "-" ? "" : "+"}${
						row.priceChangePercent
					}%`}</span>
				</span>
			),
		},
		{
			name: t("table.24hhighlow"),
			sortable: true,
			cell: (row) => (
				<span className={styles.tableDetail}>{`${numberFormat(
					row.highPrice
				)} / ${numberFormat(row.lowPrice)}`}</span>
			),
			width: "220px",
		},
		{
			name: t("table.24hvolume"),
			selector: (row) => row.volume * row.lastPrice,
			sortable: true,
			cell: (row) => (
				<span className={styles.tableDetail}>
					{`$${compactNumber(row.volume * row.lastPrice)}`}
				</span>
			),
			width: "110px",
			center: true,
		},
		{
			id: "marketcap",
			name: t("table.marketcap"),
			selector: (row) => row.marketCapInUSD,
			sortable: true,
			cell: (row) => (
				<span className={styles.tableDetail}>
					{row.marketCapInUSD > 0
						? `$${compactNumber(row.marketCapInUSD)}`
						: ""}
				</span>
			),
			width: "110px",
			center: true,
		},

		{
			name: t("table.priceTrend"),
			cell: (row) => <PriceTrendItem data={row} />,
			width: "200px",

			center: true,
		},
		{
			name: t("table.action"),
			cell: (row) => (
				<button
					className={styles.btnAction}
					onClick={() =>
						(window.location = `/spot-trade?token=${row.baseAsset}-${row.quoteAsset}`)
					}
				>
					{t("buy")}
				</button>
			),
			right: true,
		},
	];

	const assetsColPNL = [
		{
			name: t("table.name"),
			selector: (row) => row.tokenSymbol,
			cell: (row) => (
				<div className={styles.assetsListName}>
					<img
						src={require(`../../assets/images/coin/${row.tokenSymbol}.png`)}
						alt=""
						width={36}
					/>
					<span>{row.tokenSymbol}</span>
				</div>
			),
		},
		{
			name: t("table.quantity"),
			sortable: false,
			selector: (row) => numberFormat(parseFloat(row.amount)),
		},
		{
			name: t("table.priceInUSD"),

			cell: (row) => <span>{numberFormat(parseFloat(row.inUSD))}</span>,
		},
		{
			name: t("table.priceInBTC"),
			sortable: false,
			selector: (row) => numberFormat(parseFloat(row.inBTC)),
		},
		{
			name: pnlOptions.extralabel || "",
			sortable: false,
			selector: (row) => parseFloat(row.extraData),
			cell: (row) => (
				<div className={row.extraData < 0 ? "priceDown" : "priceUp"}>
					<span>{`${row.extraData < 0 ? "-" : "+"}${numberFormat(
						Math.abs(row.extraData)
					)}`}</span>
					<span>{`(${row.extraData < 0 ? "-" : "+"}${numberFormat(
						Math.abs(row.extraData1)
					)}%)`}</span>
				</div>
			),
		},
		{
			name: t("table.priceTrend"),
			cell: (row) => (
				<PriceTrendItem
					data={{ symbol: row.tokenSymbol + "BUSD" }}
					customWidth={100}
				/>
			),
			// width: "160px",
			center: true,
		},
		{
			name: t("table.action"),
			cell: (row) => (
				<>
					{row.tokenSymbol !== "BUSD" ? (
						<div className={styles.actions}>
							<button
								className={styles.pnlBtn}
								onClick={() =>
									pnlTokenSelection(row.tokenSymbol)
								}
							>
								{t("pnl.detail")}
							</button>
							<button
								className={styles.btnAction}
								onClick={() =>
									(window.location = `/spot-trade?token=${row.tokenSymbol}-BUSD`)
								}
							>
								{t("trade")}
							</button>
						</div>
					) : null}
				</>
			),
			width: "220px",
			center: true,
		},
	];

	const assetsCol = [
		{
			name: t("table.name"),
			selector: (row) => row.tokenSymbol,
			cell: (row) => (
				<div className={styles.assetsListName}>
					<img
						src={require(`../../assets/images/coin/${row.tokenSymbol}.png`)}
						alt=""
						width={36}
					/>
					<span>{row.tokenSymbol}</span>
				</div>
			),
		},
		{
			name: t("table.quantity"),
			sortable: false,
			selector: (row) => numberFormat(parseFloat(row.amount)),
		},
		{
			name: t("table.priceInUSD"),

			cell: (row) => <span>{numberFormat(parseFloat(row.inUSD))}</span>,
		},
		{
			name: t("table.priceInBTC"),
			sortable: false,
			selector: (row) => numberFormat(parseFloat(row.inBTC)),
		},
		{
			name: t("table.priceTrend"),
			cell: (row) => (
				<PriceTrendItem data={{ symbol: row.tokenSymbol + "BUSD" }} />
			),
			width: "220px",
			left: true,
		},
		{
			name: t("table.action"),
			cell: (row) => (
				<>
					{row.tokenSymbol !== "BUSD" ? (
						<button
							className={styles.btnAction}
							onClick={() =>
								(window.location = `/spot-trade?token=${row.tokenSymbol}-BUSD`)
							}
						>
							{t("trade")}
						</button>
					) : null}
				</>
			),
			width: "100px",
			right: true,
		},
	];

	const liteCol = [
		{
			name: t("table.name"),
			selector: (row) => row.name,
			cell: (row) => (
				<div
					className={styles.tableDetail}
					onClick={() =>
						(window.location = `/spot-trade?token=${row.baseAsset}-${row.quoteAsset}`)
					}
				>
					<span>{row.baseAsset}</span>
					<span>/</span>
					<span>{row.quoteAsset}</span>
				</div>
			),
			sortable: false,
			width: "33%",
		},
		{
			name: t("table.price"),
			selector: (row) => parseFloat(row.lastPrice),
			sortable: true,
			width: "33%",

			cell: (row) => (
				<PriceItem startPrice={row.lastPrice} symbol={row.id} />
			),
			right: true,
		},
		{
			name: t("table.change"),
			selector: (row) => Number(row.priceChangePercent),
			sortable: true,
			width: "33%",
			cell: (row) => (
				<span
					className={styles.tableDetail}
					onClick={() =>
						(window.location = `/spot-trade?token=${row.baseAsset}-${row.quoteAsset}`)
					}
				>
					<span
						className={
							row.priceChangePercent[0] === "-"
								? "priceDown"
								: "priceUp"
						}
					>{`${row.priceChangePercent[0] === "-" ? "" : "+"}${
						row.priceChangePercent
					}%`}</span>
				</span>
			),
			right: true,
		},
	];

	const sideLiteCol = [
		{
			selector: (row) => row.name,
			cell: (row) => (
				<div
					className={styles.tableDetail}
					onClick={() =>
						(window.location = `/spot-trade?token=${row.baseAsset}-${row.quoteAsset}`)
					}
				>
					<span>{row.baseAsset}</span>
					<span>/</span>
					<span>{row.quoteAsset}</span>
				</div>
			),
			sortable: false,
			width: "33%",
		},
		{
			selector: (row) => parseFloat(row.lastPrice),
			sortable: true,
			width: "33%",

			cell: (row) => (
				<PriceItem startPrice={row.lastPrice} symbol={row.id} />
			),
			right: true,
		},
		{
			selector: (row) => Number(row.priceChangePercent),
			sortable: true,
			width: "33%",
			cell: (row) => (
				<span
					className={styles.tableDetail}
					onClick={() =>
						(window.location = `/spot-trade?token=${row.baseAsset}-${row.quoteAsset}`)
					}
				>
					<span
						className={
							row.priceChangePercent[0] === "-"
								? "priceDown"
								: "priceUp"
						}
					>{`${row.priceChangePercent[0] === "-" ? "" : "+"}${
						row.priceChangePercent
					}%`}</span>
				</span>
			),
			right: true,
		},
	];

	const refCol = [
		{
			name: "ID",
			selector: (row) => row.id,
			sortable: false,
		},
		{
			name: t("refTable.type"),
			selector: (row) => row.type,
			sortable: false,
		},
		{
			name: t("refTable.amount"),
			selector: (row) => row.amount,
			cell: (row) => <div>{`${row.amount} ${row.asset}`}</div>,
			sortable: false,
		},
		{
			name: t("refTable.date"),
			selector: (row) => row.date,
			sortable: false,
		},
		{
			name: t("refTable.status"),
			selector: (row) => row.status,
			cell: (row) => (
				<>
					{row.status === "RECEIVED" ? (
						<div className={styles.rewardReceived}>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
									fill="#2EBD56"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M9.28875 16.2905L5.69875 12.7005C5.30875 12.3105 5.30875 11.6805 5.69875 11.2905C6.08875 10.9005 6.71875 10.9005 7.10875 11.2905L9.99875 14.1705L16.8787 7.29055C17.2688 6.90055 17.8987 6.90055 18.2887 7.29055C18.6788 7.68055 18.6788 8.31055 18.2887 8.70055L10.6988 16.2905C10.3187 16.6805 9.67875 16.6805 9.28875 16.2905Z"
									fill="#FEFEFE"
								/>
							</svg>
							<span>{t("reward.received")}</span>
						</div>
					) : (
						<div className={styles.rewardWaiting}>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2Z"
									fill="#2563EB"
								/>
								<circle cx="12" cy="7" r="1" fill="#FDFCFF" />
								<rect
									x="11"
									y="10"
									width="2"
									height="8"
									rx="1"
									fill="#FDFCFF"
								/>
							</svg>

							<span>{t("reward.waiting")}</span>
						</div>
					)}
				</>
			),
			sortable: false,
		},
	];

	const prizeCol = [
		{
			name: t("luckyWheel.type"),
			cell: (row) => <RewardDetail reward={row} />,
		},
		{
			name: t("luckyWheel.prize"),
			sortable: false,
			cell: (row) => (
				<div>{`${parseFloat(row.amount)} ${row.symbol}`}</div>
			),
		},
		{
			name: t("luckyWheel.date"),
			selector: (row) => row.date,
			sortable: false,
		},
	];

	return (
		<DataTable
			columns={getCol()}
			defaultSortFieldId="marketcap"
			defaultSortAsc={false}
			onRowClicked={(row) =>
				!type
					? (window.location = `/spot-trade?token=${row.baseAsset}-${row.quoteAsset}`)
					: {}
			}
			selectableRows={false}
			highlightOnHover={true}
			className={getCustomClass()}
			{...otherProps}
		/>
	);
};

export default Table;

const PriceItem = ({ startPrice, symbol }) => {
	const { changeData } = useSelector((state) => state.common);
	const [currentPrice, setCurrentPrice] = useState(startPrice);
	const getNewPrice = (symbol) => {
		if (!changeData || !changeData?.length > 0) return 0;
		return changeData?.filter((p) => p?.s === symbol)[0]?.c || 0;
	};
	useEffect(() => {
		const newPrice = getNewPrice(symbol);
		if (newPrice > 0) setCurrentPrice(newPrice);
	}, [changeData]);
	return (
		<span className={styles.tableDetail}>{numberFormat(currentPrice)}</span>
	);
};

const PriceTrendItem = ({ data, customWidth }) => {
	const { data: seriesData, isLoading: isLoadingKlines } = useGetKLinesQuery({
		symbol: data?.symbol,
	});
	return !isLoadingKlines && seriesData ? (
		<Chart
			height={40}
			width={customWidth || 150}
			series={[
				{
					data: seriesData[data?.symbol] || [0],
				},
			]}
			options={{
				chart: {
					toolbar: {
						show: false,
					},
					sparkline: {
						enabled: true,
					},
					animations: {
						enabled: false,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "smooth",
					width: 1,
				},

				xaxis: {
					labels: {
						show: false,
					},
				},
				yaxis: {
					floating: true,
					axisTicks: {
						show: false,
					},
					axisBorder: {
						show: false,
					},
					labels: {
						show: false,
					},
				},
				tooltip: {
					enabled: false,
				},
				fill: {
					type: "solid",
					colors: ["#E6E6FE"],
				},
				colors: [
					`${
						data?.priceChangePercent
							? data?.priceChangePercent[0] === "-"
								? "#dd4c56"
								: "#2ebd56"
							: "#5757F7"
					}`,
				],
			}}
		/>
	) : null;
};
