import React from "react";
import styles from "./styles.module.scss";
import Chart from "react-apexcharts";
import { numberFormat } from "../../utils/formatNumber";
import { useWindowSize } from "@react-hook/window-size";

const AssetsChart = ({
	series = [0],
	labels = [""],
	isOverview,
	isPNL = false,
}) => {
	const [width] = useWindowSize();
	const total = series?.length > 0 ? series.reduce((a, b) => a + b) : 0;
	const chartOptions = {
		chart: {
			height: 216,
			width: 300,
		},
		labels: labels || [0],
		legend: {
			show: false,
		},
		dataLabels: {
			enabled: false,
		},
		colors: [
			"#343494",
			"#4646C5",
			"#5757F7",
			"#7070F7",
			"#8989F9",
			"#A3A3FA",
			"#BCBCFB",
			"#E6E6FE",
		],
	};

	return (
		<div
			className={
				isPNL || width < 1200
					? styles.pnlChartContainer
					: styles.chartContainer
			}
		>
			<div
				className={
					!isOverview || width < 1200
						? styles.chartLegendGrid
						: styles.overviewContainer
				}
			>
				{series.map((each, index) =>
					isOverview ? (
						<div key={index} className={styles.chartLegendOverview}>
							<div>
								<div
									style={{
										width: 20,
										height: 20,
										background: chartOptions.colors[index],
									}}
								/>
								<div>{labels[index]}</div>
							</div>
							<div>{`$${numberFormat(
								series[index].toFixed(2)
							)}(${(parseFloat(series[index] / total) > 0
								? (series[index] / total) * 100
								: 0
							).toFixed(2)}%)`}</div>
						</div>
					) : (
						<div key={index} className={styles.chartLegendGridItem}>
							<div>
								<div
									style={{
										width: 20,
										height: 20,
										background: chartOptions.colors[index],
									}}
								/>
								<span>{`${labels[index]}(${(parseFloat(
									series[index] / total
								) > 0
									? (series[index] / total) * 100
									: 0
								).toFixed(3)}%)`}</span>
							</div>
						</div>
					)
				)}
			</div>
			<Chart options={chartOptions} series={series || [0]} type="pie" />
		</div>
	);
};

export default AssetsChart;
