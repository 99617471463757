import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EN_COMMON from "./translations/en/common.json";
import VI_COMMON from "./translations/vi/common.json";
import EN_WALLET from "./translations/en/wallet.json";
import VI_WALLET from "./translations/vi/wallet.json";
import EN_TRADE from "./translations/en/trade.json";
import VI_TRADE from "./translations/vi/trade.json";
import EN_AUTH from "./translations/en/auth.json";
import VI_AUTH from "./translations/vi/auth.json";

i18n.use(initReactI18next).init({
	fallbackLng: localStorage.getItem("lang") || "en",
	interpolation: {
		escapeValue: false, // not needed for react as it escapes by default
	},
	resources: {
		en: {
			common: EN_COMMON,
			wallet: EN_WALLET,
			trade: EN_TRADE,
			auth: EN_AUTH,
		},
		vi: {
			common: VI_COMMON,
			wallet: VI_WALLET,
			trade: VI_TRADE,
			auth: VI_AUTH,
		},
	},
});

export default i18n;
