import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import Modal from "react-bootstrap/Modal";
import { useWindowSize } from "@react-hook/window-size";
import MobileModal from "../Common/MobileModal";

const Instruction = () => {
	const [t] = useTranslation("common");
	const [openModal, setOpenModal] = useState(false);
	const [width] = useWindowSize();

	return (
		<>
			<div className={styles.instruction}>
				<div className="contentContainerMedium">
					<div className="contentHead">{t("instruction")}</div>
					<div>
						<div>
							{[1, 2, 3].map((value) => (
								<div
									key={value}
									className={styles.instructionItem}
								>
									<div>{value}</div>
									<img
										src={require(`../../assets/images/ins${value}.svg`)}
										height={150}
										alt="instruction"
										loading="lazy"
									/>
									<div>{t(`instructionText${value}`)}</div>
								</div>
							))}
						</div>
						<button
							className="btnLight"
							onClick={() => setOpenModal(true)}
						>
							{t("more")}
						</button>
					</div>
				</div>
			</div>
			<>
				{width > 1200 ? (
					<Modal show={openModal} onHide={() => setOpenModal(false)}>
						<div className={styles.instructionModal}>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								onClick={() => setOpenModal(false)}
							>
								<mask
									id="mask0_713_7783"
									maskUnits="userSpaceOnUse"
									x="0"
									y="0"
									width="24"
									height="24"
								>
									<rect
										width="24"
										height="24"
										fill="#D9D9D9"
									/>
								</mask>
								<g mask="url(#mask0_713_7783)">
									<path
										d="M11.9998 13.3998L7.0998 18.2998C6.91647 18.4831 6.68314 18.5748 6.3998 18.5748C6.11647 18.5748 5.88314 18.4831 5.6998 18.2998C5.51647 18.1165 5.4248 17.8831 5.4248 17.5998C5.4248 17.3165 5.51647 17.0831 5.6998 16.8998L10.5998 11.9998L5.6998 7.0998C5.51647 6.91647 5.4248 6.68314 5.4248 6.3998C5.4248 6.11647 5.51647 5.88314 5.6998 5.6998C5.88314 5.51647 6.11647 5.4248 6.3998 5.4248C6.68314 5.4248 6.91647 5.51647 7.0998 5.6998L11.9998 10.5998L16.8998 5.6998C17.0831 5.51647 17.3165 5.4248 17.5998 5.4248C17.8831 5.4248 18.1165 5.51647 18.2998 5.6998C18.4831 5.88314 18.5748 6.11647 18.5748 6.3998C18.5748 6.68314 18.4831 6.91647 18.2998 7.0998L13.3998 11.9998L18.2998 16.8998C18.4831 17.0831 18.5748 17.3165 18.5748 17.5998C18.5748 17.8831 18.4831 18.1165 18.2998 18.2998C18.1165 18.4831 17.8831 18.5748 17.5998 18.5748C17.3165 18.5748 17.0831 18.4831 16.8998 18.2998L11.9998 13.3998Z"
										fill="#939393"
									/>
								</g>
							</svg>
							<div>
								<p className={styles.instTitle}>
									{t("ins.title")}
								</p>
								<p>{t("ins.line1")}</p>
								<p>
									<span>{t("ins.line2")}</span>
									<strong>{t("ins.line2Bold")}</strong>
								</p>
								<p>{t("ins.line3")}</p>
								<p>{t("ins.line4")}</p>
							</div>
							<div>
								<button
									className="btnDark"
									onClick={() => setOpenModal(false)}
								>
									{t("understood")}
								</button>
							</div>
						</div>
					</Modal>
				) : (
					<MobileModal
						open={openModal}
						close={() => setOpenModal(false)}
						title={t("ins.title")}
					>
						<div className={styles.rulesMobile}>
							<div>
								<p>{t("ins.line1")}</p>
								<p>
									<span>{t("ins.line2")}</span>
									<strong>{t("ins.line2Bold")}</strong>
								</p>
								<p>{t("ins.line3")}</p>
								<p>{t("ins.line4")}</p>
							</div>
							<div>
								<button
									className="btnDark"
									onClick={() => setOpenModal(false)}
								>
									{t("understood")}
								</button>
							</div>
						</div>
					</MobileModal>
				)}
			</>
		</>
	);
};

export default Instruction;
