import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MobileModal from "../Common/MobileModal";
import styles from "./styles.module.scss";
import TradeComponent from "./TradeComponent";

import { changeTradeSide } from "../../Store/spot/";
import { useDispatch } from "react-redux";

const MobileTradeComponent = () => {
	const [t] = useTranslation("trade");
	const [openModal, setOpenModal] = useState(false);
	const dispatch = useDispatch();

	const setActiveTab = (value) => dispatch(changeTradeSide(value));

	return (
		<>
			<div className={styles.mobileTradeButtonsContainer}>
				<button
					className={styles.buyBtn}
					onClick={() => {
						setActiveTab("BUY");
						setOpenModal(true);
					}}
				>
					{t("buy")}
				</button>
				<button
					className={styles.sellBtn}
					onClick={() => {
						setActiveTab("SELL");
						setOpenModal(true);
					}}
				>
					{t("sell")}
				</button>
			</div>
			<MobileModal open={openModal} close={() => setOpenModal(false)}>
				<TradeComponent />
			</MobileModal>
		</>
	);
};

export default MobileTradeComponent;
