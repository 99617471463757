import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./config";

export const marketService = createApi({
	reducerPath: "marketService",
	baseQuery: baseQuery,
	endpoints: (builder) => ({
		getMarketList: builder.query({
			query: (take = 100) => `markets?take=${take}`,
		}),
		get24HChange: builder.query({
			query: (symbols) => `markets/24hr?symbols=${symbols}`,
		}),
		getKLines: builder.query({
			query: ({ symbol, interval = "1d", limit = 7 }) =>
				`markets/klines?symbols=${symbol}&interval=${interval}&limit=${limit}`,
		}),
		getTrendingPairs: builder.query({
			query: (data) => {
				// limit,
				// 	orderDirection,
				// 	orderBy,
				// 	quote,
				const searchParams = new URLSearchParams(data).toString();
				return `markets/trending?` + searchParams;
			},
		}),
	}),
});

export const {
	useGetMarketListQuery,
	useGetKLinesQuery,
	useGet24HChangeQuery,
	useGetTrendingPairsQuery,
	useGetTokenListQuery,
} = marketService;
