import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InviteFriends from "../../Component/ReferralPage/InviteFriends";
import Instruction from "../../Component/ReferralPage/Instruction";
import Achievements from "../../Component/ReferralPage/Achievements";
import Rewards from "../../Component/ReferralPage/Rewards";
import { useGetUserInfoQuery } from "../../services/auth";

const Referral = () => {
	const [t] = useTranslation("common");
	const [activeTab, setActiveTab] = useState("program");
	const { data: user } = useGetUserInfoQuery();

	return (
		<div className={styles.refContainer}>
			<div>{t("header.ref")}</div>
			<Tabs
				activeKey={activeTab}
				onSelect={(key) => setActiveTab(key)}
				className={styles.walletTabs}
			>
				<Tab eventKey={"program"} title={t("refProgram")} />
				{!!user && (
					<Tab
						eventKey={"achievements"}
						title={t("myAchievements")}
					/>
				)}
			</Tabs>
			{activeTab === "program" ? (
				<>
					<InviteFriends />
					<Instruction />
				</>
			) : null}
			{activeTab === "achievements" ? (
				<>
					<Achievements />
					<Rewards />
				</>
			) : null}
		</div>
	);
};

export default Referral;
