import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import {
	formatDateNotime,
	getPastDate,
	getPastDateRaw,
	getDateFromRespond,
} from "../../utils/date";

import autoAnimate from "@formkit/auto-animate";
import Chart from "react-apexcharts";
import { useGetPNLDetailQuery } from "../../services/wallet";
import { useWindowSize } from "@react-hook/window-size";

const DailyPNLChart = ({ pnl }) => {
	const [t] = useTranslation("wallet");
	const [width] = useWindowSize();

	const [categories, setCategories] = useState([""]);
	const [data, setData] = useState([0]);

	useEffect(() => {
		let values = [];
		let diffs = [];
		let dates = [];
		if (pnl?.result[0].length > 0) {
			for (let i = 0; i < pnl.result.length; i++) {
				let total = 0;
				for (let j = 0; j < pnl.result[i].length; j++) {
					total += parseFloat(pnl.result[i][j]?.valueInUSD);
				}
				values.push(total.toFixed(2));
			}

			for (let k = 1; k < pnl.result.length; k++) {
				diffs.push((values[k] - values[k - 1]).toFixed(2));
				dates.push(getDateFromRespond(pnl.result[k][0].date));
			}
		}
		setData(diffs);
		setCategories(dates);
	}, [pnl]);

	return (
		<div>
			<div>{t("pnl.valueUSD")}</div>
			<Chart
				options={{
					chart: {
						type: "bar",
						zoom: {
							enabled: false,
						},
						toolbar: {
							show: false,
						},
						animations: {
							enabled: true,
						},
					},
					plotOptions: {
						bar: {
							colors: {
								ranges: [
									{
										from: 0,
										to: 9999999,
										color: "#6DD189",
									},
									{
										from: -9999999,
										to: 0,
										color: "#E76069",
									},
								],
							},
							columnWidth: "50%",
						},
					},
					dataLabels: {
						enabled: false,
					},
					stroke: {
						show: true,
						curve: "straight",
						lineCap: "butt",
						colors: "#BCBCFB",
						width: 2.5,
						dashArray: 0,
					},
					xaxis:
						width > 1200
							? {
									categories: categories,
									tickPlacement: "on",
									axisTicks: {
										show: true,
										borderType: "solid",
										color: "#78909C",
										height: 6,
										offsetX: 0,
										offsetY: 0,
									},
									axisBorder: {
										show: true,
										color: "#6DD189",
										height: 1,
										width: "100%",
										offsetX: 0,
										offsetY: 0,
									},
							  }
							: {
									categories: categories,
									show: false,
									labels: {
										show: false,
									},
									axisBorder: {
										show: false,
									},
									axisTicks: {
										show: false,
									},
							  },
					yaxis:
						width > 1200
							? {}
							: {
									show: false,
									labels: {
										show: false,
									},
									axisBorder: {
										show: false,
									},
									axisTicks: {
										show: false,
									},
							  },
					markers: {
						size: 3,
					},
					grid: {
						borderColor: "#000",
						strokeDashArray: 1,
						position: "back",
						xaxis: {
							lines: {
								show: true,
							},
						},
						yaxis: {
							lines: {
								show: true,
							},
						},
						row: {
							colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
							opacity: 1,
						},
					},
				}}
				series={[
					{
						name: "",
						data: data,
					},
				]}
				type="bar"
				height={width > 1200 ? 410 : 250}
			/>
		</div>
	);
};

export default DailyPNLChart;
