import { createSlice } from "@reduxjs/toolkit";
import { authService } from "../../services/auth";

export const authUser = createSlice({
	name: "auth",
	initialState: {
		access_token:
			localStorage.getItem("access_token") ||
			sessionStorage.getItem("access_token"),
		email: localStorage.getItem("email") || sessionStorage.getItem("email"),
		lang: localStorage.getItem("lang") || "en",
	},
	reducers: {
		setCredentials: (state, action) => {
			state.access_token = action.payload;
		},
		userInfor: (state, action) => {
			state = { ...action.payload };
		},
		resetCredentials: (state, action) => {
			state.access_token = null;
			state.email = null;
		},
		setLang: (state, action) => {
			state.lang = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			authService.endpoints.signIn.matchFulfilled,
			(state, action) => {
				state.access_token = action.payload.result.access_token;
				state.email = action.payload.result.email;
			}
		);
		builder.addMatcher(
			authService.endpoints.signUp.matchFulfilled,
			(state, action) => {
				state.access_token = action.payload.result.access_token;
				state.email = action.payload.result.email;
			}
		);
	},
});

export const { setCredentials, resetCredentials, userInfor, setLang } =
	authUser.actions;

export default authUser.reducer;
export const selectCurrentUser = (state) => {
	let dataUser = {
		name: state.auth.name,
		username: state.auth.username,
		email: state.auth.email,
		phone: state.auth.phone,
		isEmailConfirmed: state.auth.isEmailConfirmed,
		spotBalance: state.auth.spotBalance,
		fundingBalance: state.auth.fundingBalance,
	};
	return dataUser;
};
export const selectCurrentToken = (state) => state.auth.access_token;
