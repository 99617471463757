import React, { useRef, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

import { useGetSpotAssetsQuery } from "../../services/spot";
import { useGetPNLDetailQuery } from "../../services/wallet";
import { getPastDate } from "../../utils/date";
import { numberFormat } from "../../utils/formatNumber";

const AssetsTable = ({ setSelectedToken, ...otherProps }) => {
	const [t] = useTranslation("wallet");
	const [data, setData] = useState([]);
	const col = [
		{ name: "Coin", selector: (row) => row.token, width: "80px" },
		{
			name: t("pnl.valueChange"),
			selector: (row) => row.pnl,
			cell: (row) => (
				<div className={row.pnl >= 0 ? "priceUp" : "priceDown"}>{`${
					row.pnl >= 0 ? "+" : "-"
				}$${numberFormat(Math.abs(row.pnl))}`}</div>
			),
			center: true,
			sortable: true,
		},
		{
			name: t("pnl.action"),
			selector: (row) => row.pnl,
			cell: (row) => (
				<>
					{row.token !== "BUSD" ? (
						<button
							className={styles.actionBtn}
							onClick={() => setSelectedToken(row.token)}
						>
							{t("pnl.details")}
						</button>
					) : null}
				</>
			),
			center: true,
		},
	];

	const { data: assets } = useGetSpotAssetsQuery();
	const { data: pnl } = useGetPNLDetailQuery({
		start: getPastDate(1, true),
		end: getPastDate(0, true),
	});
	useEffect(() => {
		if (assets && pnl) {
			const pnlData = [];

			assets.forEach((element) => {
				let filtered = pnl?.result
					.at(-1)
					?.filter((each) => each.token === element.tokenSymbol);
				if (filtered.length > 0) {
					pnlData.push({
						token: element.tokenSymbol,
						pnl: (
							parseFloat(element.inUSD) -
							parseFloat(filtered[0].valueInUSD)
						).toFixed(4),
					});
				}
			});
			setData(pnlData);
			console.log(pnlData);
		}
	}, [assets, pnl]);

	return (
		<div className={styles.assetsTableContainer}>
			<DataTable
				columns={col}
				defaultSortFieldId="marketcap"
				defaultSortAsc={false}
				// onRowClicked={(row) =>
				// 	!type
				// 		? (window.location = `/spot-trade?token=${row.baseAsset}-${row.quoteAsset}`)
				// 		: {}
				// }
				selectableRows={false}
				highlightOnHover={true}
				data={data}
				className="pnlTable"
				{...otherProps}
			/>
		</div>
	);
};

export default AssetsTable;
