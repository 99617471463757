import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { useGetUserInfoQuery } from "../../services/auth";
import { numberFormat } from "../../utils/formatNumber";
import AssetsChart from "./AssetsChart";
import TransactionHistory from "./TransactionHistory";
import { useGetSpotAssetsQuery } from "../../services/spot";
import { useGetWalletAssetsQuery } from "../../services/wallet";
import LoadingDot from "../Animation/LoadingDot";
import { useGetPNLDetailQuery } from "../../services/wallet";
import { getPastDate } from "../../utils/date";
import { useNavigate } from "react-router";
import { useWindowSize } from "@react-hook/window-size";

const OverviewWallet = () => {
	const [t] = useTranslation("wallet");
	const [width] = useWindowSize();
	const navigate = useNavigate();
	const { data: user } = useGetUserInfoQuery();
	const { data: spotAssets } = useGetSpotAssetsQuery();
	const { data: fundingAssets } = useGetWalletAssetsQuery();
	const [totalBalance, setTotalBalance] = useState(0);
	const [totalBalanceDiff, setTotalBalanceDiff] = useState(0);

	const { data: pnl } = useGetPNLDetailQuery({
		start: getPastDate(1, true),
		end: getPastDate(0, true),
	});

	useEffect(() => {
		if (spotAssets && fundingAssets) {
			let total = 0;
			for (let i = 0; i < spotAssets.length; i++) {
				total += spotAssets[i].inBTC;
			}
			for (let i = 0; i < fundingAssets.length; i++) {
				total += fundingAssets[i].inBTC;
			}
			setTotalBalance(total);
		}
	}, [spotAssets, fundingAssets]);

	useEffect(() => {
		if (pnl?.result?.length > 0) {
			let total = 0;
			for (let i = 0; i < pnl.result[0].length; i++) {
				total += parseFloat(pnl.result[0][i].valueInUSD);
			}
			setTotalBalanceDiff((user?.spotBalance - total).toFixed(2));
		}
	}, [pnl, user]);

	return (
		<>
			{user ? (
				<>
					<div className="contentContainer">
						<div className="defaultContainer">
							<div className={styles.contentHead}>
								{t("overview")}
							</div>
							<div className={styles.contentBody}>
								<div className={styles.walletDetail}>
									<div>
										<div>
											<div>{t("balance") + ":"}</div>
											<div>
												{numberFormat(
													totalBalance.toFixed(6)
												) + " BTC"}
											</div>
										</div>
										<div>
											<div>{t("value") + ":"}</div>
											<div>
												{"$" +
													numberFormat(
														(
															user?.spotBalance +
															user?.fundingBalance
														).toFixed(2)
													)}
											</div>
										</div>
										{width > 1200 ? (
											<div className={styles.pnlOverview}>
												<div>
													<span>
														{t("pnl.today")}
													</span>
													<span
														className={
															totalBalanceDiff < 0
																? "priceDown"
																: "priceUp"
														}
													>{`${
														totalBalanceDiff < 0
															? "-"
															: "+"
													}$${numberFormat(
														Math.abs(
															totalBalanceDiff
														)
													)}`}</span>
												</div>
												<div
													onClick={() =>
														navigate("/pnl")
													}
												>
													<span>
														{t("pnl.detail")}
													</span>
													<svg
														width="10"
														height="16"
														viewBox="0 0 10 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M0.316206 15.6838C0.105402 15.473 0 15.2234 0 14.935C0 14.6472 0.105402 14.3979 0.316206 14.1871L6.49275 8.01054L0.295125 1.81291C0.098375 1.61616 0 1.37022 0 1.0751C0 0.779974 0.105402 0.527009 0.316206 0.316206C0.527009 0.105402 0.776601 0 1.06498 0C1.3528 0 1.60211 0.105402 1.81291 0.316206L8.89592 7.42029C8.98024 7.50461 9.04011 7.59596 9.07552 7.69433C9.11037 7.79271 9.1278 7.89811 9.1278 8.01054C9.1278 8.12297 9.11037 8.22837 9.07552 8.32675C9.04011 8.42512 8.98024 8.51647 8.89592 8.60079L1.79183 15.7049C1.59508 15.9016 1.3528 16 1.06498 16C0.776601 16 0.527009 15.8946 0.316206 15.6838Z"
															fill="#5757F7"
														/>
													</svg>
												</div>
											</div>
										) : null}
									</div>
									{user && (
										<AssetsChart
											labels={[
												t("spotWallet"),
												t("fundingWallet"),
											]}
											series={[
												user?.spotBalance,
												user?.fundingBalance,
											]}
											isOverview={true}
										/>
									)}
									{width <= 1200 ? (
										<div className={styles.pnlOverview}>
											<div>
												<span>{t("pnl.today")}</span>
												<span
													className={
														totalBalanceDiff < 0
															? "priceDown"
															: "priceUp"
													}
												>{`${
													totalBalanceDiff < 0
														? "-"
														: "+"
												}$${numberFormat(
													Math.abs(totalBalanceDiff)
												)}`}</span>
											</div>
											<div
												onClick={() => navigate("/pnl")}
											>
												<span>{t("pnl.detail")}</span>
												<svg
													width="10"
													height="16"
													viewBox="0 0 10 16"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M0.316206 15.6838C0.105402 15.473 0 15.2234 0 14.935C0 14.6472 0.105402 14.3979 0.316206 14.1871L6.49275 8.01054L0.295125 1.81291C0.098375 1.61616 0 1.37022 0 1.0751C0 0.779974 0.105402 0.527009 0.316206 0.316206C0.527009 0.105402 0.776601 0 1.06498 0C1.3528 0 1.60211 0.105402 1.81291 0.316206L8.89592 7.42029C8.98024 7.50461 9.04011 7.59596 9.07552 7.69433C9.11037 7.79271 9.1278 7.89811 9.1278 8.01054C9.1278 8.12297 9.11037 8.22837 9.07552 8.32675C9.04011 8.42512 8.98024 8.51647 8.89592 8.60079L1.79183 15.7049C1.59508 15.9016 1.3528 16 1.06498 16C0.776601 16 0.527009 15.8946 0.316206 15.6838Z"
														fill="#5757F7"
													/>
												</svg>
											</div>
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
					<div className="contentContainer">
						<div className="defaultContainer">
							<div className={styles.contentHead}>
								{t("walletHistory")}
							</div>
							<div className={styles.contentBody}>
								<TransactionHistory />
							</div>
						</div>
					</div>
				</>
			) : (
				<LoadingDot />
			)}
		</>
	);
};

export default OverviewWallet;
