import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import Overlay from "react-bootstrap/Overlay";
import { DayPicker } from "react-day-picker";
import { formatDateNotime, getPastDate } from "../../utils/date";

import autoAnimate from "@formkit/auto-animate";
import TotalAssetsCharts from "./TotalAssetsCharts";
import DailyPNLChart from "./DailyPNLChart";
import ProfitChart from "./ProfitChart";
import AssetsChart from "../WalletPage/AssetsChart";
import { useGetSpotAssetsQuery } from "../../services/spot";
import AssetsTable from "./AssetsTable";
import { useGetPNLDetailQuery } from "../../services/wallet";
import AssetsPNLDetail from "./AssetsPNLDetail";

const PNLCharts = () => {
	const [t] = useTranslation("wallet");
	const [range, setRange] = useState(0);
	const [start, setStart] = useState(getPastDate(7, true));
	const [end, setEnd] = useState(getPastDate(1, true));
	const [openCustomRange, setOpenCustomRange] = useState(false);
	const [selectedDate, setSelectedDate] = useState();

	const { data: assets } = useGetSpotAssetsQuery();
	const { data: pnl, refetch } = useGetPNLDetailQuery({ start, end });

	const [labels, setLabels] = useState([""]);
	const [series, setSeries] = useState([0]);
	const [totalBalance, setTotalBalance] = useState(0);

	const [openModal, setOpenModal] = useState(false);
	const [selectedToken, setSelectedToken] = useState();

	const target = useRef(null);
	const parent = useRef(null);

	const handleClickCustom = () => {
		setRange(2);
		setOpenCustomRange(!openCustomRange);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		setSelectedToken();
	};

	useEffect(() => {
		if (selectedToken) {
			setOpenModal(true);
		}
	}, [selectedToken]);

	useEffect(() => {
		if (assets?.length > 0) {
			let total = 0;
			for (let i = 0; i < assets?.length; i++) {
				total += assets[i]?.inBTC;
			}
			setTotalBalance(total);
		}
	}, [assets]);

	useEffect(() => {
		if (assets) {
			let filtered = assets
				.filter((item) => parseFloat(item?.amount) > 0)
				.sort((a, b) => parseFloat(b.inUSD) - parseFloat(a.inUSD));

			if (filtered?.length > 8) {
				let temp = [];

				temp = filtered.splice(7, filtered.length - 7);

				const reducedTemp = temp.reduce((a, b) => {
					return {
						tokenSymbol: t("others"),
						inUSD: parseFloat(a.inUSD) + parseFloat(b.inUSD),
					};
				});

				filtered = [...filtered, reducedTemp];
			}
			setLabels(filtered.map((item) => item?.tokenSymbol));
			setSeries(filtered.map((item) => parseFloat(item?.inUSD)));
		}
	}, [assets, t]);

	useEffect(() => {
		if (range === 0) {
			//week
			setStart(getPastDate(7, true));
			setEnd(getPastDate(0, true));
			setOpenCustomRange(false);
			setSelectedDate();
		}
		if (range === 1) {
			//month
			setStart(getPastDate(30, true));
			setEnd(getPastDate(0, true));
			setOpenCustomRange(false);
			setSelectedDate();
		}
	}, [range]);

	useEffect(() => {
		if (selectedDate?.from && selectedDate?.to && range === 2) {
			setStart(formatDateNotime(selectedDate?.from));
			setEnd(formatDateNotime(selectedDate?.to));
			const timer = setTimeout(() => {
				setOpenCustomRange(false);
			}, 0);
			return () => clearTimeout(timer);
		}
	}, [selectedDate, range]);

	useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent]);

	useEffect(() => {
		if (start && end) {
			refetch();
		}
	}, [start, end, refetch]);

	return (
		<>
			<div className={styles.chartsContainer} ref={parent}>
				<div className={styles.rangeSelect}>
					<div
						onClick={() => setRange(0)}
						className={range === 0 ? styles.selected : undefined}
					>
						{t("pnl.7d")}
					</div>
					<div
						onClick={() => setRange(1)}
						className={range === 1 ? styles.selected : undefined}
					>
						{t("pnl.30d")}
					</div>
					<div
						onClick={handleClickCustom}
						ref={target}
						className={range === 2 ? styles.selected : undefined}
					>
						{selectedDate?.from && selectedDate?.to && range === 2
							? `${formatDateNotime(
									selectedDate?.from
							  )} - ${formatDateNotime(selectedDate?.to)}`
							: t("pnl.custom")}
					</div>
				</div>
				<div className="d-flex justify-content-between w-100">
					<div>
						<div>
							<div>
								<div className={styles.chartItem}>
									<div>{t("pnl.totalAssets")}</div>
									<div>
										<TotalAssetsCharts pnl={pnl} />
									</div>
								</div>
								<div className={styles.chartItem}>
									<div>{t("pnl.dailyPNL")}</div>
									<DailyPNLChart pnl={pnl} />
								</div>
								<div className={styles.chartItem}>
									<div>{t("pnl.profit")}</div>
									<ProfitChart pnl={pnl} />
								</div>
							</div>
						</div>
					</div>

					{assets?.length > 0 ? (
						<div className={styles.assetsList}>
							<div>{t("pnl.assetsDistribution")}</div>
							<AssetsChart
								isOverview={false}
								isPNL={true}
								labels={labels}
								series={series}
							/>
							<AssetsTable
								setSelectedToken={(value) =>
									setSelectedToken(value)
								}
							/>
						</div>
					) : null}
				</div>
			</div>

			<Overlay
				target={target.current}
				show={openCustomRange}
				placement="bottom"
			>
				{({
					placement: _placement,
					arrowProps: _arrowProps,
					show: _show,
					popper: _popper,
					hasDoneInitialMeasure: _hasDoneInitialMeasure,
					...props
				}) => (
					<div {...props} className="contentContainerMediumFixed">
						<DayPicker
							mode="range"
							selected={selectedDate}
							onSelect={setSelectedDate}
							showOutsideDays
							fixedWeeks
						/>
					</div>
				)}
			</Overlay>
			{openModal ? (
				<AssetsPNLDetail
					openModal={openModal}
					handleClose={handleCloseModal}
					token={selectedToken}
				/>
			) : null}
		</>
	);
};

export default PNLCharts;
