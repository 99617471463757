import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useGetUserInfoQuery } from "../../services/auth";
import styles from "./styles.module.scss";
import CopyComponent from "../Common/CopyComponent";
import InviteFriendsModal from "./InviteFriendModal";
import autoAnimate from "@formkit/auto-animate";
import { HashLoader } from "react-spinners";

const InviteFriends = () => {
	const [t] = useTranslation("common");
	const navigate = useNavigate();
	const { data: user, isLoading } = useGetUserInfoQuery();
	const parent = useRef(null);

	const [openModal, setOpenModal] = useState(false);
	useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent]);
	return (
		<>
			<div className={styles.inviteFriends} ref={parent}>
				<div>
					<div>{t("targetText1")}</div>
					<div>{t("targetText2")}</div>
				</div>
				{isLoading ? (
					<div>
						<HashLoader color="#5757F7" size={40} />
					</div>
				) : (
					<div>
						{user?.email ? (
							<>
								<div className={styles.refID}>
									<div>{t("refID")}</div>
									<div>
										<span>{user?.id}</span>
										<CopyComponent
											copyText={user?.id}
											showText={false}
										/>
									</div>
								</div>

								<div className={styles.refLink}>
									<div>{t("refLink")}</div>
									<div>
										<span>{`${window.location.hostname}/?ref=${user?.id}`}</span>
										<CopyComponent
											copyText={`${window.location.hostname}/?ref=${user?.id}`}
											showText={false}
										/>
									</div>
								</div>

								<button
									className="btnDark"
									onClick={() => setOpenModal(true)}
								>
									{t("invite")}
								</button>
							</>
						) : (
							<div>
								<button
									className="btnDark"
									onClick={() => navigate("/sign-in")}
								>
									{t("auth2Join")}
								</button>
							</div>
						)}
					</div>
				)}
			</div>
			<InviteFriendsModal
				openModal={openModal}
				setOpenModal={(value) => setOpenModal(value)}
			/>
		</>
	);
};

export default InviteFriends;
