import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import {
	updateOrderDetail,
	changeTradeType,
	changeTradeSide,
} from "../../Store/spot/";

import {
	useGetSpotAssetsQuery,
	useGetSpotOrdersQuery,
	useCreateBuyLimitOrderMutation,
	useCreateSellLimitOrderMutation,
	useCreateBuyMarketOrderMutation,
	useCreateSellMarketOrderMutation,
} from "../../services/spot";

import {
	formatNumberBySize,
	convertNumString,
	numberFormat,
} from "../../utils/formatNumber";

import { Form } from "react-bootstrap";

import { toast } from "react-toastify";
import { toastCfg } from "../../config";
import TooltipComponent from "../Common/TooltipComponent";
import { useNavigate } from "react-router";

const TradeComponent = () => {
	const [t] = useTranslation("trade");
	const search = useLocation().search;

	const [baseAsset, quoteAsset] = new URLSearchParams(search)
		.get("token")
		.split("-");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		data: assetsData,
		isLoading: isLoadingAssetsData,
		refetch: refetchAssetsData,
	} = useGetSpotAssetsQuery();
	const { refetch: refetchOrdersData } = useGetSpotOrdersQuery({
		skip: 0,
		status: "NEW",
	});

	const user = useSelector((state) => state.auth);

	const [createBuyLimitOrder, { isLoading: isLoadingBuyLimit }] =
		useCreateBuyLimitOrderMutation();
	const [createSellLimitOrder, { isLoading: isLoadingSellLimit }] =
		useCreateSellLimitOrderMutation();
	const [createBuyMarketOrder, { isLoading: isLoadingBuyMarket }] =
		useCreateBuyMarketOrderMutation();
	const [createSellMarketOrder, { isLoading: isLoadingSellMarket }] =
		useCreateSellMarketOrderMutation();

	const { currentPrice, tickSize, stepSize } = useSelector(
		(state) => state.spot.priceChange24h
	);
	// const [activeTab, ] = useState("BUY");
	const { isPro } = useSelector((state) => state.spot);

	const [assetsBalance, setAssetsBalance] = useState({
		baseAsset: 0,
		quoteAsset: 0,
	});

	const {
		quantity,
		price,
		range,
		total,
		tradeType,
		side: activeTab,
	} = useSelector((state) => state.spot.orderDetail);

	const setTradeType = (value) => dispatch(changeTradeType(value));
	const setActiveTab = (value) => dispatch(changeTradeSide(value));

	const [startPrice, setStartPrice] = useState(0);

	const refetchData = () => {
		refetchOrdersData();
	};

	const handleChangePrice = (e) => {
		let newPrice = convertNumString(e.target.value);

		dispatch(
			updateOrderDetail({
				price: formatNumberBySize(newPrice, tickSize),
				total: formatNumberBySize(
					newPrice * parseFloat(quantity),
					tickSize
				),
			})
		);
	};

	const handleChangeQuantityLimit = (e) => {
		let newQuantity = convertNumString(e.target.value);
		let currentAmount =
			activeTab === "BUY"
				? assetsBalance.quoteAsset
				: assetsBalance.baseAsset;
		if (newQuantity > currentAmount) newQuantity = currentAmount;
		let newRange = parseInt(
			((parseFloat(newQuantity) * parseFloat(price)) / currentAmount) *
				100
		);
		if (newRange > 100) newRange = 100;

		dispatch(
			updateOrderDetail({
				quantity: formatNumberBySize(newQuantity, stepSize),
				total: formatNumberBySize(
					newQuantity * parseFloat(price),
					tickSize
				),
				range: newRange,
			})
		);
	};

	const handleChangeQuantityMarket = (e) => {
		let newQuantity = convertNumString(e.target.value);
		let currentAmount =
			activeTab === "BUY"
				? assetsBalance.quoteAsset
				: assetsBalance.baseAsset;
		if (newQuantity > currentAmount) newQuantity = currentAmount;
		let newRange = parseInt(
			(parseFloat(newQuantity) / currentAmount) * 100
		);
		if (newRange > 100) newRange = 100;
		if (activeTab === "BUY") {
			dispatch(
				updateOrderDetail({
					quantity: formatNumberBySize(newQuantity, tickSize),
					range: newRange,
					total: formatNumberBySize(newQuantity, tickSize),
				})
			);
		} else {
			dispatch(
				updateOrderDetail({
					quantity: formatNumberBySize(newQuantity, stepSize),
					range: newRange,
					total: formatNumberBySize(newQuantity, stepSize),
				})
			);
		}
	};

	const handleChangeQuantity = (e) => {
		if (tradeType === "LIMIT") {
			handleChangeQuantityLimit(e);
		} else {
			handleChangeQuantityMarket(e);
		}
	};

	const handleChangeRangeLimit = (e) => {
		let currentAmount =
			activeTab === "BUY"
				? assetsBalance.quoteAsset
				: assetsBalance.baseAsset;
		if (activeTab === "BUY") {
			dispatch(
				updateOrderDetail({
					range: parseFloat(e.target.value),
					total: formatNumberBySize(
						(parseFloat(e.target.value) / 100) * currentAmount,
						tickSize
					),
					quantity: formatNumberBySize(
						((parseFloat(e.target.value) / 100) * currentAmount) /
							parseFloat(price),
						stepSize
					),
				})
			);
		} else {
			dispatch(
				updateOrderDetail({
					range: parseFloat(e.target.value),

					quantity: formatNumberBySize(
						(parseFloat(e.target.value) / 100) *
							parseFloat(currentAmount),
						stepSize
					),
					total: formatNumberBySize(
						(parseFloat(e.target.value) / 100) *
							parseFloat(currentAmount) *
							parseFloat(price),
						tickSize
					),
				})
			);
		}
	};

	const handleChangeRangeMarket = (e) => {
		let currentAmount =
			activeTab === "BUY"
				? assetsBalance.quoteAsset
				: assetsBalance.baseAsset;

		if (activeTab === "BUY") {
			dispatch(
				updateOrderDetail({
					range: parseFloat(e.target.value),
					quantity: formatNumberBySize(
						(parseFloat(e.target.value) / 100) * currentAmount,
						tickSize
					),
					total: formatNumberBySize(
						(parseFloat(e.target.value) / 100) * currentAmount,
						tickSize
					),
				})
			);
		} else {
			dispatch(
				updateOrderDetail({
					range: parseFloat(e.target.value),
					quantity: formatNumberBySize(
						(parseFloat(e.target.value) / 100) * currentAmount,
						stepSize
					),
					total: formatNumberBySize(
						(parseFloat(e.target.value) / 100) * currentAmount,
						stepSize
					),
				})
			);
		}
	};

	const handleChangeRange = (e) => {
		if (tradeType === "LIMIT") {
			handleChangeRangeLimit(e);
		} else {
			handleChangeRangeMarket(e);
		}
	};

	const handleChangeTotal = (e) => {
		let currentAmount =
			activeTab === "BUY"
				? assetsBalance.quoteAsset
				: assetsBalance.baseAsset;
		let newRange = parseInt(
			(convertNumString(e.target.value) / parseFloat(currentAmount)) * 100
		);
		if (newRange > 100) newRange = 100;

		dispatch(
			updateOrderDetail({
				total: formatNumberBySize(
					convertNumString(e.target.value),
					tickSize
				),
				quantity: formatNumberBySize(
					convertNumString(e.target.value) / parseFloat(price),
					tickSize
				),
				range: newRange,
			})
		);
	};

	const handlePriceFocus = () => {
		if (tradeType !== "LIMIT") {
			setTradeType("LIMIT");
			dispatch(
				updateOrderDetail({
					price: parseFloat(currentPrice),
				})
			);
		}
	};

	const handleAddPrice = () => {
		let newPrice;
		if (tradeType !== "LIMIT") {
			setTradeType("LIMIT");
			newPrice = parseFloat(parseFloat(currentPrice) + tickSize);
		} else {
			newPrice = parseFloat(parseFloat(price) + tickSize);
		}

		dispatch(
			updateOrderDetail({
				price: formatNumberBySize(newPrice, tickSize, false),
				total: formatNumberBySize(
					newPrice * parseFloat(quantity),
					tickSize
				),
			})
		);
	};

	const handleSubPrice = () => {
		let newPrice;
		if (tradeType !== "LIMIT") {
			setTradeType("LIMIT");
			newPrice = parseFloat(parseFloat(currentPrice) - tickSize);
		} else {
			newPrice = parseFloat(parseFloat(price) - tickSize);
		}

		dispatch(
			updateOrderDetail({
				price: formatNumberBySize(newPrice, tickSize, false),
				total: formatNumberBySize(
					newPrice * parseFloat(quantity),
					tickSize
				),
			})
		);
	};

	const handleAddQuantity = () => {
		const diff =
			activeTab === "BUY" && tradeType === "MARKET" ? tickSize : stepSize;

		let newQuantity = parseFloat(quantity) + parseFloat(diff);

		let currentAmount =
			activeTab === "BUY"
				? assetsBalance.quoteAsset
				: assetsBalance.baseAsset;
		if (newQuantity > currentAmount) newQuantity = currentAmount;

		if (tradeType === "LIMIT") {
			let newRange = parseInt(
				((parseFloat(newQuantity) * parseFloat(price)) /
					currentAmount) *
					100
			);
			if (newRange > 100) newRange = 100;

			dispatch(
				updateOrderDetail({
					quantity: formatNumberBySize(newQuantity, stepSize, false),
					total: formatNumberBySize(
						parseFloat(newQuantity * parseFloat(price)),
						tickSize
					),
					range: newRange,
				})
			);
		} else {
			let newRange = parseInt(
				(parseFloat(newQuantity) / currentAmount) * 100
			);
			if (newRange > 100) newRange = 100;
			if (activeTab === "BUY") {
				dispatch(
					updateOrderDetail({
						quantity: formatNumberBySize(
							newQuantity,
							tickSize,
							false
						),
						range: newRange,
						total: formatNumberBySize(newQuantity, tickSize),
					})
				);
			} else {
				dispatch(
					updateOrderDetail({
						quantity: formatNumberBySize(
							newQuantity,
							stepSize,
							false
						),
						range: newRange,
						total: formatNumberBySize(newQuantity, stepSize),
					})
				);
			}
		}
	};

	const handleSubQuantity = () => {
		const diff =
			activeTab === "BUY" && tradeType === "MARKET"
				? parseFloat(tickSize)
				: parseFloat(stepSize);

		let newQuantity = quantity - diff > 0 ? parseFloat(quantity - diff) : 0;
		let currentAmount =
			activeTab === "BUY"
				? assetsBalance.quoteAsset
				: assetsBalance.baseAsset;
		if (newQuantity > currentAmount) newQuantity = currentAmount;
		if (tradeType === "LIMIT") {
			let newRange = parseInt(
				((parseFloat(newQuantity) * parseFloat(price)) /
					currentAmount) *
					100
			);
			if (newRange > 100) newRange = 100;
			dispatch(
				updateOrderDetail({
					quantity: formatNumberBySize(newQuantity, stepSize, false),
					total: formatNumberBySize(
						parseFloat(newQuantity * parseFloat(price)),
						tickSize
					),
					range: newRange,
				})
			);
		} else {
			let newRange = parseInt(
				(parseFloat(newQuantity) / currentAmount) * 100
			);
			if (newRange > 100) newRange = 100;
			if (activeTab === "BUY") {
				dispatch(
					updateOrderDetail({
						quantity: formatNumberBySize(
							newQuantity,
							tickSize,
							false
						),
						total: formatNumberBySize(newQuantity, tickSize),
						range: newRange,
					})
				);
			} else {
				dispatch(
					updateOrderDetail({
						quantity: formatNumberBySize(
							newQuantity,
							stepSize,
							false
						),
						total: formatNumberBySize(newQuantity, stepSize),
						range: newRange,
					})
				);
			}
		}
	};
	const submitOrderBuyLimit = async (e) => {
		e.preventDefault();
		if (user?.access_token) {
			try {
				const response = await createBuyLimitOrder({
					price: price,
					quantity: Number(quantity),
					symbol: baseAsset + quoteAsset,
				});
				const result = response.data;
				if (result?.statusCode === "SUCCESS") {
					toast.success(t("placeOrderBuy"), toastCfg);
				}
				if (response?.error) {
					if (
						response.error.data.message ===
						"Filter failure: MIN_NOTIONAL"
					) {
						toast.error(t("minNotional"), toastCfg);
					} else {
						toast.error(response.error.data.message, toastCfg);
					}
				}
			} catch (error) {
				console.log("error", error);
				toast.error(t("sysErr"), toastCfg);
			}

			refetchData();
			dispatch(
				updateOrderDetail({
					quantity: 0,
					range: 0,
					total: 0,
				})
			);
		} else {
			toast.warn(t("plsLogin"), toastCfg);
		}
	};

	const submitOrderSellLimit = async (e) => {
		e.preventDefault();
		if (user?.access_token) {
			try {
				const response = await createSellLimitOrder({
					price: price,
					quantity: Number(quantity),
					symbol: baseAsset + quoteAsset,
				});
				const result = response.data;
				if (result?.statusCode === "SUCCESS") {
					toast.success(t("placeOrderSell"), toastCfg);
				}
				if (response?.error) {
					if (
						response.error.data.message ===
						"Filter failure: MIN_NOTIONAL"
					) {
						toast.error(t("minNotional"), toastCfg);
					} else {
						toast.error(response.error.data.message, toastCfg);
					}
				}
			} catch (error) {
				console.log("error", error);
				toast.error(t("sysErr"), toastCfg);
			}

			refetchData();
			dispatch(
				updateOrderDetail({
					quantity: 0,
					range: 0,
					total: 0,
				})
			);
		} else {
			toast.warn(t("plsLogin"), toastCfg);
		}
	};

	const submitOrderBuyMaket = async (e) => {
		e.preventDefault();
		if (user?.access_token) {
			try {
				const response = await createBuyMarketOrder({
					quantity: Number(quantity),
					symbol: baseAsset + quoteAsset,
				});
				const result = response.data;

				if (result?.statusCode === "SUCCESS") {
					toast.success(t("placeOrderBuy"), toastCfg);
				}
				if (response?.error) {
					if (
						response.error.data.message ===
						"Filter failure: MIN_NOTIONAL"
					) {
						toast.error(t("minNotional"), toastCfg);
					} else {
						toast.error(response.error.data.message, toastCfg);
					}
				}
			} catch (error) {
				console.log("error", error);
				toast.error(t("sysErr"), toastCfg);
			}

			refetchData();
			dispatch(
				updateOrderDetail({
					quantity: 0,
					range: 0,
					total: 0,
				})
			);
		} else {
			toast.warn(t("plsLogin"), toastCfg);
		}
	};

	const submitOrderSellMaket = async (e) => {
		e.preventDefault();
		if (user?.access_token) {
			try {
				const response = await createSellMarketOrder({
					quantity: Number(quantity),
					symbol: baseAsset + quoteAsset,
				});
				const result = response.data;
				if (result?.statusCode === "SUCCESS") {
					toast.success(t("placeOrderSell"), toastCfg);
				}
				if (response?.error) {
					if (
						response.error.data.message ===
						"Filter failure: MIN_NOTIONAL"
					) {
						toast.error(t("minNotional"), toastCfg);
					} else {
						toast.error(response.error.data.message, toastCfg);
					}
				}
			} catch (error) {
				console.log("error", error);
				toast.error(t("sysErr"), toastCfg);
			}

			refetchData();
			dispatch(
				updateOrderDetail({
					quantity: 0,
					range: 0,
					total: 0,
				})
			);
		} else {
			toast.warn(t("plsLogin"), toastCfg);
		}
	};

	const handleSubmitOrder = (e) => {
		if (activeTab === "BUY") {
			if (tradeType === "LIMIT") {
				submitOrderBuyLimit(e);
			} else {
				submitOrderBuyMaket(e);
			}
		} else {
			if (tradeType === "LIMIT") {
				submitOrderSellLimit(e);
			} else {
				submitOrderSellMaket(e);
			}
		}
	};

	useEffect(() => {
		setTradeType("MARKET");
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			refetchData();
		}, 10000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		const baseAssetData = assetsData?.filter(
			(each) => each.tokenSymbol === baseAsset
		)[0];
		const quoteAssetData = assetsData?.filter(
			(each) => each.tokenSymbol === quoteAsset
		)[0];

		setAssetsBalance({
			quoteAsset: quoteAssetData
				? parseFloat(quoteAssetData?.amount) -
				  parseFloat(quoteAssetData?.tradingAmount)
				: 0,
			baseAsset: baseAssetData
				? parseFloat(baseAssetData?.amount) -
				  parseFloat(baseAssetData?.tradingAmount)
				: 0,
		});
	}, [isLoadingAssetsData, assetsData, baseAsset, quoteAsset]);

	useEffect(() => {
		refetchAssetsData();
	}, []);

	useEffect(() => {
		dispatch(
			updateOrderDetail({
				type: tradeType,
				side: activeTab,
				range: 0,
				total: 0,
				quantity: 0,
			})
		);
	}, [activeTab, tradeType]);

	useEffect(() => {
		if (tradeType === "LIMIT") {
			dispatch(
				updateOrderDetail({
					price: parseFloat(currentPrice),
				})
			);
		}
	}, [tradeType]);

	return (
		<div className={styles.tradeComponent}>
			<div className={styles.tradeTab}>
				<div
					onClick={() => setActiveTab("BUY")}
					className={activeTab === "BUY" ? styles.activeBuy : ""}
				>
					{t("buy")}
				</div>
				<div
					onClick={() => setActiveTab("SELL")}
					className={activeTab === "SELL" ? styles.activeSell : ""}
				>
					{t("sell")}
				</div>
			</div>
			<form
				className="contentContainerMedium"
				onSubmit={handleSubmitOrder}
			>
				<div className={styles.trade}>
					<div>
						<TooltipComponent
							content={
								<ol>
									<li>{t("tooltip.market")}</li>
									<li>{t("tooltip.limit")}</li>
								</ol>
							}
						>
							<div className={styles.tradeTypeHead}>
								<span className="heading5">
									{t("tradeType")}
								</span>
								<svg
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M10.0003 1.66699C5.39199 1.66699 1.66699 5.39199 1.66699 10.0003C1.66699 14.6087 5.39199 18.3337 10.0003 18.3337C14.6087 18.3337 18.3337 14.6087 18.3337 10.0003C18.3337 5.39199 14.6087 1.66699 10.0003 1.66699Z"
										fill="#FDFCFF"
										stroke="#939393"
									/>
									<ellipse
										cx="10.0003"
										cy="5.83333"
										rx="0.833333"
										ry="0.833333"
										fill="#939393"
									/>
									<rect
										x="9.16699"
										y="8.33301"
										width="1.66667"
										height="6.66667"
										rx="0.833333"
										fill="#939393"
									/>
								</svg>
							</div>
						</TooltipComponent>

						<div className={styles.selectContainer}>
							<Form.Select
								className={styles.selectType}
								value={tradeType}
								onChange={(e) => setTradeType(e.target.value)}
							>
								<option value="LIMIT">LIMIT</option>
								<option value="MARKET">MARKET</option>
							</Form.Select>
						</div>
					</div>
					<div className={styles.inputSection}>
						<span className="heading5">
							<span>{`${t("price")} (${quoteAsset})`}</span>
						</span>
						<div className={styles.inputContainer}>
							<div
								className={styles.iconBtn}
								onClick={handleSubPrice}
							>
								<svg
									width="14"
									height="2"
									viewBox="0 0 14 2"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1 2C0.716667 2 0.479 1.904 0.287 1.712C0.0956668 1.52067 0 1.28333 0 1C0 0.716667 0.0956668 0.479 0.287 0.287C0.479 0.0956664 0.716667 0 1 0H13C13.2833 0 13.5207 0.0956664 13.712 0.287C13.904 0.479 14 0.716667 14 1C14 1.28333 13.904 1.52067 13.712 1.712C13.5207 1.904 13.2833 2 13 2H1Z"
										fill="#939393"
									/>
								</svg>
							</div>

							{tradeType === "MARKET" ? (
								<NumericFormat
									decimalSeparator="."
									thousandSeparator=","
									allowLeadingZeros={false}
									allowNegative={false}
									valueIsNumericString={true}
									className={styles.customInput}
									value={currentPrice}
									onFocus={handlePriceFocus}
									min={0}
								/>
							) : (
								<NumericFormat
									decimalSeparator="."
									thousandSeparator=","
									allowLeadingZeros={false}
									allowNegative={false}
									valueIsNumericString={true}
									className={styles.customInput}
									value={price}
									onChange={handleChangePrice}
									min={0}
								/>
							)}

							<div
								className={styles.iconBtn}
								onClick={handleAddPrice}
							>
								<svg
									width="14"
									height="14"
									viewBox="0 0 14 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M7 14C6.71667 14 6.47933 13.904 6.288 13.712C6.096 13.5207 6 13.2833 6 13V8H1C0.716667 8 0.479 7.904 0.287 7.712C0.0956668 7.52067 0 7.28333 0 7C0 6.71667 0.0956668 6.479 0.287 6.287C0.479 6.09567 0.716667 6 1 6H6V1C6 0.716667 6.096 0.479 6.288 0.287C6.47933 0.0956668 6.71667 0 7 0C7.28333 0 7.521 0.0956668 7.713 0.287C7.90433 0.479 8 0.716667 8 1V6H13C13.2833 6 13.5207 6.09567 13.712 6.287C13.904 6.479 14 6.71667 14 7C14 7.28333 13.904 7.52067 13.712 7.712C13.5207 7.904 13.2833 8 13 8H8V13C8 13.2833 7.90433 13.5207 7.713 13.712C7.521 13.904 7.28333 14 7 14Z"
										fill="#939393"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div className={styles.inputSection}>
						<span className="heading5">
							<span>{`${t("quantity")} (${
								activeTab === "BUY" && tradeType === "MARKET"
									? quoteAsset
									: baseAsset
							})`}</span>
						</span>
						<div className={styles.inputContainer}>
							<div
								className={styles.iconBtn}
								onClick={handleSubQuantity}
							>
								<svg
									width="14"
									height="2"
									viewBox="0 0 14 2"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1 2C0.716667 2 0.479 1.904 0.287 1.712C0.0956668 1.52067 0 1.28333 0 1C0 0.716667 0.0956668 0.479 0.287 0.287C0.479 0.0956664 0.716667 0 1 0H13C13.2833 0 13.5207 0.0956664 13.712 0.287C13.904 0.479 14 0.716667 14 1C14 1.28333 13.904 1.52067 13.712 1.712C13.5207 1.904 13.2833 2 13 2H1Z"
										fill="#939393"
									/>
								</svg>
							</div>

							<NumericFormat
								decimalSeparator="."
								thousandSeparator=","
								allowLeadingZeros={false}
								allowNegative={false}
								valueIsNumericString={true}
								className={styles.customInput}
								value={quantity}
								onChange={handleChangeQuantity}
								min={0}
							/>
							<div
								className={styles.iconBtn}
								onClick={handleAddQuantity}
							>
								<svg
									width="14"
									height="14"
									viewBox="0 0 14 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M7 14C6.71667 14 6.47933 13.904 6.288 13.712C6.096 13.5207 6 13.2833 6 13V8H1C0.716667 8 0.479 7.904 0.287 7.712C0.0956668 7.52067 0 7.28333 0 7C0 6.71667 0.0956668 6.479 0.287 6.287C0.479 6.09567 0.716667 6 1 6H6V1C6 0.716667 6.096 0.479 6.288 0.287C6.47933 0.0956668 6.71667 0 7 0C7.28333 0 7.521 0.0956668 7.713 0.287C7.90433 0.479 8 0.716667 8 1V6H13C13.2833 6 13.5207 6.09567 13.712 6.287C13.904 6.479 14 6.71667 14 7C14 7.28333 13.904 7.52067 13.712 7.712C13.5207 7.904 13.2833 8 13 8H8V13C8 13.2833 7.90433 13.5207 7.713 13.712C7.521 13.904 7.28333 14 7 14Z"
										fill="#939393"
									/>
								</svg>
							</div>
						</div>
					</div>
					<TooltipComponent
						text={`${parseInt(range)}%`}
						place="right"
					>
						<div className={styles.rangeSelect}>
							<div className={styles.inputSection}>
								<span>{t("chooseRange")}</span>

								<div className={styles.customSliderStep}>
									{[0, 25, 50, 75, 100].map((value) => (
										<span
											key={value}
											className={
												range >= value
													? styles.slideOptionPassed
													: styles.slideOption
											}
										></span>
									))}
								</div>

								<input
									type="range"
									step="1"
									className={styles.slider}
									value={range}
									onChange={handleChangeRange}
								/>

								<span
									className={styles.slideBar}
									style={{ width: `${range}%` }}
								></span>

								<div className={styles.percentSelectContainer}>
									{[0, 25, 50, 75, 100].map((value) => (
										<span
											className={
												range === value
													? styles.percentSelectActive
													: styles.percentSelect
											}
											key={value}
											onClick={() =>
												handleChangeRange({
													target: {
														value: value,
													},
												})
											}
										>{`${value}%`}</span>
									))}
								</div>
							</div>
						</div>
					</TooltipComponent>

					<div className={styles.total}>
						<div className={styles.inputSection}>
							<span className="heading5">{`${t("total")} (${
								activeTab === "SELL" && tradeType === "MARKET"
									? baseAsset
									: quoteAsset
							})`}</span>
							<div className={styles.inputContainer}>
								<NumericFormat
									decimalSeparator="."
									thousandSeparator=","
									allowLeadingZeros={false}
									allowNegative={false}
									valueIsNumericString={true}
									className={styles.customInputTotal}
									value={total}
									onChange={handleChangeTotal}
									disabled={tradeType === "MARKET"}
								/>
							</div>

							<div>
								<span>{t("available")}</span>
								{activeTab === "BUY" ? (
									<span>{`${numberFormat(
										formatNumberBySize(
											assetsBalance.quoteAsset,
											tickSize
										)
									)} ${quoteAsset}`}</span>
								) : (
									<span>{`${numberFormat(
										formatNumberBySize(
											assetsBalance.baseAsset,
											stepSize
										)
									)} ${baseAsset}`}</span>
								)}
							</div>
						</div>
					</div>

					<button
						className={
							activeTab === "BUY" ? styles.buyBtn : styles.sellBtn
						}
						onClick={
							user?.access_token
								? handleSubmitOrder
								: () => navigate("/sign-in")
						}
						type="submit"
					>
						{user?.access_token
							? activeTab === "BUY"
								? t("buy")
								: t("sell")
							: t("unAuth")}
					</button>
				</div>
			</form>
		</div>
	);
};

export default TradeComponent;
