import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Chart from "react-apexcharts";
import {
	useGetKLinesQuery,
	useGetTrendingPairsQuery,
} from "../../services/market";
import autoAnimate from "@formkit/auto-animate";
import { numberFormat, compactNumber } from "../../utils/formatNumber";
import { useSelector } from "react-redux";
import { formatDate } from "../../utils/date";

const Trending = () => {
	const [t] = useTranslation("common");

	const { data: trendingPairs, isLoading: isLoadingPairs } =
		useGetTrendingPairsQuery();

	const parent = useRef(null);
	const container = useRef(null);

	useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent]);

	useEffect(() => {
		container.current && autoAnimate(container.current);
	}, [container]);

	return (
		<div ref={container}>
			<p className={styles.heading}>{t("trending")}</p>
			<div className={styles.trendingContainer} ref={parent}>
				{!isLoadingPairs && trendingPairs?.data
					? trendingPairs?.data?.map((pair) => (
							<TrendingItems
								key={pair?.id}
								data={pair}
								// newPrice={getNewPrice(pair?.id)}
							/>
					  ))
					: null}
			</div>
		</div>
	);
};

const TrendingItems = ({ data, newPrice = 0 }) => {
	const navigate = useNavigate();

	const { data: seriesData, isLoading: isLoadingKlines } = useGetKLinesQuery({
		symbol: data?.symbol,
	});

	const parent = useRef(null);

	useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent]);

	return (
		<div
			className={styles.trendingItem}
			onClick={() =>
				(window.location = `/spot-trade?token=${data?.baseAsset}-${data?.quoteAsset}`)
			}
		>
			<div className={styles.dashboardChart} ref={parent}>
				{!isLoadingKlines && seriesData ? (
					<Chart
						type="area"
						height={100}
						width={166}
						series={[
							{
								data: seriesData[data?.symbol] || [0],
							},
						]}
						options={{
							chart: {
								toolbar: {
									show: false,
								},
								sparkline: {
									enabled: true,
								},
								animations: {
									enabled: false,
								},
							},
							dataLabels: {
								enabled: false,
							},
							stroke: {
								curve: "smooth",
								width: 1,
							},

							xaxis: {
								labels: {
									show: true,
								},
								categories: seriesData
									? seriesData.timestamp[data?.symbol].map(
											(time) => formatDate(time)
									  )
									: [""],
							},
							yaxis: {
								floating: true,
								axisTicks: {
									show: false,
								},
								axisBorder: {
									show: false,
								},
								labels: {
									show: false,
								},
							},
							tooltip: {
								x: {
									show: true,
								},
								y: {
									title: {
										formatter: () => data?.symbol,
									},
								},
							},
							fill: {
								type: "solid",
								colors: ["#E6E6FE"],
							},
							colors: ["#5757F7"],
						}}
					/>
				) : null}
			</div>
			<PriceDetail data={data} />
		</div>
	);
};

const PriceDetail = ({ data }) => {
	const [t] = useTranslation("common");
	const { changeData } = useSelector((state) => state.common);

	const getNewPrice = (symbol) => {
		if (!changeData || !changeData?.length > 0) return 0;
		return changeData?.filter((p) => p?.s === symbol)[0]?.c || 0;
	};
	const isPriceDown = () => data?.priceChangePercent[0] === "-";

	const [currentPrice, setCurrentPrice] = useState(data?.lastPrice);

	useEffect(() => {
		const newPrice = getNewPrice(data?.symbol);
		if (newPrice > 0) {
			setCurrentPrice(newPrice);
		}
	}, [changeData]);
	return (
		<div className={styles.priceDetailContainer}>
			<div className={styles.trendingDetailFirstLine}>
				<span
					className={styles.symbol}
				>{`${data?.baseAsset}/${data?.quoteAsset}`}</span>
				<div className="d-flex align-items-center justify-content-between">
					<span
						className={
							isPriceDown() ? styles.priceDown : styles.priceUp
						}
					>
						{"$" + numberFormat(currentPrice)}
					</span>
					{isPriceDown() ? (
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<mask
								id="mask0_368_1796"
								maskUnits="userSpaceOnUse"
								x="0"
								y="0"
								width="16"
								height="16"
							>
								<rect width="16" height="16" fill="#D9D9D9" />
							</mask>
							<g mask="url(#mask0_368_1796)">
								<path
									d="M7.53285 9.53332L5.79952 7.79999C5.58841 7.58888 5.5413 7.34732 5.65819 7.07532C5.77463 6.80288 5.98285 6.66666 6.28285 6.66666H9.71619C10.0162 6.66666 10.2244 6.80288 10.3409 7.07532C10.4577 7.34732 10.4106 7.58888 10.1995 7.79999L8.46619 9.53332C8.39952 9.59999 8.3273 9.64999 8.24952 9.68332C8.17174 9.71666 8.08841 9.73332 7.99952 9.73332C7.91063 9.73332 7.8273 9.71666 7.74952 9.68332C7.67174 9.64999 7.59952 9.59999 7.53285 9.53332Z"
									fill="#DD4C56"
								/>
							</g>
						</svg>
					) : (
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<mask
								id="mask0_368_1813"
								maskUnits="userSpaceOnUse"
								x="0"
								y="0"
								width="16"
								height="16"
							>
								<rect width="16" height="16" fill="#D9D9D9" />
							</mask>
							<g mask="url(#mask0_368_1813)">
								<path
									d="M6.28285 9.33333C5.98285 9.33333 5.77463 9.19711 5.65819 8.92466C5.5413 8.65266 5.58841 8.41111 5.79952 8.2L7.53285 6.46666C7.59952 6.4 7.67174 6.35 7.74952 6.31666C7.8273 6.28333 7.91063 6.26666 7.99952 6.26666C8.08841 6.26666 8.17174 6.28333 8.24952 6.31666C8.3273 6.35 8.39952 6.4 8.46619 6.46666L10.1995 8.2C10.4106 8.41111 10.4577 8.65266 10.3409 8.92466C10.2244 9.19711 10.0162 9.33333 9.71619 9.33333H6.28285Z"
									fill="#2EBD56"
								/>
							</g>
						</svg>
					)}
				</div>
			</div>
			<div className={styles.trendingDetailSecondLine}>
				<span className={styles.volume}>{`${t(
					"24hvolume"
				)}: $${compactNumber(data?.volume * data?.lastPrice)}`}</span>

				<div className="d-flex align-items-center justify-content-between">
					<span
						className={
							isPriceDown() ? styles.priceDown : styles.priceUp
						}
					>
						{`${!isPriceDown() ? "+" : ""}${
							data?.priceChangePercent
						}%`}
					</span>
				</div>
			</div>
		</div>
	);
};

export default Trending;
