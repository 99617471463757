import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import {
	useGetAPIKeyListQuery,
	useChangeAPIKeyLimitMutation,
	useDeactivateAPIKeyMutation,
} from "../../services/auth";
import { useTranslation } from "react-i18next";
import autoAnimate from "@formkit/auto-animate";
import CopyComponent from "../Common/CopyComponent";
import LoadingDot from "../Animation/LoadingDot";
import { toast } from "react-toastify";
import { toastCfg } from "../../config";

const KeyList = () => {
	const { data: list } = useGetAPIKeyListQuery();
	const parent = useRef(null);

	useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent]);

	return (
		<div className={styles.keyList} ref={parent}>
			{list?.result?.data?.map((apiKey) => (
				<ListItem key={apiKey.id} keyData={apiKey} />
			))}
		</div>
	);
};

const ListItem = ({ keyData }) => {
	const [t] = useTranslation("common");
	const [showDetail, setShowDetail] = useState(false);
	const [enableSpot, setEnableSpot] = useState(false);
	const parent = useRef(null);

	const [changeAPIKeyLimit] = useChangeAPIKeyLimitMutation();
	const [deactivateAPIKey] = useDeactivateAPIKeyMutation();
	const { refetch: refetchKeyList } = useGetAPIKeyListQuery();

	const changeEnableSpot = () => {
		setEnableSpot(!enableSpot);
	};

	const handleChangeAPIKeyLimit = async () => {
		const res = await changeAPIKeyLimit({
			publicKey: keyData?.id,
			enableTrade: enableSpot,
		});

		if (res?.data?.statusCode === "SUCCESS") {
			toast.success(t("api.saveSuccess"), toastCfg);
		} else {
			toast.error(t("message.error"), toastCfg);
		}
		refetchKeyList();
	};

	const handleDeactivateAPIKey = async () => {
		const res = await deactivateAPIKey({
			publicKey: keyData?.id,
		});

		if (res?.data?.statusCode === "SUCCESS") {
			toast.success(t("api.saveSuccess"), toastCfg);
		} else {
			toast.error(t("message.error"), toastCfg);
		}
		refetchKeyList();
	};

	useEffect(() => {
		setEnableSpot(keyData?.enableTrade);
	}, [keyData]);

	useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent]);

	return (
		<div className="contentContainerMedium">
			<div className={styles.keyItem} ref={parent}>
				<div>
					<div>{keyData?.name}</div>
					<div>
						{enableSpot === keyData.enableTrade ? (
							<div></div>
						) : (
							<div onClick={handleChangeAPIKeyLimit}>
								{t("api.save")}
							</div>
						)}
						<div onClick={handleDeactivateAPIKey}>
							{t("api.remove")}
						</div>
					</div>
				</div>
				<div className={styles.keyDetail}>
					<div>
						<div>{t("api.key")}</div>
						<CopyComponent
							text={keyData?.id}
							copyText={keyData?.id}
						/>
					</div>
					<div>
						<div>{t("api.keyLimit")}</div>
						<div className={styles.keyLimit}>
							<div>
								<input
									type="checkbox"
									checked={true}
									disabled
								/>
								<div>{t("api.readData")}</div>
							</div>
							<div>
								<input
									type="checkbox"
									checked={enableSpot}
									onChange={changeEnableSpot}
								/>
								<div>{t("api.enableSpot")}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default KeyList;
