import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TransferCrypto from "../../Component/WalletPage/TransferCrypto";
import WalletHead from "../../Component/WalletPage/WalletHead";

function TransferPage() {
    const [t] = useTranslation("wallet");

    return (
        <div className="walletLayout">
            <div className="defaultContainer">
                <WalletHead title={`${t("transferCrypto")}`} />
            </div>
            <TransferCrypto />
            {/* keep the last div */}
            <div></div>
        </div>
    );
}
export default TransferPage;
