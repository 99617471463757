import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useGetAPIKeyListQuery } from "../../services/auth";
import { useGetUserInfoQuery } from "../../services/auth";
import LoadingDot from "../../Component/Animation/LoadingDot";
import CreateModal from "../../Component/APIManagement/CreateModal";
import KeyList from "../../Component/APIManagement/KeyList";

const APIManagement = () => {
	const [t] = useTranslation("common");
	const { data: keyList } = useGetAPIKeyListQuery();
	const { data: user, isLoading } = useGetUserInfoQuery();
	const [openModal, setOpenModal] = useState(false);
	useEffect(() => {
		if (!user?.email && !isLoading) {
			window.location.pathname = "/sign-in";
		}
	}, [user, isLoading]);
	return (
		<>
			{isLoading ? (
				<div className="loadingCenter">
					<LoadingDot />
				</div>
			) : (
				<div className={styles.apiManagement}>
					<div>
						<div>{t("apiManage")}</div>
						{keyList?.result?.count > 0 ? (
							<div>
								<button
									className="btnDark"
									onClick={() => setOpenModal(true)}
								>
									{t("api.create")}
								</button>
							</div>
						) : null}
					</div>
					<ol>
						<li>{t("api.r1")}</li>
						<li>{t("api.r2")}</li>
						<li>{t("api.r3")}</li>
					</ol>
					{keyList?.result?.count === 0 ? (
						<div className={styles.noKey}>
							<div>{t("api.noKey")}</div>
							<button
								className="btnDark"
								onClick={() => setOpenModal(true)}
							>
								{t("api.create")}
							</button>
						</div>
					) : (
						<div className={styles.list}>
							<div>{t("api.list")}</div>
							<KeyList />
						</div>
					)}
				</div>
			)}
			<CreateModal
				openModal={openModal}
				setOpenModal={(value) => setOpenModal(value)}
			/>
		</>
	);
};

export default APIManagement;
