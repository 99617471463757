import React from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import Avatar from "../../assets/images/ava.png";
import { useGetUserInfoQuery } from "../../services/auth";
import { useState } from "react";
import KycModal from "./KycModal";
import { useNavigate } from "react-router";
import AuthenticatorModal from "./AuthenticatorModal";
const Settings = () => {
	const [t] = useTranslation("auth");
	const navigate = useNavigate();
	const { data: user } = useGetUserInfoQuery();
	const [isOpenKYCModal, setIsOpenKYCModal] = useState(false);
	const [isOpen2FAModal, setIsOpen2FAModal] = useState(false);
	return (
		<>
			<div className="contentContainer">
				<div className={styles.content}>
					<p className={styles.contentTitle}>{t("security")}</p>
					<div className={styles.contentDetail}>
						<div className={styles.changePassword}>
							<div className="d-flex align-items-center">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<mask
										id="mask0_48_484"
										maskUnits="userSpaceOnUse"
										x="0"
										y="0"
										width="24"
										height="24"
									>
										<rect
											width="24"
											height="24"
											fill="#D9D9D9"
										/>
									</mask>
									<g mask="url(#mask0_48_484)">
										<path
											d="M6 22C5.45 22 4.97933 21.8043 4.588 21.413C4.196 21.021 4 20.55 4 20V10C4 9.45 4.196 8.979 4.588 8.587C4.97933 8.19567 5.45 8 6 8H7V6C7 4.61667 7.48767 3.43733 8.463 2.462C9.43767 1.48733 10.6167 1 12 1C13.3833 1 14.5627 1.48733 15.538 2.462C16.5127 3.43733 17 4.61667 17 6V8H18C18.55 8 19.021 8.19567 19.413 8.587C19.8043 8.979 20 9.45 20 10V20C20 20.55 19.8043 21.021 19.413 21.413C19.021 21.8043 18.55 22 18 22H6ZM6 20H18V10H6V20ZM12 17C12.55 17 13.021 16.8043 13.413 16.413C13.8043 16.021 14 15.55 14 15C14 14.45 13.8043 13.979 13.413 13.587C13.021 13.1957 12.55 13 12 13C11.45 13 10.9793 13.1957 10.588 13.587C10.196 13.979 10 14.45 10 15C10 15.55 10.196 16.021 10.588 16.413C10.9793 16.8043 11.45 17 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
											fill="#424243"
										/>
									</g>
								</svg>
								<span className={styles.changePasswordTitle}>
									{t("changePassword")}
								</span>
							</div>
							<div className={styles.customBtnContainer}>
								<button
									className="btnLight"
									onClick={() => navigate("/password-reset")}
								>
									{t("change")}
								</button>
							</div>
						</div>

						<div className="d-flex align-items-center">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clipPath="url(#clip0_48_614)">
									<path
										d="M22.5 6.375H17.25C16.9516 6.375 16.6655 6.49353 16.4545 6.7045C16.2435 6.91548 16.125 7.20163 16.125 7.5V17.25C16.125 17.5484 16.2435 17.8345 16.4545 18.0455C16.6655 18.2565 16.9516 18.375 17.25 18.375H22.5C22.7984 18.375 23.0845 18.2565 23.2955 18.0455C23.5065 17.8345 23.625 17.5484 23.625 17.25V7.5C23.625 7.20163 23.5065 6.91548 23.2955 6.7045C23.0845 6.49353 22.7984 6.375 22.5 6.375ZM22.875 17.25C22.875 17.3495 22.8355 17.4448 22.7652 17.5152C22.6948 17.5855 22.5995 17.625 22.5 17.625H17.25C17.1505 17.625 17.0552 17.5855 16.9848 17.5152C16.9145 17.4448 16.875 17.3495 16.875 17.25V16.875H22.875V17.25ZM22.875 16.125H16.875V8.625H22.875V16.125ZM22.875 7.875H16.875V7.5C16.875 7.40054 16.9145 7.30516 16.9848 7.23483C17.0552 7.16451 17.1505 7.125 17.25 7.125H22.5C22.5995 7.125 22.6948 7.16451 22.7652 7.23483C22.8355 7.30516 22.875 7.40054 22.875 7.5V7.875Z"
										fill="#1B191D"
									/>
									<path
										d="M11.625 14.9276V7.875C11.625 7.77554 11.5855 7.68016 11.5152 7.60983C11.4449 7.53951 11.3495 7.5 11.25 7.5H1.50004C1.40059 7.5 1.3052 7.53951 1.23488 7.60983C1.16455 7.68016 1.12504 7.77554 1.12504 7.875V14.9276L0.401669 16.7355C0.378855 16.7924 0.370351 16.8541 0.376901 16.9151C0.383452 16.976 0.404858 17.0345 0.439242 17.0853C0.473627 17.136 0.519942 17.1776 0.574127 17.2063C0.628313 17.2351 0.688716 17.25 0.750044 17.25H12C12.0614 17.25 12.1218 17.2351 12.176 17.2063C12.2301 17.1776 12.2765 17.136 12.3108 17.0853C12.3452 17.0345 12.3666 16.976 12.3732 16.9151C12.3797 16.8541 12.3712 16.7924 12.3484 16.7355L11.625 14.9276ZM1.87504 8.25H10.875V14.625H1.87504V8.25ZM1.30392 16.5L1.75392 15.375H10.9962L11.4462 16.5H1.30392Z"
										fill="#1B191D"
									/>
									<path
										d="M18.375 2.625C18.3086 2.62501 18.2434 2.64264 18.1861 2.67609C18.1288 2.70953 18.0814 2.75759 18.0487 2.81536C18.016 2.87314 17.9992 2.93855 18.0001 3.00491C18.001 3.07128 18.0194 3.13623 18.0536 3.19313L19.1786 5.06813C19.212 5.12357 19.2591 5.16945 19.3154 5.20129C19.3717 5.23314 19.4353 5.24988 19.5 5.24988C19.5647 5.24988 19.6283 5.23314 19.6846 5.20129C19.7409 5.16945 19.788 5.12357 19.8214 5.06813L20.9464 3.19313C20.9806 3.13623 20.999 3.07128 20.9999 3.00491C21.0008 2.93855 20.984 2.87314 20.9513 2.81536C20.9186 2.75759 20.8712 2.70953 20.8139 2.67609C20.7566 2.64264 20.6914 2.62501 20.625 2.625H19.875V1.875C19.875 1.47718 19.717 1.09564 19.4357 0.81434C19.1544 0.533035 18.7728 0.375 18.375 0.375H6.375C5.97718 0.375 5.59564 0.533035 5.31434 0.81434C5.03304 1.09564 4.875 1.47718 4.875 1.875V6.375H5.625V1.875C5.625 1.67609 5.70402 1.48532 5.84467 1.34467C5.98532 1.20402 6.17609 1.125 6.375 1.125H18.375C18.5739 1.125 18.7647 1.20402 18.9053 1.34467C19.046 1.48532 19.125 1.67609 19.125 1.875V2.625H18.375ZM19.9624 3.375L19.5 4.146L19.0376 3.375H19.9624Z"
										fill="#1B191D"
									/>
									<path
										d="M19.1249 22.1252C19.1249 22.3241 19.0459 22.5148 18.9053 22.6555C18.7646 22.7961 18.5738 22.8752 18.3749 22.8752H6.37493C6.17602 22.8752 5.98525 22.7961 5.8446 22.6555C5.70395 22.5148 5.62493 22.3241 5.62493 22.1252V21.0002H6.37493C6.4413 21.0001 6.50648 20.9825 6.56382 20.9491C6.62115 20.9156 6.66857 20.8676 6.70125 20.8098C6.73393 20.752 6.75069 20.6866 6.74983 20.6202C6.74896 20.5539 6.73049 20.4889 6.6963 20.432L5.5713 18.557C5.53552 18.5048 5.48755 18.4621 5.43156 18.4326C5.37556 18.4031 5.31322 18.3877 5.24993 18.3877C5.18664 18.3877 5.12429 18.4031 5.0683 18.4326C5.0123 18.4621 4.96434 18.5048 4.92855 18.557L3.80355 20.432C3.76937 20.4889 3.7509 20.5539 3.75003 20.6202C3.74916 20.6866 3.76592 20.752 3.7986 20.8098C3.83128 20.8676 3.87871 20.9156 3.93604 20.9491C3.99337 20.9825 4.05856 21.0001 4.12493 21.0002H4.87493V22.1252C4.87493 22.523 5.03296 22.9045 5.31427 23.1858C5.59557 23.4671 5.9771 23.6252 6.37493 23.6252H18.3749C18.7728 23.6252 19.1543 23.4671 19.4356 23.1858C19.7169 22.9045 19.8749 22.523 19.8749 22.1252V19.5002H19.1249V22.1252ZM4.78755 20.2502L5.24993 19.4792L5.7123 20.2502H4.78755Z"
										fill="#1B191D"
									/>
									<path
										d="M7.875 10.875H7.6875V10.3125C7.6875 9.9644 7.54922 9.63056 7.30308 9.38442C7.05694 9.13828 6.7231 9 6.375 9C6.0269 9 5.69306 9.13828 5.44692 9.38442C5.20078 9.63056 5.0625 9.9644 5.0625 10.3125V10.875H4.875C4.77554 10.875 4.68016 10.9145 4.60984 10.9848C4.53951 11.0552 4.5 11.1505 4.5 11.25V13.5C4.5 13.5995 4.53951 13.6948 4.60984 13.7652C4.68016 13.8355 4.77554 13.875 4.875 13.875H7.875C7.97446 13.875 8.06984 13.8355 8.14017 13.7652C8.21049 13.6948 8.25 13.5995 8.25 13.5V11.25C8.25 11.1505 8.21049 11.0552 8.14017 10.9848C8.06984 10.9145 7.97446 10.875 7.875 10.875ZM5.8125 10.3125C5.8125 10.1633 5.87176 10.0202 5.97725 9.91475C6.08274 9.80926 6.22582 9.75 6.375 9.75C6.52418 9.75 6.66726 9.80926 6.77275 9.91475C6.87824 10.0202 6.9375 10.1633 6.9375 10.3125V10.875H5.8125V10.3125ZM7.5 13.125H5.25V11.625H7.5V13.125Z"
										fill="#1B191D"
									/>
									<path
										d="M18.75 13.875C18.7492 14.0814 18.8051 14.2841 18.9118 14.4609C19.0184 14.6377 19.1717 14.7817 19.3547 14.8771C19.5378 14.9726 19.7436 15.0159 19.9496 15.0022C20.1555 14.9885 20.3538 14.9184 20.5226 14.7996C20.6914 14.6807 20.8243 14.5177 20.9066 14.3284C20.989 14.1391 21.0176 13.9308 20.9895 13.7263C20.9613 13.5217 20.8775 13.3289 20.747 13.1689C20.6166 13.0089 20.4446 12.8878 20.25 12.819V9.75H19.5V10.125H18.75V10.875H19.5V12.819C19.2814 12.8963 19.092 13.0392 18.9578 13.2282C18.8235 13.4173 18.751 13.6432 18.75 13.875ZM19.875 13.5C19.9492 13.5 20.0217 13.522 20.0833 13.5632C20.145 13.6044 20.1931 13.663 20.2215 13.7315C20.2498 13.8 20.2573 13.8754 20.2428 13.9482C20.2283 14.0209 20.1926 14.0877 20.1402 14.1402C20.0877 14.1926 20.0209 14.2283 19.9482 14.2428C19.8754 14.2573 19.8 14.2498 19.7315 14.2215C19.663 14.1931 19.6044 14.145 19.5632 14.0833C19.522 14.0217 19.5 13.9492 19.5 13.875C19.5 13.7755 19.5395 13.6802 19.6098 13.6098C19.6802 13.5395 19.7756 13.5 19.875 13.5Z"
										fill="#1B191D"
									/>
								</g>
								<defs>
									<clipPath id="clip0_48_614">
										<rect
											width="24"
											height="24"
											fill="white"
										/>
									</clipPath>
								</defs>
							</svg>
							<span className={styles.changePasswordTitle}>
								{t("2fa")}
							</span>
						</div>

						<div className={styles.twofaDetail}>
							<div className={styles.twofaDetailItem}>
								<div className={styles.itemFixed}>
									<div className="d-flex align-items-center">
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M12.4 6.02133C14.3529 6.02133 15.6703 6.86489 16.4214 7.56978L19.3565 4.704C17.5538 3.02844 15.208 2 12.4 2C8.33249 2 4.8196 4.33422 3.10938 7.73156L6.47204 10.3431C7.3156 7.83556 9.64982 6.02133 12.4 6.02133Z"
												fill="#EA4335"
											/>
											<path
												d="M22.3839 12.6311C22.3839 11.776 22.3146 11.152 22.1643 10.5049H12.3999V14.3644H18.1315C18.0159 15.3235 17.3919 16.768 16.0052 17.7387L19.287 20.2809C21.2515 18.4667 22.3839 15.7973 22.3839 12.6311Z"
												fill="#4285F4"
											/>
											<path
												d="M6.48356 14.4568C6.264 13.8097 6.13689 13.1163 6.13689 12.3999C6.13689 11.6834 6.264 10.9901 6.472 10.343L3.10933 7.73145C2.40444 9.14122 2 10.7243 2 12.3999C2 14.0754 2.40444 15.6586 3.10933 17.0683L6.48356 14.4568Z"
												fill="#FBBC05"
											/>
											<path
												d="M12.4 22.8001C15.208 22.8001 17.5653 21.8757 19.2871 20.281L16.0053 17.7388C15.1271 18.3513 13.9484 18.7788 12.4 18.7788C9.64974 18.7788 7.31552 16.9646 6.48352 14.457L3.12085 17.0686C4.83107 20.4659 8.33241 22.8001 12.4 22.8001Z"
												fill="#34A853"
											/>
										</svg>

										<span className={styles.twofaTitle}>
											Google Authenticator
										</span>
									</div>
									<span className={styles.twofaDescription}>
										{t("ggAuthVerify")}
									</span>
								</div>
								<div>
									<div className={styles.verifyStatus}>
										{!user?.twoFAEnabled ? (
											<>
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2Z"
														fill="#DD4C56"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M16.2997 16.2997C15.9097 16.6897 15.2797 16.6897 14.8897 16.2997L11.9997 13.4097L9.10973 16.2997C8.71973 16.6897 8.08973 16.6897 7.69973 16.2997C7.30973 15.9097 7.30973 15.2797 7.69973 14.8897L10.5897 11.9997L7.69973 9.10973C7.30973 8.71973 7.30973 8.08973 7.69973 7.69973C8.08973 7.30973 8.71973 7.30973 9.10973 7.69973L11.9997 10.5897L14.8897 7.69973C15.2797 7.30973 15.9097 7.30973 16.2997 7.69973C16.6897 8.08973 16.6897 8.71973 16.2997 9.10973L13.4097 11.9997L16.2997 14.8897C16.6797 15.2697 16.6797 15.9097 16.2997 16.2997Z"
														fill="#FEFEFE"
													/>
												</svg>

												<span
													className={
														styles.unverified
													}
												>
													{t("unverified")}
												</span>
											</>
										) : (
											<>
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
														fill="#2EBD56"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M9.28973 16.2901L5.69973 12.7001C5.30973 12.3101 5.30973 11.6801 5.69973 11.2901C6.08973 10.9001 6.71973 10.9001 7.10973 11.2901L9.99973 14.1701L16.8797 7.29006C17.2697 6.90006 17.8997 6.90006 18.2897 7.29006C18.6797 7.68006 18.6797 8.31006 18.2897 8.70006L10.6997 16.2901C10.3197 16.6801 9.67973 16.6801 9.28973 16.2901Z"
														fill="#FEFEFE"
													/>
												</svg>
												<span
													className={styles.verified}
												>
													{t("verified")}
												</span>
											</>
										)}
									</div>
									<div className={styles.customBtnContainer}>
										{!user?.twoFAEnabled ? (
											<button
												className="btnDark"
												onClick={() =>
													setIsOpen2FAModal(true)
												}
											>
												{t("verify")}
											</button>
										) : null}
									</div>
								</div>
							</div>
							<div className={styles.twofaDetailItem}>
								<div className={styles.itemFixed}>
									<div className="d-flex align-items-center">
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<mask
												id="mask0_48_618"
												maskUnits="userSpaceOnUse"
												x="0"
												y="0"
												width="24"
												height="24"
											>
												<rect
													width="24"
													height="24"
													fill="#D9D9D9"
												/>
											</mask>
											<g mask="url(#mask0_48_618)">
												<path
													d="M4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.8043 4.97933 22 5.45 22 6V18C22 18.55 21.8043 19.021 21.413 19.413C21.021 19.8043 20.55 20 20 20H4ZM20 8L12.525 12.675C12.4417 12.725 12.354 12.7623 12.262 12.787C12.1707 12.8123 12.0833 12.825 12 12.825C11.9167 12.825 11.8293 12.8123 11.738 12.787C11.646 12.7623 11.5583 12.725 11.475 12.675L4 8V18H20V8ZM12 11L20 6H4L12 11ZM4 8.25V6.775V6.8V6.787V8.25Z"
													fill="#363638"
												/>
											</g>
										</svg>

										<span className={styles.twofaTitle}>
											Email
										</span>
									</div>
									<span className={styles.twofaDescription}>
										{t("emailVerify")}
									</span>
								</div>
								<div>
									<div className={styles.verifyStatus}>
										{!user?.isEmailConfirmed ? (
											<>
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2Z"
														fill="#DD4C56"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M16.2997 16.2997C15.9097 16.6897 15.2797 16.6897 14.8897 16.2997L11.9997 13.4097L9.10973 16.2997C8.71973 16.6897 8.08973 16.6897 7.69973 16.2997C7.30973 15.9097 7.30973 15.2797 7.69973 14.8897L10.5897 11.9997L7.69973 9.10973C7.30973 8.71973 7.30973 8.08973 7.69973 7.69973C8.08973 7.30973 8.71973 7.30973 9.10973 7.69973L11.9997 10.5897L14.8897 7.69973C15.2797 7.30973 15.9097 7.30973 16.2997 7.69973C16.6897 8.08973 16.6897 8.71973 16.2997 9.10973L13.4097 11.9997L16.2997 14.8897C16.6797 15.2697 16.6797 15.9097 16.2997 16.2997Z"
														fill="#FEFEFE"
													/>
												</svg>

												<span
													className={
														styles.unverified
													}
												>
													{t("unverified")}
												</span>
											</>
										) : (
											<>
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
														fill="#2EBD56"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M9.28973 16.2901L5.69973 12.7001C5.30973 12.3101 5.30973 11.6801 5.69973 11.2901C6.08973 10.9001 6.71973 10.9001 7.10973 11.2901L9.99973 14.1701L16.8797 7.29006C17.2697 6.90006 17.8997 6.90006 18.2897 7.29006C18.6797 7.68006 18.6797 8.31006 18.2897 8.70006L10.6997 16.2901C10.3197 16.6801 9.67973 16.6801 9.28973 16.2901Z"
														fill="#FEFEFE"
													/>
												</svg>
												<span
													className={styles.verified}
												>
													{t("verified")}
												</span>
											</>
										)}
									</div>
									<span className={styles.lastCol}>
										{user?.email}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="contentContainer">
				<div className={styles.content}>
					<p className={styles.contentTitle}>{t("kyc")}</p>
					<div className={styles.contentDetail}>
						<div className={styles.kycContainer}>
							<div className={styles.kycCol}>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clipPath="url(#clip0_191_1622)">
										<path
											d="M21.2499 21H2.75006C1.23303 21 0 19.767 0 18.2499V5.75006C0 4.23303 1.23303 3 2.75006 3H21.2499C22.767 3 24 4.23303 24 5.75006V18.2499C24 19.767 22.767 21 21.2499 21ZM2.75006 4.5C2.06104 4.5 1.5 5.06104 1.5 5.75006V18.2499C1.5 18.939 2.06104 19.5 2.75006 19.5H21.2499C21.939 19.5 22.5 18.939 22.5 18.2499V5.75006C22.5 5.06104 21.939 4.5 21.2499 4.5H2.75006Z"
											fill="#424243"
										/>
										<path
											d="M7.49994 12.0001C6.12189 12.0001 5 10.878 5 9.50012C5 8.12207 6.12189 7 7.49994 7C8.87799 7 9.99988 8.12207 9.99988 9.50012C9.99988 10.878 8.87799 12.0001 7.49994 12.0001ZM7.49994 8.5C6.94897 8.5 6.5 8.94897 6.5 9.50012C6.5 10.0511 6.94897 10.5001 7.49994 10.5001C8.0509 10.5001 8.49988 10.0511 8.49988 9.50012C8.49988 8.94897 8.0509 8.5 7.49994 8.5Z"
											fill="#424243"
										/>
										<path
											d="M11.25 17.0001C10.836 17.0001 10.5 16.6641 10.5 16.2501V15.7501C10.5 15.061 9.93896 14.5 9.24994 14.5H5.75006C5.06104 14.5 4.5 15.061 4.5 15.7501V16.2501C4.5 16.6641 4.164 17.0001 3.75 17.0001C3.336 17.0001 3 16.6641 3 16.2501V15.7501C3 14.233 4.23303 13 5.75006 13H9.24994C10.767 13 12 14.233 12 15.7501V16.2501C12 16.6641 11.664 17.0001 11.25 17.0001Z"
											fill="#424243"
										/>
										<path
											d="M20.2499 9H14.75C14.336 9 14 8.664 14 8.25C14 7.836 14.336 7.5 14.75 7.5H20.2499C20.6639 7.5 20.9999 7.836 20.9999 8.25C20.9999 8.664 20.6639 9 20.2499 9Z"
											fill="#424243"
										/>
										<path
											d="M20.2499 13H14.75C14.336 13 14 12.664 14 12.25C14 11.836 14.336 11.5 14.75 11.5H20.2499C20.6639 11.5 20.9999 11.836 20.9999 12.25C20.9999 12.664 20.6639 13 20.2499 13Z"
											fill="#424243"
										/>
										<path
											d="M20.2499 17H14.75C14.336 17 14 16.664 14 16.25C14 15.836 14.336 15.5 14.75 15.5H20.2499C20.6639 15.5 20.9999 15.836 20.9999 16.25C20.9999 16.664 20.6639 17 20.2499 17Z"
											fill="#424243"
										/>
									</g>
									<defs>
										<clipPath id="clip0_191_1622">
											<rect
												width="24"
												height="24"
												fill="white"
											/>
										</clipPath>
									</defs>
								</svg>

								<span className={styles.changePasswordTitle}>
									{t("id")}
								</span>
							</div>
							<div>
								<div className={styles.verifyStatus}>
									{user?.kycStatus === "APPROVED" && (
										<>
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
													fill="#2EBD56"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M9.28973 16.2901L5.69973 12.7001C5.30973 12.3101 5.30973 11.6801 5.69973 11.2901C6.08973 10.9001 6.71973 10.9001 7.10973 11.2901L9.99973 14.1701L16.8797 7.29006C17.2697 6.90006 17.8997 6.90006 18.2897 7.29006C18.6797 7.68006 18.6797 8.31006 18.2897 8.70006L10.6997 16.2901C10.3197 16.6801 9.67973 16.6801 9.28973 16.2901Z"
													fill="#FEFEFE"
												/>
											</svg>
											<span className={styles.verified}>
												{t("verified")}
											</span>
										</>
									)}
									{!user?.kycStatus && (
										<>
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2Z"
													fill="#DD4C56"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M16.2997 16.2997C15.9097 16.6897 15.2797 16.6897 14.8897 16.2997L11.9997 13.4097L9.10973 16.2997C8.71973 16.6897 8.08973 16.6897 7.69973 16.2997C7.30973 15.9097 7.30973 15.2797 7.69973 14.8897L10.5897 11.9997L7.69973 9.10973C7.30973 8.71973 7.30973 8.08973 7.69973 7.69973C8.08973 7.30973 8.71973 7.30973 9.10973 7.69973L11.9997 10.5897L14.8897 7.69973C15.2797 7.30973 15.9097 7.30973 16.2997 7.69973C16.6897 8.08973 16.6897 8.71973 16.2997 9.10973L13.4097 11.9997L16.2997 14.8897C16.6797 15.2697 16.6797 15.9097 16.2997 16.2997Z"
													fill="#FEFEFE"
												/>
											</svg>

											<span className={styles.unverified}>
												{t("unverified")}
											</span>
										</>
									)}
									{user?.kycStatus === "PENDING" && (
										<>
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2Z"
													fill="#FDFCFF"
													stroke="#2563EB"
												/>
												<circle
													cx="12"
													cy="7"
													r="1"
													fill="#2563EB"
												/>
												<rect
													x="11"
													y="10"
													width="2"
													height="8"
													rx="1"
													fill="#2563EB"
												/>
											</svg>
											<span className={styles.pending}>
												{t("pending")}
											</span>
										</>
									)}
								</div>
								<div className={styles.customBtnContainer}>
									{!user?.kycStatus ? (
										<button
											className="btnDark"
											onClick={() =>
												setIsOpenKYCModal(true)
											}
										>
											{t("verify")}
										</button>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{isOpenKYCModal ? (
				<KycModal
					isOpenKYCModal={isOpenKYCModal}
					setIsOpenKYCModal={(value) => setIsOpenKYCModal(value)}
				/>
			) : null}
			{isOpen2FAModal ? (
				<AuthenticatorModal
					isOpen={isOpen2FAModal}
					close={() => setIsOpen2FAModal(false)}
				/>
			) : null}
		</>
	);
};

export default Settings;
