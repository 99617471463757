import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { useGetUserInfoQuery } from "../../services/auth";
import { useLocation, useNavigate } from "react-router";
import DashBoardContent from "./DashBoardContent";
import Trending from "./Trending";
import MarketChange from "./MarketChange";
import MarketList from "./MarketList";
import { useDispatch } from "react-redux";
import { updatePriceChange } from "../../Store/common";
import autoAnimate from "@formkit/auto-animate";
import { TickerTape } from "react-tradingview-embed";
function Dashboard() {
	const [t] = useTranslation("common");
	const navigate = useNavigate();
	const search = useLocation().search;

	const dispatch = useDispatch();

	const { data: user } = useGetUserInfoQuery();
	const parent = useRef(null);

	useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent]);

	useEffect(() => {
		const wssPrice = new WebSocket("wss://stream.binance.com:9443/ws");
		wssPrice.onopen = (event) => {
			wssPrice.send(
				JSON.stringify({
					method: "SUBSCRIBE",
					params: ["!miniTicker@arr"],
					id: 1,
				})
			);
		};

		wssPrice.onmessage = (event) => {
			let currentPriceObj = JSON.parse(event.data);
			dispatch(updatePriceChange(currentPriceObj));
			// setDataChange(currentPriceObj);
		};
		return () => {
			wssPrice.close();
		};
	}, []);

	useEffect(() => {
		const promoCode = new URLSearchParams(search).get("promo");
		const refCode = new URLSearchParams(search).get("ref");
		if (promoCode) {
			localStorage.setItem("promo", promoCode);
		}
		if (refCode) {
			localStorage.setItem("ref", refCode);
		}
	}, [search]);

	return (
		<>
			<TickerTape
				widgetProps={{
					symbols: [
						{
							proName: "BITSTAMP:BTCUSD",
							title: "Bitcoin",
						},
						{
							proName: "BITSTAMP:ETHUSD",
							title: "Ethereum",
						},
						{
							description: "BNB",
							proName: "BINANCE:BNBUSD",
						},
						{
							description: "Polygon",
							proName: "COINBASE:MATICUSD",
						},
						{
							description: "Dogecoin",
							proName: "BINANCE:DOGEUSD",
						},
						{
							description: "The Sandbox",
							proName: "BINANCE:SANDUSD",
						},
					],
					showSymbolLogo: true,
					colorTheme: "light",
					isTransparent: false,
					displayMode: "adaptive",
					locale: "en",
				}}
			/>

			<div className={styles.dashboardContainer} ref={parent}>
				{user ? <DashBoardContent /> : null}
				<Trending />
				<MarketChange />
				<MarketList />
			</div>
		</>
	);
}
export default Dashboard;
