import React, { useEffect, useState } from "react";
import { Tab, Nav, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import {
	useGetSpotOrdersQuery,
	useGetSpotAssetsQuery,
} from "../../services/spot";
import { useGetUserInfoQuery, useGetWSTokenQuery } from "../../services/auth";
import mqtt from "mqtt/dist/mqtt";
import { toastCfg } from "../../config";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { numberFormat } from "../../utils/formatNumber";

import styles from "./styles.module.scss";
import StatusText from "../Common/StatusText";
import { useWindowSize } from "@react-hook/window-size";

function SpotTradeStatus(props) {
	const [t] = useTranslation("trade");

	const { refetch: refetchAssetsData } = useGetSpotAssetsQuery();

	const [activeTab, setActiveTab] = useState("NEW");
	const [page, setPage] = useState(1);

	const auth = useSelector((state) => state.auth);

	const { data: user } = useGetUserInfoQuery();

	const { data: wsToken } = useGetWSTokenQuery();

	const [width] = useWindowSize();

	const {
		data: orders,
		isLoading,
		refetch: refetchOrdersData,
	} = useGetSpotOrdersQuery({
		skip: (page - 1) * 10,
		status: activeTab,
	});

	const handlePageChange = (newPage) => {
		setPage(newPage);
	};

	const defaultCol = [
		{
			name: t("time"),
			selector: (row) => row.createdAt,
		},
		{
			name: t("tradePair"),
			selector: (row) => row.baseAsset + row.quoteAsset,
			width: "150px",
		},
		{
			name: t("type"),
			selector: (row) => (row.side === "SELL" ? t("sell") : t("buy")),
			width: "150px",

			cell: (row) => (
				<>
					{row.side === "SELL" ? (
						<div className={styles.sellBadge}>
							<span>{t("sell")}</span>
						</div>
					) : (
						<div className={styles.buyBadge}>
							<span>{t("buy")}</span>
						</div>
					)}
				</>
			),
		},
		{
			name: t("order"),
			selector: (row) => row.type,
			cell: (row) => <span>{row.type}</span>,
		},
		{
			name: t("price"),
			selector: (row) =>
				numberFormat(
					activeTab === "FILLED"
						? parseFloat(row.filledQuoteQuantity) /
								parseFloat(row.filledQuantity)
						: row.price
				),
		},
		{
			name: t("quantity"),
			selector: (row) =>
				numberFormat(
					activeTab === "FILLED" ? row.filledQuantity : row.quantity
				),
		},

		{
			name: t("fee"),
			selector: (row) => (
				<div>{`${numberFormat(
					parseFloat(row?.fee?.platformAmount) +
						parseFloat(row?.fee?.systemAmount) -
						parseFloat(row?.fee?.totalDiscountAmount)
				)} ${!!row?.fee?.token ? row.fee.token : ""}`}</div>
			),
		},

		{
			name: t("status"),
			selector: (row) => row.status,
			cell: (row) => (
				<>
					<StatusText status={row.status} />
				</>
			),
		},
	];

	const newOrderCol = [
		...defaultCol,
		{
			name: t("action"),
			cell: (row) => (
				<div
					className={styles.actionBtn}
					onClick={() => cancelOrder(row.id)}
				>
					<span>{t("cancel")}</span>
				</div>
			),
		},
	];

	const paginationOption = {
		noRowsPerPage: true,
		rangeSeparatorText: "/",
	};

	const cancelOrder = async (id) => {
		if (!id) {
			toast.warn("Order ID không được để trống", toastCfg);
			return;
		}
		if (
			auth.access_token ||
			localStorage.getItem("access_token") ||
			sessionStorage.getItem("access_token")
		) {
			const url = process.env.REACT_APP_FRYPTO_API + "/spot/orders/" + id;

			const requestOptions = {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.access_token}`,
				},
			};
			try {
				const response = await fetch(url, requestOptions);
				const result = await response.json();
				if (result.statusCode == "SUCCESS") {
					toast.success(t("cancelSuccess"), toastCfg);
				} else {
					toast.error(result.message, toastCfg);
				}
			} catch (error) {
				console.log("error", error);
				toast.error(t("error"), toastCfg);
			}

			refetchOrdersData();
			refetchAssetsData();
		} else {
			toast.warn(t("plsLogin"), toastCfg);
			return;
		}
	};

	useEffect(() => {
		if (!!user?.id && !!wsToken?.result) {
			const clientId =
				"mqttjs_" + Math.random().toString(16).substr(2, 8);
			const options = {
				keepalive: 60,
				clientId: clientId,
				protocolId: "MQTT",
				protocolVersion: 4,
				clean: true,
				reconnectPeriod: 1000,
				connectTimeout: 30 * 1000,
				will: {
					topic: "WillMsg",
					payload: "Connection Closed abnormally..!",
					qos: 0,
					retain: false,
				},
				username: user?.id.toString(),
				password: wsToken?.result,
			};

			const client = mqtt.connect(
				process.env.REACT_APP_MQTT_URL,
				options
			);

			client.subscribe(`orderChange/${user?.id}`);
			client.on("message", (topic, message) => {
				refetchOrdersData();
				refetchAssetsData();
				const data = JSON.parse(message.toString());
				if (data?.status === "FILLED") {
					toast.success(t("orderFilled"), toastCfg);
				}
				console.log(data);
			});
		}
	}, [user, wsToken]);

	useEffect(() => {
		refetchOrdersData();
	}, [page]);

	useEffect(() => {
		if (page === 1) {
			refetchOrdersData();
		} else {
			setPage(1);
		}
	}, [activeTab]);

	return (
		<div className={styles.history}>
			<h6>{t("history")}</h6>
			<div>
				<div>
					<Tabs
						activeKey={activeTab}
						onSelect={(key) => setActiveTab(key)}
						className={styles.historyTabs}
					>
						<Tab
							eventKey={"NEW"}
							title={
								<div className={styles.tabTitle}>
									<span>{t("open")}</span>
								</div>
							}
						></Tab>
						<Tab
							eventKey={"FILLED"}
							title={
								<div className={styles.tabTitle}>
									<span>{t("filled")}</span>
								</div>
							}
						></Tab>
						<Tab
							eventKey={"CANCELED"}
							title={
								<div className={styles.tabTitle}>
									<span>{t("cancelled")}</span>
								</div>
							}
						></Tab>
					</Tabs>
				</div>
				<>
					{width > 1200 ? (
						<div className={styles.historyTable}>
							<DataTable
								columns={
									activeTab === "NEW"
										? newOrderCol
										: defaultCol
								}
								data={orders?.data}
								selectableRows={false}
								highlightOnHover={true}
								pagination={true}
								paginationServer
								paginationComponentOptions={paginationOption}
								onChangePage={handlePageChange}
								paginationTotalRows={
									orders?.count || orders?.length
								}
								className="historyTable"
							/>
						</div>
					) : (
						<div>
							{orders?.data?.map((order) => (
								<HistoryItem
									key={order.id}
									data={order}
									activeTab={activeTab}
								/>
							))}
						</div>
					)}
				</>
			</div>
		</div>
	);
}

const HistoryItem = ({ data, activeTab }) => {
	const [t] = useTranslation("trade");
	return (
		<div className={styles.mobileHistoryItem}>
			<div>
				<div>{`${data?.baseAsset}/${data?.quoteAsset}`}</div>
				<div
					className={data?.side === "BUY" ? "priceUp" : "priceDown"}
				>{`${data?.side === "BUY" ? t("buy") : t("sell")}/${
					data?.type
				}`}</div>
			</div>
			<div>
				<div>
					<div>{t("time")}</div>
					<div>{data?.createdAt}</div>
				</div>
				<div>
					<div>{t("price")}</div>
					<div>
						{numberFormat(
							activeTab === "FILLED"
								? parseFloat(data?.filledQuoteQuantity) /
										parseFloat(data?.filledQuantity)
								: data?.price
						)}
					</div>
				</div>
				<div>
					<div>{t("quantity")}</div>
					<div>
						{numberFormat(
							activeTab === "FILLED"
								? data?.filledQuantity
								: data?.quantity
						)}
					</div>
				</div>

				<div>
					<div>{t("fee")}</div>
					<div>{`${numberFormat(
						parseFloat(data?.fee?.platformAmount) +
							parseFloat(data?.fee?.systemAmount) -
							parseFloat(data?.fee?.totalDiscountAmount)
					)} ${!!data?.fee?.token ? data.fee.token : ""}`}</div>
				</div>
				<div>
					<div>{t("status")}</div>
					<div>
						<StatusText status={data?.status} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SpotTradeStatus;
