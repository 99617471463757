import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "@react-hook/window-size";
import MobileModal from "../Common/MobileModal";
import tos from "../../assets/tos";

const TOS = ({ isOpen, close }) => {
	const [t] = useTranslation("auth");
	const [width] = useWindowSize();
	return (
		<>
			{width > 1200 ? (
				<Modal show={isOpen} onHide={close} className={styles.tosModal}>
					<div>{t("tos")}</div>
					<div className={styles.tosContent}>
						<div>{tos}</div>
					</div>
					<button className="btnDark" onClick={close}>
						{t("undestand")}
					</button>
				</Modal>
			) : (
				<MobileModal open={isOpen} close={close} title={t("tos")}>
					<div className={styles.tosContent}>
						<div>{tos}</div>
					</div>
					<div className={styles.mobileCloseTOS}>
						<button className="btnDark" onClick={close}>
							{t("undestand")}
						</button>
					</div>
				</MobileModal>
			)}
		</>
	);
};

export default TOS;
