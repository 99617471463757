import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./config";

export const spotService = createApi({
	reducerPath: "spotService",
	baseQuery: baseQuery,
	endpoints: (builder) => ({
		getSpotAssets: builder.query({
			query: () => `spot/assets`,
		}),
		getSpotOrders: builder.query({
			query: (data) => {
				const take = data?.take || 10;
				const skip = data?.skip || 0;
				const status = data?.status;
				const searchParams = new URLSearchParams({
					take,
					skip,
					status,
				}).toString();
				return `/spot/orders?` + searchParams;
			},
		}),
		createBuyLimitOrder: builder.mutation({
			query: ({ symbol, price, quantity }) => ({
				url: `/spot/orders`,
				method: "POST",
				body: {
					symbol,
					price,
					quantity,
					side: "BUY",
					timeInForce: "GTC",
					type: "LIMIT",
				},
			}),
		}),
		createSellLimitOrder: builder.mutation({
			query: ({ symbol, price, quantity }) => ({
				url: `/spot/orders`,
				method: "POST",
				body: {
					symbol,
					price,
					quantity,
					side: "SELL",
					timeInForce: "GTC",
					type: "LIMIT",
				},
			}),
		}),

		createBuyMarketOrder: builder.mutation({
			query: ({ symbol, quantity }) => ({
				url: `/spot/orders`,
				method: "POST",
				body: {
					symbol,
					quoteOrderQty: quantity,
					side: "BUY",
					type: "MARKET",
				},
			}),
		}),

		createSellMarketOrder: builder.mutation({
			query: ({ symbol, quantity }) => ({
				url: `/spot/orders`,
				method: "POST",
				body: {
					symbol,
					quantity,
					side: "SELL",
					type: "MARKET",
				},
			}),
		}),
	}),
});

export const {
	useGetSpotAssetsQuery,
	useGetSpotOrdersQuery,
	useCreateBuyLimitOrderMutation,
	useCreateSellLimitOrderMutation,
	useCreateBuyMarketOrderMutation,
	useCreateSellMarketOrderMutation,
} = spotService;
