import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { numberFormat } from "../../utils/formatNumber";
import { useNavigate } from "react-router";
import { useGetPNLDetailQuery } from "../../services/wallet";
import { useGetSpotAssetsQuery } from "../../services/spot";
import { getPastDate } from "../../utils/date";
import { formatDateNotime } from "../../utils/date";
import Overlay from "react-bootstrap/Overlay";
import { DayPicker } from "react-day-picker";
import DetailAssetChart from "./DetailAssetChart";
import AssetProfitChart from "./AssetProfitChart";
import { useWindowSize } from "@react-hook/window-size";
import MobileModal from "../Common/MobileModal";

const AssetsPNLDetail = ({ openModal, handleClose, token }) => {
	const [t] = useTranslation("wallet");
	const navigate = useNavigate();
	const [range, setRange] = useState(0);
	const [start, setStart] = useState(getPastDate(30, true));
	const [end, setEnd] = useState(getPastDate(1, true));
	const [openCustomRange, setOpenCustomRange] = useState(false);
	const [selectedDate, setSelectedDate] = useState();
	const [overviewData, setOverviewData] = useState({});
	const { data: assets } = useGetSpotAssetsQuery();
	const [width] = useWindowSize();

	const target = useRef(null);
	const { data: pnl, refetch } = useGetPNLDetailQuery({
		start,
		end,
		token: token, 
	});

	const handleClickCustom = () => {
		setRange(2);
		setOpenCustomRange(!openCustomRange);
	};

	useEffect(() => {
		if (range === 0) {
			//week
			setStart(getPastDate(7, true));
			setEnd(getPastDate(0, true));
			setOpenCustomRange(false);
			setSelectedDate();
		}
		if (range === 1) {
			//month
			setStart(getPastDate(30, true));
			setEnd(getPastDate(0, true));
			setOpenCustomRange(false);
			setSelectedDate();
		}
	}, [range]);

	useEffect(() => {
		if (selectedDate?.from && selectedDate?.to && range === 2) {
			setStart(formatDateNotime(selectedDate?.from));
			setEnd(formatDateNotime(selectedDate?.to));
			const timer = setTimeout(() => {
				setOpenCustomRange(false);
			}, 0);
			return () => clearTimeout(timer);
		}
	}, [selectedDate, range]);

	useEffect(() => {
		if (pnl?.result && assets) {
			let currentValue =
				assets.filter((item) => item.tokenSymbol === token)[0].inUSD ||
				0;

			let balance = parseFloat(
				pnl?.result?.at(-1)[0]?.valueInBTC || 0
			).toFixed(6);
			let value = parseFloat(
				pnl?.result?.at(-1)[0]?.valueInUSD || 0
			).toFixed(2);
			let pnl1d = (
				parseFloat(currentValue) -
				parseFloat(pnl?.result?.at(-1)[0]?.valueInUSD || 0)
			).toFixed(2);
			let pnl1dp = (
				(pnl1d / parseFloat(pnl?.result?.at(-1)[0]?.valueInUSD || 0)) *
				100
			).toFixed(2);

			let pnl30d = (
				parseFloat(pnl?.result?.at(-1)[0]?.valueInUSD || 0) -
				parseFloat(pnl?.result?.at(0)[0]?.valueInUSD || 0)
			).toFixed(2);

			let pnl30dp = (
				(pnl30d / parseFloat(pnl?.result?.at(0)[0]?.valueInUSD || 0)) *
				100
			).toFixed(2);
			setOverviewData({ balance, value, pnl1d, pnl1dp, pnl30d, pnl30dp });
		}
	}, [pnl, assets, token]);

	return (
		<>
			<>
				{width > 1200 ? (
					<Modal show={openModal} close={handleClose}>
						<div className={styles.pnlModal}>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								onClick={handleClose}
							>
								<mask
									id="mask0_713_7783"
									maskUnits="userSpaceOnUse"
									x="0"
									y="0"
									width="24"
									height="24"
								>
									<rect
										width="24"
										height="24"
										fill="#D9D9D9"
									/>
								</mask>
								<g mask="url(#mask0_713_7783)">
									<path
										d="M11.9998 13.3998L7.0998 18.2998C6.91647 18.4831 6.68314 18.5748 6.3998 18.5748C6.11647 18.5748 5.88314 18.4831 5.6998 18.2998C5.51647 18.1165 5.4248 17.8831 5.4248 17.5998C5.4248 17.3165 5.51647 17.0831 5.6998 16.8998L10.5998 11.9998L5.6998 7.0998C5.51647 6.91647 5.4248 6.68314 5.4248 6.3998C5.4248 6.11647 5.51647 5.88314 5.6998 5.6998C5.88314 5.51647 6.11647 5.4248 6.3998 5.4248C6.68314 5.4248 6.91647 5.51647 7.0998 5.6998L11.9998 10.5998L16.8998 5.6998C17.0831 5.51647 17.3165 5.4248 17.5998 5.4248C17.8831 5.4248 18.1165 5.51647 18.2998 5.6998C18.4831 5.88314 18.5748 6.11647 18.5748 6.3998C18.5748 6.68314 18.4831 6.91647 18.2998 7.0998L13.3998 11.9998L18.2998 16.8998C18.4831 17.0831 18.5748 17.3165 18.5748 17.5998C18.5748 17.8831 18.4831 18.1165 18.2998 18.2998C18.1165 18.4831 17.8831 18.5748 17.5998 18.5748C17.3165 18.5748 17.0831 18.4831 16.8998 18.2998L11.9998 13.3998Z"
										fill="#939393"
									/>
								</g>
							</svg>
						</div>
						<div className={styles.modalContent}>
							<div>{`${token} PNL`}</div>
							<div className={styles.pnlDetail}>
								<div>
									<div>
										<div>{t("pnl.balance")}</div>
										<div>
											{numberFormat(
												overviewData?.balance
											) + " BTC"}
										</div>
									</div>
									<div>
										<div>{t("pnl.estimatedValue")}</div>
										<div>
											{"$" +
												numberFormat(
													overviewData?.value
												)}
										</div>
									</div>
									<div>
										<div>{t("pnl.1dpnl")}</div>
										<div
											className={
												overviewData?.pnl1d < 0
													? "priceDown"
													: "priceUp"
											}
										>
											<span>{`${
												overviewData?.pnl1d < 0
													? "-"
													: "+"
											}$${numberFormat(
												Math.abs(overviewData?.pnl1d)
											)}`}</span>
											<span>{`(${
												overviewData?.pnl1d < 0
													? "-"
													: "+"
											}${numberFormat(
												Math.abs(overviewData?.pnl1dp)
											)}%)`}</span>
										</div>
									</div>
									<div>
										<div>{t("pnl.30dpnl")}</div>
										<div
											className={
												overviewData?.pnl30d < 0
													? "priceDown"
													: "priceUp"
											}
										>
											<span>{`${
												overviewData?.pnl30d < 0
													? "-"
													: "+"
											}$${numberFormat(
												Math.abs(overviewData?.pnl30d)
											)}`}</span>
											<span>{`(${
												overviewData?.pnl30d < 0
													? "-"
													: "+"
											}${numberFormat(
												Math.abs(overviewData?.pnl30dp)
											)}%)`}</span>
										</div>
									</div>
								</div>
								<button
									className="btnDark"
									onClick={() =>
										navigate(
											`/spot-trade?token=${token}-BUSD`
										)
									}
								>
									{t("pnl.trade")}
								</button>
							</div>
							<div className={styles.rangeSelect}>
								<div
									onClick={() => setRange(0)}
									className={
										range === 0
											? styles.selected
											: undefined
									}
								>
									{t("pnl.7d")}
								</div>
								<div
									onClick={() => setRange(1)}
									className={
										range === 1
											? styles.selected
											: undefined
									}
								>
									{t("pnl.30d")}
								</div>
								<div
									onClick={handleClickCustom}
									ref={target}
									className={
										range === 2
											? styles.selected
											: undefined
									}
								>
									{selectedDate?.from &&
									selectedDate?.to &&
									range === 2
										? `${formatDateNotime(
												selectedDate?.from
										  )} - ${formatDateNotime(
												selectedDate?.to
										  )}`
										: t("pnl.custom")}
								</div>
							</div>
							<div className={styles.detailCharts}>
								<div>
									<div>{t("pnl.totalAssets")}</div>
									<div>
										<DetailAssetChart pnl={pnl} />
									</div>
								</div>
								<div>
									<div>{t("pnl.profit")}</div>
									<div>
										<AssetProfitChart pnl={pnl} />
									</div>
								</div>
							</div>
						</div>
					</Modal>
				) : (
					<MobileModal
						title={`${token} PNL`}
						open={openModal}
						close={handleClose}
					>
						<div className={styles.pnlDetail}>
							<div>
								<div>
									<div>{t("pnl.balance")}</div>
									<div>
										{numberFormat(overviewData?.balance) +
											" BTC"}
									</div>
								</div>
								<div>
									<div>{t("pnl.estimatedValue")}</div>
									<div>
										{"$" +
											numberFormat(overviewData?.value)}
									</div>
								</div>
								<div>
									<div>{t("pnl.1dpnl")}</div>
									<div
										className={
											overviewData?.pnl1d < 0
												? "priceDown"
												: "priceUp"
										}
									>
										<span>{`${
											overviewData?.pnl1d < 0 ? "-" : "+"
										}$${numberFormat(
											Math.abs(overviewData?.pnl1d)
										)}`}</span>
										<span>{`(${
											overviewData?.pnl1d < 0 ? "-" : "+"
										}${numberFormat(
											Math.abs(overviewData?.pnl1dp)
										)}%)`}</span>
									</div>
								</div>
								<div>
									<div>{t("pnl.30dpnl")}</div>
									<div
										className={
											overviewData?.pnl30d < 0
												? "priceDown"
												: "priceUp"
										}
									>
										<span>{`${
											overviewData?.pnl30d < 0 ? "-" : "+"
										}$${numberFormat(
											Math.abs(overviewData?.pnl30d)
										)}`}</span>
										<span>{`(${
											overviewData?.pnl30d < 0 ? "-" : "+"
										}${numberFormat(
											Math.abs(overviewData?.pnl30dp)
										)}%)`}</span>
									</div>
								</div>
							</div>
						</div>
						<div className={styles.rangeSelect}>
							<div
								onClick={() => setRange(0)}
								className={
									range === 0 ? styles.selected : undefined
								}
							>
								{t("pnl.7d")}
							</div>
							<div
								onClick={() => setRange(1)}
								className={
									range === 1 ? styles.selected : undefined
								}
							>
								{t("pnl.30d")}
							</div>
							<div
								onClick={handleClickCustom}
								ref={target}
								className={
									range === 2 ? styles.selected : undefined
								}
							>
								{selectedDate?.from &&
								selectedDate?.to &&
								range === 2
									? `${formatDateNotime(
											selectedDate?.from
									  )} - ${formatDateNotime(
											selectedDate?.to
									  )}`
									: t("pnl.custom")}
							</div>
						</div>
						<div className={styles.detailCharts}>
							<div>
								<div>{t("pnl.totalAssets")}</div>
								<div>
									<DetailAssetChart pnl={pnl} />
								</div>
							</div>
							<div>
								<div>{t("pnl.profit")}</div>
								<div>
									<AssetProfitChart pnl={pnl} />
								</div>
							</div>
						</div>
						<div className={styles.mobileTradeBtn}>
							<button
								className="btnDark"
								onClick={() =>
									navigate(`/spot-trade?token=${token}-BUSD`)
								}
							>
								{t("pnl.trade")}
							</button>
						</div>
					</MobileModal>
				)}
			</>
			<Overlay
				target={target.current}
				show={openCustomRange}
				placement="bottom"
				popperConfig={{ style: { zIndex: 99999 } }}
			>
				{({
					placement: _placement,
					arrowProps: _arrowProps,
					show: _show,
					popper: _popper,
					hasDoneInitialMeasure: _hasDoneInitialMeasure,
					...props
				}) => (
					<div
						{...props}
						className="contentContainerMediumFixed"
						style={{
							position: "absolute",
							zIndex: 99999999,
							...props.style,
						}}
					>
						<DayPicker
							mode="range"
							selected={selectedDate}
							onSelect={setSelectedDate}
							showOutsideDays
							fixedWeeks
						/>
					</div>
				)}
			</Overlay>
		</>
	);
};

export default AssetsPNLDetail;
