import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useLocation } from "react-router";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Chart from "react-apexcharts";
import { useGetKLinesQuery } from "../../services/market";
import { formatDate } from "../../utils/date";
import { useWindowSize } from "@react-hook/window-size";

const LiteChart = () => {
	const search = useLocation().search;

	const [width] = useWindowSize();

	const symbol = new URLSearchParams(search).get("token").split("-").join("");
	const [activeTab, setActiveTab] = useState(1);
	const [queryOption, setQueryOption] = useState({ symbol });

	const { data: seriesData, refetch: refetchKlines } = useGetKLinesQuery({
		...queryOption,
	});

	const [isUp, setIsUp] = useState(false);

	useEffect(() => {
		let option;

		switch (Number(activeTab)) {
			//24h 1w 1m 1y
			case 1:
				option = {
					interval: "1h",
					limit: 24,
				};
				break;

			case 2:
				option = {
					interval: "6h",
					limit: 48,
				};
				break;

			case 3:
				option = {
					interval: "1d",
					limit: 30,
				};
				break;

			case 4:
				option = { interval: "1w", limit: 52 };
				break;

			default: {
				option = {
					interval: "1h",
					limit: 24,
				};
			}
		}

		setQueryOption({ symbol, ...option });
	}, [activeTab]);

	useEffect(() => {
		const data = seriesData ? seriesData[symbol] : [0];
		if (parseFloat(data[data?.length - 1]) - parseFloat(data[0]) >= 0) {
			setIsUp(true);
		} else {
			setIsUp(false);
		}
	}, [seriesData]);

	return (
		<div className="contentContainerMedium">
			<div className={styles.liteChart}>
				<div className={styles.tabs}>
					<Tabs
						activeKey={activeTab}
						onSelect={(key) => setActiveTab(key)}
						className={styles.customTab}
					>
						<Tab eventKey={1} title="24H"></Tab>
						<Tab eventKey={2} title="1W"></Tab>
						<Tab eventKey={3} title="1M"></Tab>
						<Tab eventKey={4} title="1Y"></Tab>
					</Tabs>
				</div>
				<div>
					<Chart
						type="area"
						height={width > 1200 ? 380 : 430}
						width={width - 16 * 2}
						series={[
							{
								data: seriesData ? seriesData[symbol] : [0],
							},
						]}
						options={{
							chart: {
								toolbar: {
									show: false,
								},
								sparkline: {
									enabled: true,
								},
								animations: {
									enabled: false,
								},
							},
							dataLabels: {
								enabled: false,
							},
							stroke: {
								curve: "smooth",
								width: 1,
							},

							xaxis: {
								labels: {
									show: true,
								},
								categories: seriesData
									? seriesData.timestamp[symbol].map((time) =>
											formatDate(time)
									  )
									: [""],
							},
							yaxis: {
								floating: true,
								axisTicks: {
									show: false,
								},
								axisBorder: {
									show: false,
								},
								labels: {
									show: false,
								},
							},
							tooltip: {
								x: {
									show: true,
								},
								y: {
									title: {
										formatter: () => symbol,
									},
								},
							},
							fill: {
								type: "solid",
								opacity: 1,
								colors: isUp ? ["#D5F2DD"] : ["#FBE5E7"],
							},
							colors: isUp ? ["#2EBD56"] : ["#DD4C56"],
						}}
					/>
					{/* <div
                        className={
                            seriesData && seriesData[symbol]?.length > 0
                                ? isUp
                                    ? styles.chartFooterUp
                                    : styles.chartFooterDown
                                : ""
                        }
                    ></div> */}
				</div>
			</div>
		</div>
	);
};

export default LiteChart;
