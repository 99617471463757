import React from "react";
import styles from "./styles.module.scss";

import Point from "../../assets/images/point.svg";

const RewardDetail = ({ reward }) => {
	switch (reward?.type) {
		case "POINT":
			return (
				<div className={styles.rewardPoint}>
					<img alt="" src={Point} width={24} />

					<div>{`${parseFloat(reward.amount)} Points`}</div>
				</div>
			);
		case "TOKEN":
			return (
				<div className={styles.rewardToken}>
					<img
						alt=""
						src={require(`../../assets/images/coin/${reward?.symbol}.png`)}
						width={48}
					/>
					<div>{`${parseFloat(reward.amount)} ${reward.symbol}`}</div>
				</div>
			);
		case "EXTRA_SPIN":
			return (
				<div className={styles.rewardChance}>
					<div>+1</div>
					<div>
						<div>Spinning</div>
						<div>chance</div>
					</div>
				</div>
			);

		case "AVATAR":
			return (
				<div className={styles.rewardAvatar}>
					<img
						alt=""
						src={require(`../../assets/images/avatar-${reward?.symbol}.png`)}
						width={48}
					/>
					<div>
						<div>{reward?.symbol}</div>
						<div>Avatar</div>
					</div>
				</div>
			);
		default:
			return;
	}
};

export default RewardDetail;
