// ** Toolkit imports
import { configureStore } from "@reduxjs/toolkit";

// ** Reducers
import auth from "./auth";
import spot from "./spot";
import common from "./common";

import { marketService } from "../services/market";
import { spotService } from "../services/spot";
import { authService } from "../services/auth";
import { walletService } from "../services/wallet";
import { luckyWheelService } from "../services/luckyWheel";

export const store = configureStore({
	reducer: {
		auth,
		spot,
		common,
		[marketService.reducerPath]: marketService.reducer,
		[spotService.reducerPath]: spotService.reducer,
		[authService.reducerPath]: authService.reducer,
		[walletService.reducerPath]: walletService.reducer,
		[luckyWheelService.reducerPath]: luckyWheelService.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(
			marketService.middleware,
			spotService.middleware,
			authService.middleware,
			walletService.middleware,
			luckyWheelService.middleware
		),
});
