import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { useGetUserInfoQuery } from "../../services/auth";
import { useNavigate } from "react-router";
import TooltipComponent from "../../Component/Common/TooltipComponent";
import { numberFormat } from "../../utils/formatNumber";
import { useGetPNLDetailQuery } from "../../services/wallet";
import { getPastDate } from "../../utils/date";

const DashBoardContent = () => {
	const [t] = useTranslation("common");
	const navigate = useNavigate();

	const { data: user } = useGetUserInfoQuery();
	const { data: pnl } = useGetPNLDetailQuery({
		start: getPastDate(1, true),
		end: getPastDate(0, true),
	});

	const [totalBalanceDiff, setTotalBalanceDiff] = useState(0);

	useEffect(() => {
		if (pnl?.result?.length > 0) {
			let total = 0;
			for (let i = 0; i < pnl.result[0].length; i++) {
				total += parseFloat(pnl.result[0][i].valueInUSD);
			}
			setTotalBalanceDiff((user?.spotBalance - total).toFixed(2));
		}
	}, [pnl, user]);

	return (
		<div>
			<p className={styles.heading}>{t("welcome")}</p>
			<div className="contentContainer">
				<div className={styles.dashboardContent}>
					<div className={styles.asset}>
						<div>{t("yourAssets")}</div>
						<div>
							${numberFormat((user?.spotBalance).toFixed(2))}
						</div>
					</div>
					<div className={styles.dashboardButtonContainer}>
						<div>
							<div>
								<button
									className="btnRound"
									onClick={() =>
										(window.location = "/deposit-page")
									}
								>
									<svg
										width="22"
										height="22"
										viewBox="0 0 22 22"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M11 21.5C10.575 21.5 10.219 21.356 9.932 21.068C9.644 20.781 9.5 20.425 9.5 20V12.5H2C1.575 12.5 1.2185 12.356 0.9305 12.068C0.6435 11.781 0.5 11.425 0.5 11C0.5 10.575 0.6435 10.2185 0.9305 9.9305C1.2185 9.6435 1.575 9.5 2 9.5H9.5V2C9.5 1.575 9.644 1.2185 9.932 0.9305C10.219 0.6435 10.575 0.5 11 0.5C11.425 0.5 11.7815 0.6435 12.0695 0.9305C12.3565 1.2185 12.5 1.575 12.5 2V9.5H20C20.425 9.5 20.781 9.6435 21.068 9.9305C21.356 10.2185 21.5 10.575 21.5 11C21.5 11.425 21.356 11.781 21.068 12.068C20.781 12.356 20.425 12.5 20 12.5H12.5V20C12.5 20.425 12.3565 20.781 12.0695 21.068C11.7815 21.356 11.425 21.5 11 21.5Z"
											fill="currentColor"
										/>
									</svg>
								</button>
							</div>
							<TooltipComponent text={t("tooltip.deposit")}>
								<span className={styles.dashboardBtnLabel}>
									<span>{t("deposit")}</span>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										className="only-desktop"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2Z"
											fill="#FDFCFF"
											stroke="#939393"
										/>
										<circle
											cx="12"
											cy="7"
											r="1"
											fill="#939393"
										/>
										<rect
											x="11"
											y="10"
											width="2"
											height="8"
											rx="1"
											fill="#939393"
										/>
									</svg>
								</span>
							</TooltipComponent>
						</div>
						<div>
							<div>
								<button
									className="btnRound"
									onClick={() =>
										(window.location = "/withdraw-page")
									}
								>
									<svg
										width="25"
										height="24"
										viewBox="0 0 25 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M12.5 18.0001C12.075 18.0001 11.719 17.8561 11.432 17.5681C11.144 17.2811 11 16.9251 11 16.5001V5.77507L8.1875 8.58757C7.8875 8.88757 7.5375 9.03757 7.1375 9.03757C6.7375 9.03757 6.375 8.87507 6.05 8.55007C5.75 8.25007 5.6065 7.89357 5.6195 7.48057C5.6315 7.06857 5.775 6.72507 6.05 6.45007L11.45 1.05007C11.6 0.900073 11.7625 0.793573 11.9375 0.730573C12.1125 0.668573 12.3 0.637573 12.5 0.637573C12.7 0.637573 12.8875 0.668573 13.0625 0.730573C13.2375 0.793573 13.4 0.900073 13.55 1.05007L18.95 6.45007C19.25 6.75007 19.3935 7.10607 19.3805 7.51807C19.3685 7.93107 19.225 8.27507 18.95 8.55007C18.65 8.85007 18.294 9.00607 17.882 9.01807C17.469 9.03107 17.1125 8.88757 16.8125 8.58757L14 5.77507V16.5001C14 16.9251 13.8565 17.2811 13.5695 17.5681C13.2815 17.8561 12.925 18.0001 12.5 18.0001ZM3.5 24.0001C2.675 24.0001 1.969 23.7066 1.382 23.1196C0.794 22.5316 0.5 21.8251 0.5 21.0001V18.0001C0.5 17.5751 0.6435 17.2186 0.9305 16.9306C1.2185 16.6436 1.575 16.5001 2 16.5001C2.425 16.5001 2.7815 16.6436 3.0695 16.9306C3.3565 17.2186 3.5 17.5751 3.5 18.0001V21.0001H21.5V18.0001C21.5 17.5751 21.644 17.2186 21.932 16.9306C22.219 16.6436 22.575 16.5001 23 16.5001C23.425 16.5001 23.781 16.6436 24.068 16.9306C24.356 17.2186 24.5 17.5751 24.5 18.0001V21.0001C24.5 21.8251 24.2065 22.5316 23.6195 23.1196C23.0315 23.7066 22.325 24.0001 21.5 24.0001H3.5Z"
											fill="currentColor"
										/>
									</svg>
								</button>
							</div>
							<TooltipComponent text={t("tooltip.withdraw")}>
								<span className={styles.dashboardBtnLabel}>
									<span>{t("withdraw")}</span>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										className="only-desktop"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2Z"
											fill="#FDFCFF"
											stroke="#939393"
										/>
										<circle
											cx="12"
											cy="7"
											r="1"
											fill="#939393"
										/>
										<rect
											x="11"
											y="10"
											width="2"
											height="8"
											rx="1"
											fill="#939393"
										/>
									</svg>
								</span>
							</TooltipComponent>
						</div>
						<div>
							<div>
								<button
									className="btnRound"
									onClick={() =>
										(window.location =
											"/spot-trade?token=BTC-BUSD")
									}
								>
									<svg
										width="28"
										height="28"
										viewBox="0 0 28 28"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M0.5 27.5V24.5L3.5 21.5V27.5H0.5ZM6.5 27.5V18.5L9.5 15.5V27.5H6.5ZM12.5 27.5V15.5L15.5 18.5375V27.5H12.5ZM18.5 27.5V18.5375L21.5 15.5375V27.5H18.5ZM24.5 27.5V12.5L27.5 9.5V27.5H24.5ZM0.5 19.7375V15.5L11 5L17 11L27.5 0.5V4.7375L17 15.2375L11 9.2375L0.5 19.7375Z"
											fill="currentColor"
										/>
									</svg>
								</button>
							</div>
							<TooltipComponent text={t("tooltip.trade")}>
								<span className={styles.dashboardBtnLabel}>
									<span>{t("spotTrading")}</span>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										className="only-desktop"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2Z"
											fill="#FDFCFF"
											stroke="#939393"
										/>
										<circle
											cx="12"
											cy="7"
											r="1"
											fill="#939393"
										/>
										<rect
											x="11"
											y="10"
											width="2"
											height="8"
											rx="1"
											fill="#939393"
										/>
									</svg>
								</span>
							</TooltipComponent>
						</div>
					</div>
				</div>
				<div className={styles.pnlOverview}>
					<div>
						<span>{t("pnl.today") + ":"}</span>
						<span
							className={
								totalBalanceDiff < 0 ? "priceDown" : "priceUp"
							}
						>{`${totalBalanceDiff < 0 ? "-" : "+"}$${numberFormat(
							Math.abs(totalBalanceDiff)
						)}`}</span>
					</div>
					<div onClick={() => (window.location = "/pnl")}>
						<span>{t("pnl.detail")}</span>
						<svg
							width="10"
							height="16"
							viewBox="0 0 10 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M0.316206 15.6838C0.105402 15.473 0 15.2234 0 14.935C0 14.6472 0.105402 14.3979 0.316206 14.1871L6.49275 8.01054L0.295125 1.81291C0.098375 1.61616 0 1.37022 0 1.0751C0 0.779974 0.105402 0.527009 0.316206 0.316206C0.527009 0.105402 0.776601 0 1.06498 0C1.3528 0 1.60211 0.105402 1.81291 0.316206L8.89592 7.42029C8.98024 7.50461 9.04011 7.59596 9.07552 7.69433C9.11037 7.79271 9.1278 7.89811 9.1278 8.01054C9.1278 8.12297 9.11037 8.22837 9.07552 8.32675C9.04011 8.42512 8.98024 8.51647 8.89592 8.60079L1.79183 15.7049C1.59508 15.9016 1.3528 16 1.06498 16C0.776601 16 0.527009 15.8946 0.316206 15.6838Z"
								fill="#5757F7"
							/>
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DashBoardContent;
