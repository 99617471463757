import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import Table from "../Common/Table";
import { useGetRewardHistoryQuery } from "../../services/luckyWheel";

const MyRewards = () => {
	const [t] = useTranslation("common");
	const [page, setPage] = useState(1);

	const { data: prizes } = useGetRewardHistoryQuery({
		take: 10,
		skip: (page - 1) * 10,
	});

	const paginationOption = {
		noRowsPerPage: true,
		rangeSeparatorText: "/",
	};

	const handlePageChange = (newPage) => {
		setPage(newPage);
	};

	return (
		<div className={styles.myPrizes}>
			<div className="contentContainer">
				<div className="contentHead">{t("luckyWheel.myPrizes")}</div>
				<div className="contentBody">
					<Table
						type="PRIZE"
						data={prizes?.result?.data}
						pagination={true}
						paginationServer
						paginationComponentOptions={paginationOption}
						onChangePage={handlePageChange}
						paginationTotalRows={prizes?.result?.count}
					/>
				</div>
			</div>
		</div>
	);
};

export default MyRewards;
