import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import WalletHead from "../../Component/WalletPage/WalletHead";
import TransactionHistory from "../../Component/WalletPage/TransactionHistory";
import WithdrawCrypto from "../../Component/WalletPage/WithdrawCrypto";

function WithdrawPage() {
	const [t] = useTranslation("wallet");

	return (
		<div className="walletLayout">
			<div className="defaultContainer">
				<WalletHead title={`${t("withdraw")} crypto`} />
			</div>
			<WithdrawCrypto />
			<div className="contentContainer defaultContainer">
				<div className="contentHead">{t("withdrawHistory")}</div>
				<div className="contentBody">
					<TransactionHistory type="OUT" />
				</div>
			</div>
		</div>
	);
}
export default WithdrawPage;
