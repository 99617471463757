import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import LoadingDot from "../../Component/Animation/LoadingDot";
import { setCredentials } from "../../Store/auth";
import { useGetUserInfoQuery } from "../../services/auth";

const SSOAuth = () => {
	const search = useLocation().search;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		data: user,
		isLoading,
		refetch: refetchUserInfo,
		error,
		isFetching,
	} = useGetUserInfoQuery();
	const { access_token } = useSelector((state) => state.auth);

	const [isComplete, setIsComplete] = useState(false);
	useEffect(() => {
		const jwt = new URLSearchParams(search).get("access_token");
		if (jwt) {
			localStorage.setItem("access_token", jwt);
			dispatch(setCredentials(jwt));
		} else {
			setIsComplete(true);
		}
	}, [dispatch, navigate, search]);

	useEffect(() => {
		if (access_token) {
			refetchUserInfo();
		}
	}, [access_token, refetchUserInfo, error]);

	useEffect(() => {
		if (user?.email) {
			setIsComplete(true);
		}
	}, [user, isFetching]);

	useEffect(() => {
		if (isComplete) navigate("/");
	}, [isComplete]);
	return (
		<div className="d-flex align-items-center justify-content-center py-4">
			<LoadingDot />
		</div>
	);
};

export default SSOAuth;
