import React, { useState, useMemo, useEffect } from "react";
import {
	useGetUserInfoQuery,
	useRequestKYCMutation,
} from "../../services/auth";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { toastCfg } from "../../config";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useWindowSize } from "@react-hook/window-size";
import MobileModal from "../../Component/Common/MobileModal";

const baseStyle = {
	flex: 1,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "20px",
	borderWidth: 1,
	borderRadius: 10,
	borderColor: "#BCBCFB",
	borderStyle: "dashed",
	backgroundColor: "#fafafa",
	color: "#bdbdbd",
	outline: "none",
	transition: "border .24s ease-in-out",
	height: "200px",
	width: "250px",
};

const focusedStyle = {
	borderColor: "#2196f3",
};

const acceptStyle = {
	borderColor: "#00e676",
};

const rejectStyle = {
	borderColor: "#ff1744",
};

const ImgInput = ({ setSelectedImg, text }) => {
	const [t] = useTranslation("common");

	const {
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		accept: { "image/*": [] },
		maxFiles: 1,
		minFiles: 1,
		onDrop: (acceptedFiles) => {
			setSelectedImg(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)[0]
			);
		},
	});
	const style = useMemo(
		() => ({
			...baseStyle,
			...(isFocused ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isFocused, isDragAccept, isDragReject]
	);
	return (
		<div {...getRootProps({ style })}>
			<input {...getInputProps()} />
			<p>{t(text)}</p>
		</div>
	);
};

const KycModal = ({ isOpenKYCModal, setIsOpenKYCModal }) => {
	const [t] = useTranslation("common");
	const { data: user, refetch: refetchUserInfo } = useGetUserInfoQuery();
	const [width] = useWindowSize();
	const [selectedImg, setSelectedImg] = useState([]);

	const [frontImg, setFrontImg] = useState();
	const [backImg, setBackImg] = useState();

	const [requestKYC, { isLoading, error }] = useRequestKYCMutation();

	const onConfirm = async () => {
		const data = new FormData();
		data.append("front", frontImg);
		data.append("back", backImg);
		const res = await requestKYC(data);

		if (res?.error) {
			if (res?.error?.data?.message) {
				toast.error(res?.error?.data?.message, toastCfg);
			} else {
				toast.error(t("message.error"), toastCfg);
			}
		} else {
			toast.success(t("kycRequestSuccess"));
			refetchUserInfo();
		}
		setIsOpenKYCModal(false);
	};

	return (
		<>
			{width > 1200 ? (
				<Modal
					show={isOpenKYCModal}
					onHide={() => setIsOpenKYCModal(false)}
				>
					<Modal.Header closeButton>
						<Modal.Title>{t("uploadTitle")}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className={styles.kycModalContainer}>
							<div className={styles.kycImageContainer}>
								{frontImg ? (
									<div className={styles.selectedImgItem}>
										<img
											src={frontImg?.preview}
											alt="slected"
											width="100%"
										/>
										<span>{frontImg.path}</span>
									</div>
								) : (
									<ImgInput
										text={t("chooseImgFront")}
										setSelectedImg={(value) =>
											setFrontImg(value)
										}
									/>
								)}
								{backImg ? (
									<div className={styles.selectedImgItem}>
										<img
											src={backImg?.preview}
											alt="slected"
											width="100%"
										/>
										<span>{backImg.path}</span>
									</div>
								) : (
									<ImgInput
										text={t("chooseImgBack")}
										setSelectedImg={(value) =>
											setBackImg(value)
										}
									/>
								)}
							</div>

							{/* {selectedImg?.length < 2 ? (
						<div {...getRootProps({ style })}>
							<input {...getInputProps()} />
							<p>{t("chooseImg")}</p>
						</div>
					) : (
						<div className={styles.selectedImgContainer}>
							{selectedImg?.map((img) => (
								<div
									key={img.path}
									className={styles.selectedImgItem}
								>
									<img
										src={img?.preview}
										alt="slected"
										width="100%"
									/>
									<span>{img.path}</span>
								</div>
							))}
						</div>
					)} */}
						</div>
					</Modal.Body>
					<div className={styles.modalBottom}>
						<div className={styles.customSmallBtnContainer}>
							<button
								className="btnLight"
								onClick={() => {
									setIsOpenKYCModal(false);
									setSelectedImg([]);
								}}
							>
								{t("cancel")}
							</button>
						</div>
						<div className={styles.customBtnContainer}>
							<button
								className={
									selectedImg?.length === 2
										? "btnDark"
										: "btnDisabled"
								}
								onClick={() => onConfirm()}
							>
								{t("upload")}
							</button>
						</div>
					</div>
				</Modal>
			) : (
				<MobileModal
					title={t("uploadTitle")}
					open={isOpenKYCModal}
					close={() => setIsOpenKYCModal(false)}
				>
					<div className={styles.kycModalContainer}>
						<div className={styles.kycImageContainer}>
							{frontImg ? (
								<div className={styles.selectedImgItem}>
									<img
										src={frontImg?.preview}
										alt="slected"
										width="100%"
									/>
									<span>{frontImg.path}</span>
								</div>
							) : (
								<ImgInput
									text={t("chooseImgFront")}
									setSelectedImg={(value) =>
										setFrontImg(value)
									}
								/>
							)}
							{backImg ? (
								<div className={styles.selectedImgItem}>
									<img
										src={backImg?.preview}
										alt="slected"
										width="100%"
									/>
									<span>{backImg.path}</span>
								</div>
							) : (
								<ImgInput
									text={t("chooseImgBack")}
									setSelectedImg={(value) =>
										setBackImg(value)
									}
								/>
							)}
						</div>
					</div>
					<div className={styles.modalBottom}>
						<div className={styles.customSmallBtnContainer}>
							<button
								className="btnLight"
								onClick={() => {
									setIsOpenKYCModal(false);
									setSelectedImg([]);
								}}
							>
								{t("cancel")}
							</button>
						</div>
						<div className={styles.customBtnContainer}>
							<button
								className={
									selectedImg?.length === 2
										? "btnDark"
										: "btnDisabled"
								}
								onClick={() => onConfirm()}
							>
								{t("upload")}
							</button>
						</div>
					</div>
				</MobileModal>
			)}
		</>
	);
};

export default KycModal;
