import React, { useState, useEffect } from "react";
import AuthCode from "react-auth-code-input";
import Modal from "react-bootstrap/Modal";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "@react-hook/window-size";
import MobileModal from "../Common/MobileModal";

const Modal2FA = ({
	isOpen,
	requestCall,
	close,
	handleChangeCode,
	loading,
	code,
}) => {
	const [t] = useTranslation("wallet");
	const [width] = useWindowSize();
	return (
		<>
			{width > 1200 ? (
				<Modal show={isOpen} onHide={close} className={styles.otpModal}>
					<div>{t("OTPDes")}</div>
					<Modal.Body>
						<div>{t("enterCode")}</div>
						<AuthCode
							onChange={handleChangeCode}
							containerClassName="d-flex justify-content-between m-4"
							inputClassName={styles.codeInput}
							allowedCharacters="numeric"
						/>

						<button
							// variant="primary"
							onClick={() => requestCall()}
							className="btnDark"
							disabled={
								loading || !code || code?.toString()?.length < 6
							}
						>
							{t("confirm")}
						</button>
					</Modal.Body>
				</Modal>
			) : (
				<MobileModal open={isOpen} close={close} title={t("enterCode")}>
					<Modal.Body>
						<AuthCode
							onChange={handleChangeCode}
							containerClassName="d-flex justify-content-between m-4"
							inputClassName={styles.codeInput}
							allowedCharacters="numeric"
						/>

						<button
							// variant="primary"
							onClick={() => requestCall()}
							className="btnDark"
							disabled={
								loading || !code || code?.toString()?.length < 6
							}
						>
							{t("confirm")}
						</button>
					</Modal.Body>
					{/* keep this */}
					<div style={{ height: 250 }}></div>
				</MobileModal>
			)}
		</>
	);
};

export default Modal2FA;
