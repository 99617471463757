import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_FRYPTO_API,
	prepareHeaders: (headers, { getState }) => {
		const token = getState().auth.access_token;
		if (token) {
			headers.set("authorization", `Bearer ${token}`);
		}
		return headers;
	},
});

export default baseQuery;
