import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { useGetUserInfoQuery } from "../../services/auth";
import { useNavigate } from "react-router";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useGetTrendingPairsQuery } from "../../services/market";
import { useGetTokenListQuery } from "../../services/wallet";
import { numberFormat } from "../../utils/formatNumber";
import { useSelector } from "react-redux";
import autoAnimate from "@formkit/auto-animate";
import { useWindowSize } from "@react-hook/window-size";
const MarketChange = () => {
	const [t] = useTranslation("common");
	const [activeTab, setActiveTab] = useState(1);
	const parent = useRef(null);
	const [width] = useWindowSize();

	useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent]);

	return (
		<div ref={parent}>
			<p className={styles.heading}>{t("24hChange")}</p>
			<div className="contentContainer">
				<div className={styles.changeContent}>
					<Tabs
						activeKey={activeTab}
						onSelect={(key) => setActiveTab(key)}
						className={width < 1200 ? styles.mobileTab : undefined}
					>
						<Tab
							eventKey={1}
							title={
								<div className={styles.tabTitle}>
									<svg
										width="20"
										height="12"
										viewBox="0 0 20 12"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M0.699805 11.3C0.516471 11.1167 0.424805 10.8833 0.424805 10.6C0.424805 10.3167 0.516471 10.0833 0.699805 9.9L5.2998 5.3C5.88314 4.71667 6.59147 4.425 7.4248 4.425C8.25814 4.425 8.96647 4.71667 9.5498 5.3L10.6998 6.45C10.8998 6.65 11.1375 6.75 11.4128 6.75C11.6875 6.75 11.9248 6.65 12.1248 6.45L16.5748 2H14.9998C14.7165 2 14.4791 1.90433 14.2878 1.713C14.0958 1.521 13.9998 1.28333 13.9998 1C13.9998 0.716667 14.0958 0.479 14.2878 0.287C14.4791 0.0956668 14.7165 0 14.9998 0H18.9998C19.2831 0 19.5205 0.0956668 19.7118 0.287C19.9038 0.479 19.9998 0.716667 19.9998 1V5C19.9998 5.28333 19.9038 5.52067 19.7118 5.712C19.5205 5.904 19.2831 6 18.9998 6C18.7165 6 18.4791 5.904 18.2878 5.712C18.0958 5.52067 17.9998 5.28333 17.9998 5V3.425L13.5248 7.875C12.9415 8.45833 12.2331 8.75 11.3998 8.75C10.5665 8.75 9.85814 8.45833 9.2748 7.875L8.0998 6.7C7.91647 6.51667 7.68314 6.425 7.3998 6.425C7.11647 6.425 6.88314 6.51667 6.6998 6.7L2.0998 11.3C1.91647 11.4833 1.68314 11.575 1.3998 11.575C1.11647 11.575 0.883138 11.4833 0.699805 11.3Z"
											fill="currentColor"
										/>
									</svg>

									<span>{t("mostGain")}</span>
								</div>
							}
						></Tab>
						<Tab
							eventKey={-1}
							title={
								<div className={styles.tabTitle}>
									<svg
										width="20"
										height="12"
										viewBox="0 0 20 12"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M15.0004 12C14.7171 12 14.4797 11.904 14.2884 11.712C14.0964 11.5207 14.0004 11.2833 14.0004 11C14.0004 10.7167 14.0964 10.4793 14.2884 10.288C14.4797 10.096 14.7171 9.99999 15.0004 9.99999H16.6004L11.4004 4.84999L8.10039 8.14999C8.01706 8.23332 7.91306 8.29999 7.78839 8.34999C7.66306 8.39999 7.53372 8.42499 7.40039 8.42499C7.26706 8.42499 7.14206 8.39999 7.02539 8.34999C6.90872 8.29999 6.80039 8.23332 6.70039 8.14999L0.67539 2.07499C0.492057 1.89165 0.400391 1.66665 0.400391 1.39999C0.400391 1.13332 0.500391 0.899987 0.700391 0.699987C0.883724 0.516654 1.11706 0.424988 1.40039 0.424988C1.68372 0.424988 1.91706 0.516654 2.10039 0.699987L7.40039 5.99999L10.7004 2.69999C10.8004 2.59999 10.9087 2.52899 11.0254 2.48699C11.1421 2.44565 11.2671 2.42499 11.4004 2.42499C11.5337 2.42499 11.6587 2.44565 11.7754 2.48699C11.8921 2.52899 12.0004 2.59999 12.1004 2.69999L18.0004 8.59999V6.99999C18.0004 6.71665 18.0964 6.47899 18.2884 6.28699C18.4797 6.09565 18.7171 5.99999 19.0004 5.99999C19.2837 5.99999 19.5211 6.09565 19.7124 6.28699C19.9044 6.47899 20.0004 6.71665 20.0004 6.99999V11C20.0004 11.2833 19.9044 11.5207 19.7124 11.712C19.5211 11.904 19.2837 12 19.0004 12H15.0004Z"
											fill="currentColor"
										/>
									</svg>

									<span>{t("mostLoss")}</span>
								</div>
							}
						></Tab>
					</Tabs>
					<ChangeList orderDirection={activeTab} />
				</div>
			</div>
		</div>
	);
};

export default MarketChange;

const ChangeList = ({ orderDirection }) => {
	const [t] = useTranslation("common");
	const navigate = useNavigate();
	const [width] = useWindowSize();

	const [renderData, setRenderData] = useState([]);

	// const [currentPrice, setCurrentPrice] = useState(0);

	const { data: list, refetch } = useGetTrendingPairsQuery({
		limit: 10,
		orderDirection: orderDirection,
		orderBy: "priceChangePercent",
		quote: "BUSD",
	});

	const { data: tokenList } = useGetTokenListQuery();
	const parent = useRef(null);

	useEffect(() => {
		if (!list?.data) return;
		if (width < 1200) {
			setRenderData(list?.data?.slice(0, 5));
		} else {
			setRenderData(list?.data);
		}
	}, [list, width]);

	useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent]);

	return (
		<div
			className={width < 1200 ? styles.changeBox : styles.changeGrid}
			ref={parent}
		>
			{renderData.length > 0
				? renderData.map((item, index) => (
						<>
							{width < 1200 ? (
								<div
									key={item.id}
									onClick={() =>
										(window.location = `/spot-trade?token=${item?.baseAsset}-${item?.quoteAsset}`)
									}
								>
									<div>
										<span>{index + 1}</span>
										<img
											alt=""
											src={require(`../../assets/images/coin/${item.baseAsset}.png`)}
											width={44}
										/>
										<span>{item?.baseAsset}</span>
									</div>

									<PriceDetail
										startPrice={item?.lastPrice}
										priceChangePercent={
											item?.priceChangePercent
										}
										symbol={item?.symbol}
									/>
								</div>
							) : (
								<div
									key={item.id}
									className={styles.gridItem}
									onClick={() =>
										(window.location = `/spot-trade?token=${item?.baseAsset}-${item?.quoteAsset}`)
									}
								>
									<div>
										<span>{index + 1}</span>
										<img
											alt=""
											src={require(`../../assets/images/coin/${item.baseAsset}.png`)}
											width={44}
										/>
										<span>{item?.baseAsset}</span>
										<span>
											{tokenList?.length > 0 &&
												tokenList?.filter(
													(each) =>
														each.id ===
														item?.baseAsset
												)[0].name}
										</span>
									</div>

									<PriceDetail
										startPrice={item?.lastPrice}
										priceChangePercent={
											item?.priceChangePercent
										}
										symbol={item?.symbol}
									/>
								</div>
							)}
						</>
				  ))
				: null}
		</div>
	);
};

const PriceDetail = ({ startPrice, priceChangePercent, symbol }) => {
	const [currentPrice, setCurrentPrice] = useState(startPrice);
	const isPriceDown = () => priceChangePercent[0] === "-";

	const { changeData } = useSelector((state) => state.common);
	const getNewPrice = (symbol) => {
		if (!changeData || !changeData?.length > 0) return 0;
		return changeData?.filter((p) => p?.s === symbol)[0]?.c || 0;
	};

	useEffect(() => {
		const newPrice = getNewPrice(symbol);
		if (newPrice > 0) {
			setCurrentPrice(newPrice);
		}
	}, [changeData]);

	return (
		<div>
			<span>{"$" + numberFormat(currentPrice)}</span>
			<span className={isPriceDown() ? styles.priceDown : styles.priceUp}>
				{`${!isPriceDown() ? "+" : ""}${priceChangePercent}%`}
			</span>
		</div>
	);
};
