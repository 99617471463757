import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useGetReferralRewardsQuery } from "../../services/auth";
import Table from "../Common/Table";
import InviteFriendsModal from "./InviteFriendModal";
const Rewards = () => {
	const [t] = useTranslation("common");
	const [page, setPage] = useState(1);
	const { data: rewardData } = useGetReferralRewardsQuery((page - 1) * 10);
	const [openModal, setOpenModal] = useState(false);

	const paginationOption = {
		noRowsPerPage: true,
		rangeSeparatorText: "/",
	};

	const handlePageChange = (newPage) => {
		setPage(newPage);
	};

	return (
		<>
			<div className={styles.reward}>
				<div className="contentContainerMedium">
					<div className="contentHead">{t("myReward")}</div>
					<div>
						{rewardData?.length > 0 ? (
							<Table
								type="REF"
								data={rewardData}
								pagination={true}
								paginationServer
								paginationComponentOptions={paginationOption}
								onChangePage={handlePageChange}
							/>
						) : (
							<div className={styles.noReward}>
								<div>{t("noReward")}</div>
								<button
									className="btnDark"
									onClick={() => setOpenModal(true)}
								>
									{t("invite")}
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
			{openModal ? (
				<InviteFriendsModal
					openModal={openModal}
					setOpenModal={(value) => setOpenModal(value)}
				/>
			) : null}
		</>
	);
};

export default Rewards;
