import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import {
	useEnable2FAMutation,
	useGenerateQRQuery,
	useGetUserInfoQuery,
} from "../../services/auth";
import { useTranslation } from "react-i18next";
import LoadingDot from "../../Component/Animation/LoadingDot";
import { toastCfg } from "../../config";
import styles from "./styles.module.scss";
import { useWindowSize } from "@react-hook/window-size";
import MobileModal from "../../Component/Common/MobileModal";

const AuthenticatorModal = ({ isOpen, close }) => {
	const [t] = useTranslation("common");
	const [imgData, setImgData] = useState();
	const [
		enable2FA,
		{ isSuccess: isSuccessEnable, isLoading: isLoadingEnable2FA },
	] = useEnable2FAMutation();
	const { data: data2FA, isLoading: isLoadingQR } = useGenerateQRQuery();
	const { refetch: refetchUserInfo } = useGetUserInfoQuery();

	const [width] = useWindowSize();

	const confirm = async () => {
		await enable2FA({ secret: data2FA?.result?.secret });
	};

	const [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		if (data2FA?.result?.qr) {
			setImgData(data2FA?.result?.qr);
		}
	}, [data2FA]);

	useEffect(() => {
		if (!isLoadingEnable2FA && isSuccessEnable) {
			toast.success(t("ggAuthRequestSuccess"), toastCfg);
			refetchUserInfo();
			close();
		}
	}, [isLoadingEnable2FA, isSuccessEnable]);

	return (
		<>
			{width > 1200 ? (
				<Modal
					className="modal"
					id="EnableModal"
					style={{ display: "block" }}
					show={isOpen}
					onHide={() => {
						close();
					}}
				>
					<Modal.Header className="modal-header" closeButton={true}>
						<h5 className="modal-title">{t("activate")}</h5>
					</Modal.Header>
					<Modal.Body className="modal-body">
						<div className={styles.twoFAModalContainer}>
							<span className={styles.scanqr}>
								{t("scanQR")} {isLoadingQR && <LoadingDot />}
							</span>
							<div>
								{imgData && (
									<div className="d-flex align-items-center">
										<div
											className={
												styles.ggAuthStepContainer
											}
										>
											{t("ggAuth.step1")}
											<br></br>
											{t("ggAuth.step2")}
										</div>

										<img src={imgData} alt="" width={250} />
									</div>
								)}
								<div className={styles.checkContainer}>
									<Form.Check
										type="checkbox"
										label={t("scanned")}
										value={isChecked}
										onChange={() =>
											setIsChecked(!isChecked)
										}
									/>
									{isChecked ? (
										<p className={styles.caution}>
											{t("ggAuth.warning")}
										</p>
									) : null}
								</div>
								<div className="d-flex justify-content-center">
									<div className={styles.customBtnContainer}>
										<button
											type="button"
											className={
												!isChecked
													? `btnDisabled`
													: "btnDark"
											}
											onClick={() => confirm()}
											disabled={!isChecked}
										>
											{t("verify")}
										</button>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			) : (
				<MobileModal open={isOpen} close={close} title={t("activate")}>
					<div className={styles.twoFAModalContainer}>
						<span className={styles.scanqr}>
							{t("scanQR")} {isLoadingQR && <LoadingDot />}
						</span>
						<div>
							{imgData && (
								<div className={styles.qrContainner}>
									<div className={styles.ggAuthStepContainer}>
										{t("ggAuth.step1")}
										<br></br>
										{t("ggAuth.step2")}
									</div>

									<img src={imgData} alt="" width={250} />
								</div>
							)}
							<div className={styles.checkContainer}>
								<Form.Check
									type="checkbox"
									label={t("scanned")}
									value={isChecked}
									onChange={() => setIsChecked(!isChecked)}
								/>
								{isChecked ? (
									<p className={styles.caution}>
										{t("ggAuth.warning")}
									</p>
								) : null}
							</div>
							<div className="d-flex justify-content-center">
								<div className={styles.customBtnContainer}>
									<button
										type="button"
										className={
											!isChecked
												? `btnDisabled`
												: "btnDark"
										}
										onClick={() => confirm()}
										disabled={!isChecked}
									>
										{t("verify")}
									</button>
								</div>
							</div>
						</div>
					</div>
				</MobileModal>
			)}
		</>
	);
};

export default AuthenticatorModal;
