import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

export const EmailVerification = (props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [text, setText] = useState('Xác thực thông tin email')
  const navigate = useNavigate()
  useEffect(() => {
    const url = process.env.REACT_APP_FRYPTO_API + "/auth/email/confirm";
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token: searchParams.get('token') })
    }

    fetch(url, requestOptions)
      .then(res => {
        if (res.status == 400)
          setText('Xác thực thất bại email')
        else {
          setText('Xác thực thành công email')
          navigate('/sign-in')
        }
      })
      .catch(error => {
        console.log("error", error);
      });
  }, [])

  return (
    <div>
      {text}
    </div>
  )
}