import React, { useRef, useState, useEffect } from "react";
import styles from "./styles.module.scss";
import RewardModal from "../../Component/LuckyWheel/RewardModal";
import {
	useGetRewardListQuery,
	useSpinMutation,
	useSpinFromInviteLinkMutation,
	useGetRemainingSpinFromInviteLinkQuery,
} from "../../services/luckyWheel";
import { useLocation, useNavigate } from "react-router";

import { useGetUserInfoQuery } from "../../services/auth";

import InviteFriend from "../../Component/LuckyWheel/InviteFriend";
import Guide from "../../Component/LuckyWheel/Guide";
import MyRewards from "../../Component/LuckyWheel/MyRewards";
import Tasks from "../../Component/LuckyWheel/Tasks";

import Wheel from "../../assets/images/wheel.svg";
import WheelLight from "../../assets/images/wheel-light.svg";
import Arrow from "../..//assets/images/wheel-arrow.svg";
import { useTranslation } from "react-i18next";
import RewardDetail from "../../Component/LuckyWheel/RewardDetail";
import { toast } from "react-toastify";
import { toastCfg } from "../../config";

const LuckyWheel = () => {
	const [t] = useTranslation("common");
	const navigate = useNavigate();
	const search = useLocation().search;
	const [inviteCode, setInviteCode] = useState();

	const { data: user, refetch: refetchUserInfo } = useGetUserInfoQuery();
	const { data: rewardListData } = useGetRewardListQuery();
	// const { data: rewardHistory } = useGetRewardHistoryQuery();
	const {
		data: remainingSpinFromInviteLink,
		refetch: refetchRemainingSpinFromInviteLink,
	} = useGetRemainingSpinFromInviteLinkQuery(inviteCode);

	const [spin] = useSpinMutation();
	const [spinFromInvite] = useSpinFromInviteLinkMutation();

	const [spinsLeft, setSpinsLeft] = useState(0);
	const [rewardList, setRewardList] = useState([]);

	const [rotation, setRotation] = useState(0);
	const [deg, setDeg] = useState(0);
	const [power, setPower] = useState(0);
	const [isSpinning, setIsSpinning] = useState(false);
	const [isOpenRewardModal, setIsOpenRewardModal] = useState(false);

	const [isShowPowerBar, setIsShowPowerBar] = useState(false);

	const [reward, setReward] = useState();

	const [particleCount, setParticleCount] = useState(25);

	const intervalRef = useRef(null);

	const wheel = useRef();

	const powerUp = () => {
		setPower(0);
		if (intervalRef.current) return;
		if (!user && inviteCode) {
			if (parseInt(remainingSpinFromInviteLink?.result?.available) < 1)
				return;
		} else {
			if (spinsLeft < 1) return;
		}
		intervalRef.current = setInterval(() => {
			setPower((prevPower) => {
				if (prevPower + 1 <= 200) return prevPower + 1;
				return 10;
			});
		}, 10);
	};

	const resetPower = () => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
	};

	const spinWheel = async () => {
		let res;
		let result;
		setIsShowPowerBar(false);
		resetPower();
		if (!user && inviteCode) {
			if (parseInt(remainingSpinFromInviteLink?.result?.available) < 1)
				return;
			res = await spinFromInvite(inviteCode);
			if (res?.data?.statusCode !== "SUCCESS") {
				toast.error("Something went wrong! Try again later.", toastCfg);
				return;
			}
			result = parseInt(res?.data?.result?.payload?.id) - 1;
			setReward(res?.data?.result?.payload);
			sessionStorage.setItem(
				"rewardToBeClaimed",
				JSON.stringify(res?.data?.result)
			);
		} else {
			if (spinsLeft < 1) return;
			res = await spin();
			if (res?.data?.statusCode !== "SUCCESS") {
				toast.error("Something went wrong! Try again later.", toastCfg);
				return;
			}

			result = parseInt(res?.data?.result?.id) - 1;
			setReward(res?.data?.result);
			setSpinsLeft(spinsLeft - 1);
		}

		let currPower = power <= 100 ? power : 100 - (power - 100);

		let maxDeg = 15 + 360 - result * 30;
		let minDeg = 15 + 360 - (result + 1) * 30 + 1;

		let resultDeg =
			Math.floor(Math.random() * (maxDeg - minDeg + 1)) + minDeg;

		let time = 5 + Math.floor(power / 5);

		wheel.current.style.transition = `all ${time}s cubic-bezier(0.16, 1, 0.3, 1)`;

		let changeRotation =
			360 * Math.max(1, Math.floor(currPower ** 1.25 / 10 ** 1.25)) + 360;

		wheel.current.style.transform = `rotate(${
			rotation + resultDeg - deg + changeRotation
		}deg)`;

		setIsSpinning(true);
		setRotation(rotation + resultDeg - deg + changeRotation);
		setDeg(resultDeg);
	};

	useEffect(() => {
		if (wheel && !wheel?.current?.style?.transform) {
			wheel.current.style.transform = `rotate(0deg)`;
		}
	}, [wheel]);

	useEffect(() => {
		const invite = new URLSearchParams(search).get("inviteCode");
		if (invite) setInviteCode(invite);

		return () => resetPower();
	}, []);

	useEffect(() => {
		if (rewardListData) setRewardList(rewardListData.result);
	}, [rewardListData]);

	useEffect(() => {
		if (rewardListData?.result && reward) {
			let result = rewardListData?.result?.filter(
				(prize) => prize?.id === reward.id
			)[0];
			setParticleCount(10 * (1 / result?.probability));
		}
	}, [rewardListData, reward]);

	useEffect(() => {
		if (user) {
			setSpinsLeft(user.spin);
		}
	}, [user]);

	useEffect(() => {
		if (isSpinning) {
			const resetTimer = setTimeout(() => {
				setIsOpenRewardModal(true);
				if (!user && inviteCode) {
					refetchRemainingSpinFromInviteLink();
				} else {
					refetchUserInfo();
				}
			}, (5 + Math.floor(power / 5)) * 1000);

			resetPower();
			return () => clearTimeout(resetTimer);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSpinning, power]);

	return (
		<>
			<div className={styles.luckyWheelContainer}>
				<div>
					<div>
						<div>
							<p className={styles.head}>
								{t("luckyWheel.heading")}
							</p>
						</div>
						<div>
							<img alt="" src={Wheel} className={styles.wheel} />
							<img
								alt=""
								src={WheelLight}
								className={styles.wheel}
							/>

							<div className={styles.luckyWheel} ref={wheel}>
								{rewardList.map((reward) => (
									<div
										className={styles.prizeLabel}
										key={reward.id}
									>
										<RewardDetail reward={reward} />
									</div>
								))}
							</div>
							<button
								className={styles.spinButton}
								onMouseEnter={() => setIsShowPowerBar(true)}
								onMouseLeave={() => setIsShowPowerBar(false)}
								onMouseDown={powerUp}
								onMouseUp={spinWheel}
								disabled={isSpinning}
							>
								<div>
									<div>{`${
										!user && inviteCode
											? remainingSpinFromInviteLink
													?.result?.available
											: spinsLeft
									}`}</div>
									<div>Spin(s)</div>
								</div>
							</button>
							<img
								className={styles.arrow}
								alt=""
								src={Arrow}
								height={50}
							/>
							{isShowPowerBar ? (
								<div className={styles.powerBar}>
									<div
										style={{
											width: `${
												power <= 100
													? power
													: 100 - (power - 100)
											}%`,
										}}
									></div>
								</div>
							) : null}
						</div>
						<button
							className="btnDark"
							onClick={
								user
									? () =>
											navigate(
												"/spot-trade?token=BTC-BUSD"
											)
									: () => navigate("/sign-up")
							}
						>
							{user
								? t("luckyWheel.tradeToGetSpin")
								: t("luckyWheel.registerToGetSpin")}
						</button>
					</div>

					<Tasks />
				</div>
				{user ? <InviteFriend /> : null}
				{user ? <MyRewards /> : null}
				<Guide />
			</div>

			{isOpenRewardModal && (
				<RewardModal
					openModal={isOpenRewardModal}
					handleClose={() => {
						setIsOpenRewardModal(false);
						setIsSpinning(false);
					}}
					reward={reward}
					inviteCode={inviteCode}
					particleCount={particleCount}
				/>
			)}
		</>
	);
};

export default LuckyWheel;
