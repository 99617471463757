import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import {
	formatDateNotime,
	getPastDate,
	getPastDateRaw,
	getDateFromRespond,
} from "../../utils/date";

import autoAnimate from "@formkit/auto-animate";
import Chart from "react-apexcharts";
import { useGetPNLDetailQuery } from "../../services/wallet";
import { useWindowSize } from "@react-hook/window-size";

const DetailAssetChart = ({ pnl }) => {
	const [t] = useTranslation("wallet");

	const [width] = useWindowSize();

	const [categories, setCategories] = useState([""]);
	const [data, setData] = useState([0]);
	const [amount, setAmount] = useState([0]);

	useEffect(() => {
		let values = [];
		let dates = [];
		let amounts = [];
		if (pnl?.result[0]?.length > 0) {
			let total = 0;
			let amountTotal = 0;
			for (let i = 0; i < pnl.result.length; i++) {
				values.push(
					parseFloat(pnl.result[i][0]?.valueInUSD).toFixed(2)
				);
				dates.push(getDateFromRespond(pnl.result[i][0]?.date));
			}
		}
		setData(values);
		setCategories(dates);
	}, [pnl]);

	return (
		<div>
			<div>{t("pnl.valueUSD")}</div>
			<Chart
				options={{
					chart: {
						height: 350,
						type: "line",
						zoom: {
							enabled: false,
						},
						toolbar: {
							show: false,
						},
						animations: {
							enabled: true,
						},
					},
					dataLabels: {
						enabled: false,
					},
					stroke: {
						show: true,
						curve: data?.length >= 7 ? "smooth" : "straight",
						lineCap: "butt",
						colors: "#BCBCFB",
						width: 2.5,
						dashArray: 0,
					},
					xaxis:
						width > 1200
							? {
									categories: categories,
									axisTicks: {
										show: true,
										borderType: "solid",
										color: "#78909C",
										height: 6,
										offsetX: 0,
										offsetY: 0,
									},
							  }
							: {
									categories: categories,
									show: false,
									labels: {
										show: false,
									},
									axisBorder: {
										show: false,
									},
									axisTicks: {
										show: false,
									},
							  },
					yaxis:
						width > 1200
							? {}
							: {
									show: false,
									labels: {
										show: false,
									},
									axisBorder: {
										show: false,
									},
									axisTicks: {
										show: false,
									},
							  },
					grid: {
						show: true,
						borderColor: "#90A4AE",
						strokeDashArray: 100,
						position: "back",
						xaxis: {
							lines: {
								show: true,
							},
						},
						yaxis: {
							lines: {
								show: true,
							},
						},
						row: {
							colors: undefined,
							opacity: 0.5,
						},
						column: {
							colors: undefined,
							opacity: 0.5,
						},
					},
					colors: ["#5757F7"],
					markers: {
						size: 3,
					},
				}}
				series={[
					{
						name: "",
						data: data,
					},
				]}
				type="line"
				height={300}
			/>
			{/* <Chart
				options={{
					chart: {
						type: "line",
						zoom: {
							enabled: false,
						},
						toolbar: {
							show: false,
						},
						animations: {
							enabled: true,
						},
					},
					dataLabels: {
						enabled: false,
					},
					stroke: {
						show: true,
						curve: "straight",
						lineCap: "butt",
						colors: ["#BCBCFB", "#BCBCFB"],
						width: [2.5, 0],
						dashArray: 0,
					},
					xaxis: {
						categories: categories,
						axisTicks: {
							show: true,
							borderType: "solid",
							color: "#78909C",
							height: 6,
							offsetX: 0,
							offsetY: 0,
						},
					},
					yaxis: [
						{
							title: {
								text: "",
							},
						},
						{
							opposite: true,
							title: {
								text: "",
							},
						},
					],
					legend: {
						show: false,
					},
					colors: ["#5757F7", "#5757F7"],
					markers: {
						size: 3,
					},
					title: {
						text: "",
					},
				}}
				series={[
					{
						name: "a",
						data: data,
						type: "line",
					},
					{
						name: "b",
						data: amount,
						type: "column",
					},
				]}
				type="line"
				height={300}
			/> */}
		</div>
	);
};

export default DetailAssetChart;
