const tos = `
Updated 10 April 2023
Frypto operates this website.Throughout the site, the terms "we", "us" and "our" refer to Frypto. Frypto offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
By visiting our site and/or purchasing something from us, you engage in our "Service" and agree to be bound by the following terms and conditions ("Terms of Service", "Terms"), including those additional terms and conditions and policies referenced herein and/or available by hyperlink.  All users of the site, including without limitation browsers, vendors, customers, merchants, and/or content producers, are subject to these Terms of Service.
Please read these Terms of Service carefully before accessing or using our website.. You agree to be bound by these Terms of Service by accessing or using any element of the website. You are not permitted to use any services or visit the website if you do not agree to all of the terms and conditions of this agreement. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
Any new features or tools which are added to the current store shall also be subject to the Terms of Service. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website.You can review the most current version of the Terms of Service at any time on this page and it is your responsibility to check this page periodically for changes. By continuing to use or accessing the website, you have given acceptance to all changes.  
Upon your acceptance of these Terms of Service, you warrant and affirm that you have attained the age of majority in your state, province, or country of residence, or that you have provided us with consent to permit any of your minor dependents to access this site. Please note that our services & products are not available to U.S. citizenship holders.
You are strictly prohibited from utilizing our products and services for any unlawful or unsanctioned purposes and are further prohibited from breaching any laws in your jurisdiction, including but not limited to those governing copyright. Any failure to adhere to these terms will result in the immediate termination of your Services.
General Conditions
We reserve the right to reject the provision of Services to any individual, at any time, and for any reason without justification. You acknowledge that your content, excluding credit card data (in case required), may be transmitted unencrypted, and may involve (a) communications over various networks and (b) changes necessary to conform and adapt to the technical requirements of different networks or devices. You agree not to reproduce, duplicate, copy, sell, resell or exploit any part of the Service, use of the Service, or access to the Service or any contact on the website through which the Service is provided without our express written permission. The headings included in this agreement are provided solely for convenience and do not limit or otherwise affect these Terms.
Accuracy, Completeness and Timeliness of Information
We shall not be held liable in the event that the information presented on this site is found to be inaccurate, incomplete, or outdated. The materials provided on this site are for general information purposes only and must not be solely relied upon or used as the basis for making any decisions without first consulting primary, accurate, complete, or timely sources of information. Any reliance on the material provided on this site is done at your own peril.
This site may contain some historical data, which is not current, and is provided solely for your reference. We reserve the right to modify the contents of this site at any time without any obligation to update any information on our site. You agree that it is your sole responsibility to keep track of any changes made to our site.
Modifications to the Service and Prices
The prices of our products are subject to change without prior notice. We retain the right, at our discretion, to modify or discontinue the Service (or any component or content thereof) without notice at any time. We shall not be held liable to you or any third party for any modification, price alteration, suspension, or discontinuation of the Service.
Product or Services
We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at any time without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.


All prices are exclusive of applicable taxes and you will pay or reimburse us for all applicable taxes, duties, levies or assessments that may be assessed in any jurisdiction, whether based on the amounts paid or payable or the supply of Products, Software or Services to you or otherwise. If you wish to claim an exemption from the same, you must furnish to us a tax exemption certificate acceptable to the applicable taxing authority.
Accuracy of Shipping, Billing, and Account Information
We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.
You agree to provide current, complete and accurate information for all purchases made at our store. You are solely liable for any losses or extra expenses arising from incomplete or inaccurate information. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.
Optional Tools
We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.
You acknowledge and agree that we provide access to such tools "as is" and "as available" without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.
Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).
We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.
Third-Party Links
Our Service may offer content, products, and services that include materials from third-party sources. Additionally, there may be links to third-party websites that are not associated with us. It's important to note that we do not assess or verify the content or accuracy of these third-party materials or websites. As such, we cannot guarantee their reliability or take responsibility for any inaccuracies or potential harm caused by these materials, websites, or any other products or services offered by third-parties.
We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
User Comments, Feedback and Other Submissions
If, at our request, you send certain specific submissions or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you also grant us the right to, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.
We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.
You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.
Personal Information
Your submission of personal information is governed by our Privacy Policy.
Errors, Inaccuracies and Omissions
Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).
We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.
Prohibited Uses
Aside from the restrictions outlined in the Terms of Service, the use of the site or its content is strictly prohibited for the following purposes: (a) engaging in any unlawful activities, (b) soliciting others to participate in unlawful activities, (c) violating any local, state, federal, or international regulations, rules, laws, or ordinances, (d) infringing on our intellectual property rights or those of others, (e) engaging in discriminatory behavior or harassment based on characteristics such as gender, sexual orientation, age, ethnicity, religion, national origin, or disability, (f) providing false or misleading information, (g) uploading or transmitting any harmful software or code that may negatively impact the Service, associated websites, or the internet, (h) collecting or tracking personal information of others, (i) engaging in spamming or other similar activities, (j) engaging in obscene or immoral behavior, (k) attempting to bypass the security features of the Service or associated websites, or (l) duplicating, copying, selling, trading, or exploiting any portion of the web content for commercial purposes. Violating these restrictions may result in termination of your access to the Service and associated websites.
Trademark Information
You herein acknowledge, understand and agree that all of the Frypto  trademarks, copyright, trade name, service marks, and other logos and any brand features, and/or product and service names are trademarks and as such, are and shall remain the property of Frypto . You herein agree not to display and/or use these in any manner without our express written consent.
Disclaimer of Warranties; Limitations of Liability
We do not guarantee, represent, or warrant that your use of our service will be uninterrupted, timely, secure, or error-free. Additionally, we do not guarantee that the results obtained from using the service will be accurate or reliable. You acknowledge and agree that we may remove or cancel the service, without notice to you, for indefinite periods of time.
You agree that the use of the service is at your sole risk. All products and services delivered through the service, unless expressly stated otherwise by us, are provided on an "as is" and "as available" basis, without any representation, warranties, or conditions of any kind, whether express or implied, including but not limited to the implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.
In no event shall Frypto, its directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers, or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including but not limited to lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability, or otherwise, arising from your use of the service or any products procured through the service, or for any other claim related in any way to your use of the service or any product, including but not limited to any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available through the service, even if advised of the possibility of such damages. In states or jurisdictions where the exclusion or limitation of liability for consequential or incidental damages is not allowed, our liability shall be limited to the maximum extent permitted by law.
Force Majeure
If our performance of any of our obligations is prevented, restricted, or interfered with, by reason of: fire, flood, earthquake, explosion or other casualty or accident or act of nature; strikes or labour disputes; inability to procure or obtain delivery of parts, supplies, power, telecommunication services, equipment or software from suppliers, war or other violence; any law, order, proclamation, regulation, ordinance, demand or requirement of any governmental authority; or any other act or condition whatsoever beyond our reasonable control, we will be excused from such performance.
Indemnification
By using our Service, you agree to protect and defend Frypto and its affiliates, including its officers, directors, employees, and agents, from any claims, demands, or actions made by any third-party, including reasonable legal fees, due to or arising out of your violation of these Terms of Service, or any other documents they reference, or your violation of any law or the rights of a third-party.
Severability
In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.
Termination
The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.
These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.
If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).
Entire Agreement
The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.
These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
Governing Law
The laws of Australia shall be applied and interpreted to govern both the Terms of Service and any distinct agreements that we enter into to provide Services to you.
Changes to Term of Service
You can review the most current version of the Terms of Service at any time at this page.
We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
`;

export default tos;
