import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { getDateFromRespond } from "../../utils/date";

import Chart from "react-apexcharts";
import { useGetPNLDetailQuery } from "../../services/wallet";
import { useWindowSize } from "@react-hook/window-size";

const ProfitChart = ({ pnl }) => {
	const [t] = useTranslation("wallet");
	const [width] = useWindowSize();

	const [categories, setCategories] = useState([""]);
	const [data1, setData1] = useState([0]);
	const [data2, setData2] = useState([0]);

	useEffect(() => {
		let valuesUSD = [];
		let valuesBTC = [];
		let dates = [];
		let diffsUSD = [];
		let diffsBTC = [];

		if (pnl?.result[0].length > 0) {
			for (let i = 0; i < pnl.result.length; i++) {
				let totalUSD = 0;
				let totalBTC = 0;

				for (let j = 0; j < pnl.result[i].length; j++) {
					totalUSD += parseFloat(pnl.result[i][j]?.valueInUSD);
					totalBTC += parseFloat(pnl.result[i][j]?.valueInBTC);
				}
				valuesUSD.push(totalUSD.toFixed(2));
				valuesBTC.push(totalBTC.toFixed(6));

				dates.push(getDateFromRespond(pnl.result[i][0]?.date));
			}
			for (let i = 0; i < pnl.result.length; i++) {
				diffsUSD.push((valuesUSD[i] - valuesUSD[0]).toFixed(2));
				diffsBTC.push((valuesBTC[i] - valuesBTC[0]).toFixed(6));
			}
		}
		setData1(diffsUSD);
		setData2(diffsBTC);
		setCategories(dates);
	}, [pnl]);

	return (
		<div>
			<div className={styles.chartHead}>
				<div>{t("pnl.valueUSD")}</div>
				<div>{t("pnl.valueBTC")}</div>
			</div>

			<Chart
				options={{
					chart: {
						type: "line",
						zoom: {
							enabled: false,
						},
						toolbar: {
							show: false,
						},
						animations: {
							enabled: true,
						},
					},
					dataLabels: {
						enabled: false,
					},
					stroke: {
						show: true,
						curve: [
							data1?.length >= 7 ? "smooth" : "straight",
							data2?.length >= 7 ? "smooth" : "straight",
						],
						lineCap: "butt",
						colors: ["#BCBCFB", "#E5BB69"],
						width: [2.5, 2.5],
						dashArray: 0,
					},
					xaxis:
						width > 1200
							? {
									categories: categories,
									axisTicks: {
										show: true,
										borderType: "solid",
										color: "#78909C",
										height: 6,
										offsetX: 0,
										offsetY: 0,
									},
							  }
							: {
									categories: categories,
									show: false,
									labels: {
										show: false,
									},
									axisBorder: {
										show: false,
									},
									axisTicks: {
										show: false,
									},
							  },
					yaxis: [
						{
							title: {
								text: "",
							},
							show: width > 1200 ? true : false,
						},
						{
							opposite: true,
							title: {
								text: "",
							},
							show: width > 1200 ? true : false,
						},
					],
					colors: ["#5757F7", "#E5BB69"],
					markers: {
						size: 3,
					},
				}}
				series={[
					{
						data: data1,
						name: "USD",
					},
					{
						data: data2,
						name: "BTC",
					},
				]}
				type="line"
				height={width > 1200 ? 410 : 250}
			/>
		</div>
	);
};

export default ProfitChart;
