import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import Avatar from "../../assets/images/ava.png";
import { useGetUserInfoQuery } from "../../services/auth";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Settings from "./Settings";
import Info from "./Info";
import { useWindowSize } from "@react-hook/window-size";

const Account = () => {
	const [t] = useTranslation("auth");
	const [activeTab, setActiveTab] = useState("1");
	const [width] = useWindowSize();

	const { data: user } = useGetUserInfoQuery();

	return (
		<>
			<div className={styles.account}>
				<p className={styles.title}>{t("account")}</p>
				<Tabs
					activeKey={activeTab}
					onSelect={(key) => setActiveTab(key)}
					className={width < 1200 ? styles.mobileTab : undefined}
				>
					<Tab eventKey={"1"} title={t("profile")}></Tab>
					<Tab eventKey={"2"} title={t("settings")}></Tab>
				</Tabs>
				<div className={styles.display}>
					{activeTab === "1" && <Info />}
					{activeTab === "2" && <Settings />}
				</div>
			</div>
		</>
	);
};

export default Account;
