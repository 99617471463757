import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetCredentials, setLang } from "../../Store/auth";
import styles from "./styles.module.scss";
import { useGetUserInfoQuery } from "../../services/auth";
import { useTranslation } from "react-i18next";
import { LANG_LIST } from "../../config";
import { authService } from "../../services/auth";
import { walletService } from "../../services/wallet";
import { spotService } from "../../services/spot";

import autoAnimate from "@formkit/auto-animate";
import { numberFormat } from "../../utils/formatNumber";
import { useGetWalletAssetsQuery } from "../../services/wallet";
import { useGetSpotAssetsQuery } from "../../services/spot";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Avatar from "../../assets/images/ava.png";

const DEFAULT_LANG = localStorage.getItem("lang") || LANG_LIST[0].code;

function Header() {
	const [t, i18n] = useTranslation("common");
	const navigate = useNavigate();
	const location = useLocation();

	const dispatch = useDispatch();

	const auth = useSelector((state) => state.auth);

	const { refetch: refetchSpotAssets } = useGetSpotAssetsQuery();
	const { refetch: refetchWalletAssets } = useGetWalletAssetsQuery();

	const [selectedLang, setSelectedLang] = useState(auth.lang || DEFAULT_LANG);

	const {
		data: user,
		isLoading,
		refetch: refetchUserInfo,
		error,
	} = useGetUserInfoQuery();
	const [isOpenConfirmEmail, setIsOpenConfirmEmail] = useState(false);

	const logOut = () => {
		clearData();
		navigate("/");
	};

	const clearData = () => {
		localStorage.removeItem("access_token");
		localStorage.removeItem("email");
		sessionStorage.removeItem("access_token");
		sessionStorage.removeItem("email");
		dispatch(resetCredentials(null));
		dispatch(authService.util.resetApiState());
		dispatch(walletService.util.resetApiState());
		dispatch(spotService.util.resetApiState());
	};

	const parent = useRef(null);

	useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent]);

	useEffect(() => {
		if (error?.status === 401 && auth?.access_token) {
			clearData();
		}
	}, [error]);

	useEffect(() => {
		i18n.changeLanguage(selectedLang);
		localStorage.setItem("lang", selectedLang);
		dispatch(setLang(selectedLang));
	}, [selectedLang, i18n]);

	useEffect(() => {
		if (location.pathname === "/spot-trade") return;
		const wssPrice = new WebSocket("wss://stream.binance.com:9443/ws");

		wssPrice.onopen = (event) => {
			wssPrice.send(
				JSON.stringify({
					method: "SUBSCRIBE",
					params: ["btcbusd" + "@ticker"],
					id: 2,
				})
			);
		};

		wssPrice.onmessage = (event) => {
			let price24hData = JSON.parse(event?.data);

			if (price24hData.e === "24hrTicker") {
				document.title = `${numberFormat(
					price24hData.c
				)}  |  BTCBUSD  |  Frypto`;
			}
		};
		return () => {
			wssPrice.close();
		};
	}, [location]);

	useEffect(() => {
		if (user?.access_token) {
			const timer = setInterval(() => {
				refetchSpotAssets();
				refetchWalletAssets();
			}, 10000);
			return () => clearInterval(timer);
		}
	}, [user]);

	return (
		<>
			<Navbar
				expand={"xl"}
				className={styles.header}
				collapseOnSelect
				sticky="top"
			>
				<div className="container-xxl">
					<Navbar.Brand href="/">
						<div className="logoIcon">
							<Nav.Link
								href={process.env.PUBLIC_URL + "/"}
								className="list-group-item list-group-item-action border-0 "
							>
								<img
									src="https://cdn1.finhay.com.vn/wp-content/uploads/2022/11/01143422/unnamed.png"
									alt="logo"
									width={120}
									data-tip={t("header.logoTooltip")}
								/>
							</Nav.Link>
						</div>
					</Navbar.Brand>
					<Navbar.Toggle
						aria-controls={`offcanvasNavbar-expand-${"xl"}`}
					>
						<svg
							width="32"
							height="32"
							viewBox="0 0 32 32"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M4 9C4 8.44772 4.44772 8 5 8H27C27.5523 8 28 8.44772 28 9V9.66667C28 10.219 27.5523 10.6667 27 10.6667H5C4.44772 10.6667 4 10.219 4 9.66667V9ZM4 15.6667C4 15.1144 4.44772 14.6667 5 14.6667H27C27.5523 14.6667 28 15.1144 28 15.6667V16.3333C28 16.8856 27.5523 17.3333 27 17.3333H5C4.44772 17.3333 4 16.8856 4 16.3333V15.6667ZM4 22.3333C4 21.781 4.44772 21.3333 5 21.3333H27C27.5523 21.3333 28 21.781 28 22.3333V23C28 23.5523 27.5523 24 27 24H5C4.44772 24 4 23.5523 4 23V22.3333Z"
								fill="#939393"
							/>
						</svg>
					</Navbar.Toggle>
					<Navbar.Offcanvas
						id={`offcanvasNavbar-expand-${"xl"}`}
						aria-labelledby={`offcanvasNavbarLabel-expand-${"xl"}`}
						placement="end"
					>
						<Offcanvas.Header closeButton>
							<Offcanvas.Title
								id={`offcanvasNavbarLabel-expand-${"xl"}`}
							>
								{
									<div className="heading5">
										{user?.email || ""}
									</div>
								}
							</Offcanvas.Title>
						</Offcanvas.Header>
						<Offcanvas.Body>
							<div className={styles.menu}>
								<div className={styles.navMenu}>
									<Nav.Link
										href={
											process.env.PUBLIC_URL +
											"/deposit-page"
										}
									>
										{t("header.buy")}
									</Nav.Link>

									<Nav.Link
										href={
											process.env.PUBLIC_URL +
											"/spot-trade?token=BTC-BUSD"
										}
									>
										{t("header.trade")}
									</Nav.Link>

									<Nav.Link
										href={"https://www.frypto.io/support"}
										target="blank"
									>
										{t("header.support")}
									</Nav.Link>
									<Nav.Link
										href={"https://www.frypto.io/"}
										target="blank"
									>
										{t("header.about")}
									</Nav.Link>

									<NavDropdown
										title={t("header.getBonus")}
										id="nav-dropdown"
										className="menuNavDropdown"
									>
										<NavDropdown.Item
											eventKey="4.1"
											href={
												process.env.PUBLIC_URL +
												"/referral"
											}
										>
											{t("header.ref")}
										</NavDropdown.Item>
										<NavDropdown.Item
											eventKey="4.2"
											href={
												process.env.PUBLIC_URL +
												"/lucky-wheel"
											}
										>
											{t("header.wheel")}
										</NavDropdown.Item>
									</NavDropdown>
								</div>
								<div className="only-desktop">
									<div className="h-right d-flex align-items-center order-1 m-2">
										{auth?.access_token ? (
											<>
												<div className="d-flex mx-2 mx-lg-3">
													<Link
														className="nav-link text-primary collapsed"
														to={
															process.env
																.PUBLIC_URL +
															"/wallet-page"
														}
														title="Wallet"
														data-tip={t(
															"header.walletTooltip"
														)}
													>
														<div
															className={
																styles.iconText
															}
														>
															<svg
																width="26"
																height="24"
																viewBox="0 0 26 24"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M17.3333 14C17.9111 14 18.3889 13.8111 18.7667 13.4333C19.1444 13.0556 19.3333 12.5778 19.3333 12C19.3333 11.4222 19.1444 10.9444 18.7667 10.5667C18.3889 10.1889 17.9111 10 17.3333 10C16.7556 10 16.2778 10.1889 15.9 10.5667C15.5222 10.9444 15.3333 11.4222 15.3333 12C15.3333 12.5778 15.5222 13.0556 15.9 13.4333C16.2778 13.8111 16.7556 14 17.3333 14ZM2.66667 24C1.93333 24 1.30533 23.7391 0.782667 23.2173C0.260889 22.6947 0 22.0667 0 21.3333V2.66667C0 1.93333 0.260889 1.30533 0.782667 0.782667C1.30533 0.260889 1.93333 0 2.66667 0H21.3333C22.0667 0 22.6947 0.260889 23.2173 0.782667C23.7391 1.30533 24 1.93333 24 2.66667V6H21.3333V2.66667H2.66667V21.3333H21.3333V18H24V21.3333C24 22.0667 23.7391 22.6947 23.2173 23.2173C22.6947 23.7391 22.0667 24 21.3333 24H2.66667ZM13.3333 18.6667C12.6 18.6667 11.9724 18.4058 11.4507 17.884C10.928 17.3613 10.6667 16.7333 10.6667 16V8C10.6667 7.26667 10.928 6.63867 11.4507 6.116C11.9724 5.59422 12.6 5.33333 13.3333 5.33333H22.6667C23.4 5.33333 24.028 5.59422 24.5507 6.116C25.0724 6.63867 25.3333 7.26667 25.3333 8V16C25.3333 16.7333 25.0724 17.3613 24.5507 17.884C24.028 18.4058 23.4 18.6667 22.6667 18.6667H13.3333ZM22.6667 16V8H13.3333V16H22.6667Z"
																	fill="#939393"
																/>
															</svg>
															<span>
																{t(
																	"yourWallet"
																)}
															</span>
														</div>
													</Link>
												</div>

												<Dropdown className="dropdown user-profile ml-2 ml-sm-3 d-flex align-items-center mx-2 mx-lg-3">
													<Dropdown.Toggle
														as="dd"
														className="nav-link dropdown-toggle pulse p-0 mg-0"
														role="button"
														data-bs-toggle="dropdown"
														data-bs-display="static"
													>
														<div
															className={
																styles.headerDropdownMenu
															}
														>
															<img
																src={
																	user?.selectedAvatar
																		? require(`../../assets/images/avatar-${user?.selectedAvatar}.png`)
																		: Avatar
																}
																alt="profile"
															/>
															<svg
																width="12"
																height="7"
																viewBox="0 0 12 7"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M5.9997 6.95022C5.86636 6.95022 5.74136 6.92922 5.6247 6.88722C5.50803 6.84589 5.3997 6.77522 5.2997 6.67522L0.674696 2.05022C0.491363 1.86689 0.40403 1.63755 0.412696 1.36222C0.420696 1.08755 0.516363 0.858554 0.699696 0.675221C0.883029 0.491887 1.11636 0.40022 1.3997 0.40022C1.68303 0.40022 1.91636 0.491887 2.0997 0.675221L5.9997 4.57522L9.9247 0.65022C10.108 0.466887 10.3374 0.37922 10.6127 0.38722C10.8874 0.395887 11.1164 0.491887 11.2997 0.675221C11.483 0.858554 11.5747 1.09189 11.5747 1.37522C11.5747 1.65855 11.483 1.89189 11.2997 2.07522L6.6997 6.67522C6.5997 6.77522 6.49136 6.84589 6.3747 6.88722C6.25803 6.92922 6.13303 6.95022 5.9997 6.95022Z"
																	fill="#939393"
																/>
															</svg>
														</div>
													</Dropdown.Toggle>
													<Dropdown.Menu className=" dropdown-animation dropdown-menu-end p-0 m-0">
														<div className="contentContainerMedium w280">
															<div>
																<div className="d-flex py-2 flex-column align-items-center">
																	<img
																		className="avatar rounded-circle"
																		src={
																			user?.selectedAvatar
																				? require(`../../assets/images/avatar-${user?.selectedAvatar}.png`)
																				: Avatar
																		}
																		alt="profile"
																		width={
																			24
																		}
																	/>
																	<div className="flex-fill">
																		<span
																			className={
																				styles.emailText
																			}
																			data-tip={
																				user?.email
																			}
																		>
																			{
																				user?.email
																			}
																		</span>
																	</div>
																</div>
															</div>
															<div className="list-group list-dropdown">
																<Link
																	to={
																		process
																			.env
																			.PUBLIC_URL +
																		"/account-page"
																	}
																	className="dropdown-item"
																>
																	{t(
																		"header.account"
																	)}
																</Link>
																<Link
																	to={
																		process
																			.env
																			.PUBLIC_URL +
																		"/api-management"
																	}
																	className="dropdown-item"
																>
																	{t(
																		"header.apiManage"
																	)}
																</Link>
																<div
																	className="dropdown-item"
																	onClick={
																		logOut
																	}
																>
																	{t(
																		"header.signout"
																	)}
																</div>
															</div>
														</div>
													</Dropdown.Menu>
												</Dropdown>
												<Dropdown className="dropdown">
													<Dropdown.Toggle
														as="s"
														className="nav-link dropdown-toggle pulse"
														role="button"
														data-bs-toggle="dropdown"
													>
														<img
															src={require(`../../assets/images/flag/${selectedLang}.png`)}
															alt=""
															data-tip={t(
																"header.langTooltip"
															)}
															width={32}
														/>
													</Dropdown.Toggle>
													<Dropdown.Menu className="dropdown-menu border-0 dropdown-animation dropdown-menu-md-end m-0">
														<div className="contentContainerMedium">
															<ul className="list-unstyled mg-0 py-2 px-3">
																{LANG_LIST?.map(
																	(lang) => (
																		<li
																			key={
																				lang?.code
																			}
																			onClick={() =>
																				setSelectedLang(
																					lang?.code
																				)
																			}
																		>
																			<div
																				className={
																					styles.flag
																				}
																			>
																				<img
																					width={
																						24
																					}
																					src={require(`../../assets/images/flag/${lang?.code}.png`)}
																					alt=""
																				/>
																				<span>
																					{
																						lang?.name
																					}
																				</span>
																			</div>
																		</li>
																	)
																)}
															</ul>
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</>
										) : (
											<div className="col-lg-12 d-flex justify-content-between">
												<Dropdown className="dropdown mx-2 mx-lg-3">
													<Dropdown.Toggle
														as="s"
														className="nav-link dropdown-toggle pulse csFlag"
														role="button"
														data-bs-toggle="dropdown"
													>
														<img
															src={require(`../../assets/images/flag/${selectedLang}.png`)}
															alt=""
															data-tip={t(
																"header.langTooltip"
															)}
															width={27}
														/>
													</Dropdown.Toggle>
													<Dropdown.Menu className="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-md-end m-0">
														<div className="card border-0 ">
															<ul className="list-unstyled mg-0 py-2 px-3">
																{LANG_LIST?.map(
																	(lang) => (
																		<li
																			key={
																				lang?.code
																			}
																			onClick={() =>
																				setSelectedLang(
																					lang?.code
																				)
																			}
																		>
																			<span>
																				<img
																					width={
																						24
																					}
																					src={require(`../../assets/images/flag/${lang?.code}.png`)}
																					alt=""
																				/>{" "}
																				{
																					lang?.name
																				}
																			</span>
																		</li>
																	)
																)}
															</ul>
														</div>
													</Dropdown.Menu>
												</Dropdown>
												<div className={styles.authBtn}>
													<Link
														to={
															process.env
																.PUBLIC_URL +
															"/sign-in"
														}
													>
														<button
															type="button"
															className="btnLight"
														>
															{t("header.signin")}
														</button>
													</Link>
													<Link
														to={
															process.env
																.PUBLIC_URL +
															"/sign-up"
														}
													>
														<button
															type="button"
															className="btnDark"
														>
															{t("header.signup")}
														</button>
													</Link>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="only-mobile">
									<div className={styles.mobileMenu}>
										{auth?.access_token ? (
											<div className={styles.authMenu}>
												<Nav.Link
													href={
														process.env.PUBLIC_URL +
														"/wallet-page"
													}
													className="dropdown-item"
												>
													{t("yourWallet")}
												</Nav.Link>
												<Nav.Link
													href={
														process.env.PUBLIC_URL +
														"/account-page"
													}
													className="dropdown-item"
												>
													{t("header.account")}
												</Nav.Link>
												<Nav.Link
													href={
														process.env.PUBLIC_URL +
														"/api-management"
													}
													className="dropdown-item"
												>
													{t("header.apiManage")}
												</Nav.Link>
												<div
													className="dropdown-item"
													onClick={logOut}
												>
													{t("header.signout")}
												</div>
												<ul className="list-unstyled mg-0 py-2 px-3">
													{LANG_LIST?.map((lang) => (
														<li
															key={lang?.code}
															onClick={() =>
																setSelectedLang(
																	lang?.code
																)
															}
														>
															<div
																className={
																	styles.flag
																}
															>
																<img
																	width={24}
																	src={require(`../../assets/images/flag/${lang?.code}.png`)}
																	alt=""
																/>
																<span>
																	{lang?.name}
																</span>
															</div>
														</li>
													))}
												</ul>
											</div>
										) : (
											<div className={styles.unAuthMenu}>
												<Nav.Link
													href={
														process.env.PUBLIC_URL +
														"/sign-in"
													}
												>
													<button
														type="button"
														className="btnLight"
													>
														{t("header.signin")}
													</button>
												</Nav.Link>
												<Nav.Link
													href={
														process.env.PUBLIC_URL +
														"/sign-up"
													}
												>
													<button
														type="button"
														className="btnDark"
													>
														{t("header.signup")}
													</button>
												</Nav.Link>
												<div>
													{LANG_LIST?.map((lang) => (
														<div
															key={lang?.code}
															onClick={() =>
																setSelectedLang(
																	lang?.code
																)
															}
														>
															<span>
																<img
																	width={24}
																	src={require(`../../assets/images/flag/${lang?.code}.png`)}
																	alt=""
																/>{" "}
																{lang?.name}
															</span>
														</div>
													))}
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</Offcanvas.Body>
					</Navbar.Offcanvas>
				</div>
			</Navbar>
		</>
	);
}

export default Header;
