import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import WalletHead from "../../Component/WalletPage/WalletHead";
import TransactionHistory from "../../Component/WalletPage/TransactionHistory";
import DepositCrypto from "../../Component/WalletPage/DepositCrypto";

function DepositPage() {
	const [t] = useTranslation("wallet");

	return (
		<div className="walletLayout">
			<div className="defaultContainer">
				<WalletHead title={`${t("deposit")} crypto`} />
			</div>

			<DepositCrypto />

			<div className="contentContainer defaultContainer">
				<div className="contentHead">{t("depositHistory")}</div>
				<div className="contentBody">
					<TransactionHistory type="IN" />
				</div>
			</div>
		</div>
	);
}
export default DepositPage;
