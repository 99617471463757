import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetUserInfoQuery } from "../../services/auth";
import { useGetSpotAssetsQuery } from "../../services/spot";
import { useGetWalletAssetsQuery } from "../../services/wallet";
import styles from "./styles.module.scss";
import { numberFormat } from "../../utils/formatNumber";
import LoadingDot from "../Animation/LoadingDot";
import { useGetPNLDetailQuery } from "../../services/wallet";
import { getPastDate } from "../../utils/date";

const PNLOverview = () => {
	const [t] = useTranslation("wallet");
	const { data: user } = useGetUserInfoQuery();
	const { data: spotAssets } = useGetSpotAssetsQuery();
	const { data: fundingAssets } = useGetWalletAssetsQuery();
	const [totalBalance, setTotalBalance] = useState(0);
	const [balanceDiff1D, setBalanceDiff1D] = useState(0);
	const [percentDiff1D, setpercentDiff1D] = useState(0);

	const [balanceDiff30D, setBalanceDiff30D] = useState(0);
	const [percentDiff30D, setpercentDiff30D] = useState(0);

	const { data: pnl1d } = useGetPNLDetailQuery({
		start: getPastDate(1, true),
		end: getPastDate(0, true),
	});

	const { data: pnl30d } = useGetPNLDetailQuery({
		start: getPastDate(30, true),
		end: getPastDate(0, true),
	});

	useEffect(() => {
		if (spotAssets && fundingAssets) {
			let total = 0;
			for (let i = 0; i < spotAssets.length; i++) {
				total += spotAssets[i].inBTC;
			}
			for (let i = 0; i < fundingAssets.length; i++) {
				total += fundingAssets[i].inBTC;
			}
			setTotalBalance(total);
		}
	}, [spotAssets, fundingAssets]);

	useEffect(() => {
		if (pnl1d?.result?.length > 0) {
			let total = 0;
			for (let i = 0; i < pnl1d.result[0].length; i++) {
				total += parseFloat(pnl1d.result[0][i]?.valueInUSD);
			}

			setBalanceDiff1D((user?.spotBalance - total).toFixed(2));
			setpercentDiff1D(
				(((user?.spotBalance - total) / total) * 100).toFixed(2)
			);
		}
	}, [pnl1d, user]);

	useEffect(() => {
		if (pnl30d?.result?.length > 0 && user) {
			let totalStartDay = 0;

			for (let i = 0; i < pnl30d.result[0].length; i++) {
				totalStartDay += parseFloat(pnl30d.result[0][i]?.valueInUSD);
			}

			setBalanceDiff30D(
				(
					user?.spotBalance +
					user?.fundingBalance -
					totalStartDay
				).toFixed(2)
			);
			setpercentDiff30D(
				(
					((user?.spotBalance +
						user?.fundingBalance -
						totalStartDay) /
						totalStartDay) *
					100
				).toFixed(2)
			);
		}
	}, [pnl30d, user]);

	return (
		<>
			{user ? (
				<div className="contentContainerMedium">
					<div className={styles.pnlOverview}>
						<div>
							<div>{t("pnl.balance")}</div>
							<div>
								{numberFormat(totalBalance.toFixed(6)) + " BTC"}
							</div>
						</div>
						<div>
							<div>{t("pnl.estimatedValue")}</div>
							<div>
								{"$" +
									numberFormat(
										(
											user?.spotBalance +
											user?.fundingBalance
										).toFixed(2)
									)}
							</div>
						</div>
						<div>
							<div>{t("pnl.1dpnl")}</div>
							<div
								className={
									balanceDiff1D < 0 ? "priceDown" : "priceUp"
								}
							>
								<span>{`${
									balanceDiff1D < 0 ? "-" : "+"
								}$${numberFormat(
									Math.abs(balanceDiff1D)
								)}`}</span>
								<span>{`(${
									balanceDiff1D < 0 ? "-" : "+"
								}${numberFormat(
									Math.abs(percentDiff1D)
								)}%)`}</span>
							</div>
						</div>
						<div>
							<div>{t("pnl.30dpnl")}</div>
							<div
								className={
									balanceDiff30D < 0 ? "priceDown" : "priceUp"
								}
							>
								<span>{`${
									balanceDiff30D < 0 ? "-" : "+"
								}$${numberFormat(
									Math.abs(balanceDiff30D)
								)}`}</span>
								<span>{`(${
									balanceDiff30D < 0 ? "-" : "+"
								}${numberFormat(
									Math.abs(percentDiff30D)
								)}%)`}</span>
							</div>
						</div>
					</div>
				</div>
			) : (
				<LoadingDot />
			)}
		</>
	);
};

export default PNLOverview;
