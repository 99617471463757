import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useGetSpotOrdersQuery } from "../../services/spot";

import { useTranslation } from "react-i18next";
import { numberFormat } from "../../utils/formatNumber";

import styles from "./styles.module.scss";
import CopyComponent from "../Common/CopyComponent";
import StatusText from "../Common/StatusText";
import { useWindowWidth } from "@react-hook/window-size";

function TradingHistory(props) {
	const [t] = useTranslation("trade");
	const width = useWindowWidth();

	const [page, setPage] = useState(1);

	const {
		data: orders,
		isLoading,
		refetch: refetchOrdersData,
	} = useGetSpotOrdersQuery({
		skip: (page - 1) * 10,
		status: "",
	});

	const handlePageChange = (newPage) => {
		setPage(newPage);
	};

	const defaultCol = [
		{
			name: "ID",
			selector: (row) => row.id,
			cell: (row) => (
				<CopyComponent
					text={row?.id?.substring(0, 6) + "..."}
					copyText={row.id}
				/>
			),
		},
		{
			name: t("time"),
			selector: (row) => row.createdAt,
			width: "200px",
		},
		{
			name: t("tradePair"),
			selector: (row) => row.baseAsset + row.quoteAsset,
			width: "100px",
		},
		{
			name: t("type"),
			selector: (row) => (row.side === "SELL" ? t("sell") : t("buy")),
			center: true,
			cell: (row) => (
				<>
					{row.side === "SELL" ? (
						<div className={"sellBadge"}>
							<span>{t("sell")}</span>
						</div>
					) : (
						<div className={"buyBadge"}>
							<span>{t("buy")}</span>
						</div>
					)}
				</>
			),
		},
		{
			name: t("order"),
			selector: (row) => row.type,
			cell: (row) => <span>{row.type}</span>,
		},
		{
			name: t("price"),
			selector: (row) =>
				numberFormat(
					row?.status === "FILLED"
						? parseFloat(
								(
									parseFloat(row.filledQuoteQuantity) /
									parseFloat(row.filledQuantity)
								).toFixed(6)
						  )
						: parseFloat(parseFloat(row.price).toFixed(6))
				),
		},
		{
			name: t("quantity"),
			selector: (row) =>
				numberFormat(
					row?.status === "FILLED" ? row.filledQuantity : row.quantity
				),
		},

		{
			name: t("status"),
			selector: (row) => row.status,
			cell: (row) => (
				<>
					<StatusText status={row?.status} />
				</>
			),
		},
	];

	const statusOrder = (status) => {
		switch (status) {
			case "NEW":
				return t("orderStatus.new");
			case "FILLED":
				return t("orderStatus.filled");
			case "CANCELED":
				return t("orderStatus.cancelled");
			case "PROCESSING":
				return t("orderStatus.processing");
			default:
				return t("orderStatus.error");
		}
	};

	const paginationOption = {
		noRowsPerPage: true,
		rangeSeparatorText: "/",
	};

	useEffect(() => {
		refetchOrdersData();
	}, [page]);

	return (
		<>
			{width > 1200 ? (
				<DataTable
					columns={defaultCol}
					data={orders?.data}
					selectableRows={false}
					highlightOnHover={true}
					pagination={true}
					paginationServer
					paginationComponentOptions={paginationOption}
					onChangePage={handlePageChange}
					paginationTotalRows={orders?.count || orders?.length}
					className="historyTable"
				/>
			) : (
				<div>
					{orders?.data?.map((data) => (
						<HistoryItem key={data?.id} data={data} />
					))}
				</div>
			)}
		</>
	);
}

const HistoryItem = ({ data }) => {
	const [t] = useTranslation("trade");

	return (
		<div className={styles.listItem}>
			<div>
				<div>
					<div>{data?.baseAsset + data?.quoteAsset}</div>
					<>
						{data.side === "SELL" ? (
							<div className={"sellBadge"}>
								<span>{t("sell")}</span>
							</div>
						) : (
							<div className={"buyBadge"}>
								<span>{t("buy")}</span>
							</div>
						)}
					</>
				</div>
			</div>
			<div>
				<div>
					<div>{t("price")}</div>
					<div>
						{numberFormat(
							data?.status === "FILLED"
								? parseFloat(
										(
											parseFloat(
												data.filledQuoteQuantity
											) / parseFloat(data.filledQuantity)
										).toFixed(6)
								  )
								: parseFloat(parseFloat(data.price).toFixed(6))
						)}
					</div>
				</div>
				<div>
					<div>{t("quantity")}</div>
					<div>
						{numberFormat(
							data?.status === "FILLED"
								? data.filledQuantity
								: data.quantity
						)}
					</div>
				</div>
				<div>
					<div>{t("status")}</div>
					<div>
						<StatusText status={data?.status} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default TradingHistory;
