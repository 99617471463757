import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import Table from "../Common/Table";
import { useEffect } from "react";
import { useGetPNLDetailQuery } from "../../services/wallet";
import { getPastDate } from "../../utils/date";
import AssetPNLDetail from "../PNL/AssetsPNLDetail";
import { numberFormat } from "../../utils/formatNumber";
import { useWindowSize } from "@react-hook/window-size";
import AssetsPNLDetail from "../PNL/AssetsPNLDetail";

const AssetsTable = ({ assets, withPNL = false }) => {
	return (
		<>{withPNL ? <WithPNL assets={assets} /> : <NoPNL assets={assets} />}</>
	);
};

const NoPNL = ({ assets }) => {
	const [width] = useWindowSize();

	return (
		<>
			{width > 1200 ? (
				<Table
					data={assets}
					type="ASSETSLIST"
					className="historyTable"
				/>
			) : (
				<>
					{assets?.map((item) => (
						<AssetItem
							key={item.tokenSymbol}
							data={item}
							withPNL={true}
						/>
					))}
				</>
			)}
		</>
	);
};

const WithPNL = ({ assets }) => {
	const [t] = useTranslation("common");
	const [width] = useWindowSize();
	const [active, setActive] = useState("value");
	const [data, setData] = useState([]);

	const [openModal, setOpenModal] = useState(false);
	const [selectedToken, setSelectedToken] = useState();

	const { data: pnl } = useGetPNLDetailQuery({
		start: getPastDate(1),
		end: getPastDate(0),
	});

	const handleCloseModal = () => {
		setSelectedToken(false);
		setOpenModal(false);
	};

	const handleSelectToken = (value) => setSelectedToken(value);

	useEffect(() => {
		if (selectedToken) setOpenModal(true);
	}, [selectedToken]);

	useEffect(() => {
		if (assets?.length > 0 && pnl?.result?.length > 0) {
			let temp = [];
			assets.forEach((element) => {
				if (element.tokenSymbol !== "BUSD") {
					const filtered = pnl.result[0].filter(
						(item) => item.token === element.tokenSymbol
					)[0];

					let extraData, extraData1, extraData2, extraData3;

					if (width > 1200) {
						extraData =
							active === "value"
								? (
										parseFloat(element?.inUSD || 0) -
										parseFloat(filtered?.valueInUSD || 0)
								  ).toFixed(2)
								: (
										parseFloat(element?.amount || 0) -
										parseFloat(filtered?.amount || 0)
								  ).toFixed(2);
						extraData1 =
							active === "value"
								? (
										(extraData /
											parseFloat(
												filtered?.valueInUSD || 0
											)) *
										100
								  ).toFixed(2)
								: (
										(extraData /
											parseFloat(filtered?.amount || 0)) *
										100
								  ).toFixed(2);
						if (extraData1 > 100) extraData1 = 100;

						temp.push({
							...element,
							extraData,
							extraData1,
						});
					} else {
						extraData = (
							parseFloat(element?.inUSD || 0) -
							parseFloat(filtered?.valueInUSD || 0)
						).toFixed(2);

						extraData1 = (
							(extraData /
								parseFloat(filtered?.valueInUSD || 0)) *
							100
						).toFixed(2);

						extraData2 = (
							parseFloat(element?.amount || 0) -
							parseFloat(filtered?.amount || 0)
						).toFixed(2);

						extraData3 = (
							(extraData2 / parseFloat(filtered?.amount || 0)) *
							100
						).toFixed(2);

						if (extraData1 > 100) extraData1 = 100;
						if (extraData3 > 100) extraData3 = 100;
						temp.push({
							...element,
							extraData,
							extraData1,
							extraData2,

							extraData3,
						});
					}
				} else {
					temp.push({ ...element });
				}
			});
			setData(temp);
		}
	}, [assets, pnl, active, width]);
	return (
		<>
			{width > 1200 ? (
				<>
					<div className={styles.pnlSelect}>
						<div
							className={
								active === "value"
									? styles.pnlActive
									: undefined
							}
							onClick={() => setActive("value")}
						>
							{t("pnl.changeValue")}
						</div>
						<div
							className={
								active === "amount"
									? styles.pnlActive
									: undefined
							}
							onClick={() => setActive("amount")}
						>
							{t("pnl.changeAmount")}
						</div>
					</div>
					<Table
						data={data}
						type="SPOTASSETSLIST"
						className="historyTable"
						pnlOptions={{
							extralabel:
								active === "value"
									? t("pnl.today")
									: t("pnl.changeAmount"),
						}}
						pnlTokenSelection={handleSelectToken}
					/>
				</>
			) : (
				<>
					{data?.map((item) => (
						<AssetItem
							key={item.tokenSymbol}
							data={item}
							withPNL={true}
							pnlTokenSelection={handleSelectToken}
						/>
					))}
				</>
			)}
			{selectedToken ? (
				<AssetPNLDetail
					openModal={openModal}
					handleClose={handleCloseModal}
					token={selectedToken}
				/>
			) : null}
		</>
	);
};

const AssetItem = ({ data, withPNL, pnlTokenSelection }) => {
	const [t] = useTranslation("common");

	return (
		<div
			className={styles.listItem}
			onClick={() => pnlTokenSelection(data?.tokenSymbol)}
		>
			<div>
				<div>
					<div>{data?.tokenSymbol}</div>
					<svg
						width="10"
						height="16"
						viewBox="0 0 10 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M0.316206 15.6838C0.105402 15.473 0 15.2234 0 14.935C0 14.6472 0.105402 14.3979 0.316206 14.1871L6.49275 8.01054L0.295125 1.81291C0.098375 1.61616 0 1.37022 0 1.0751C0 0.779974 0.105402 0.527009 0.316206 0.316206C0.527009 0.105402 0.776601 0 1.06498 0C1.3528 0 1.60211 0.105402 1.81291 0.316206L8.89592 7.42029C8.98024 7.50461 9.04011 7.59596 9.07552 7.69433C9.11037 7.79271 9.1278 7.89811 9.1278 8.01054C9.1278 8.12297 9.11037 8.22837 9.07552 8.32675C9.04011 8.42512 8.98024 8.51647 8.89592 8.60079L1.79183 15.7049C1.59508 15.9016 1.3528 16 1.06498 16C0.776601 16 0.527009 15.8946 0.316206 15.6838Z"
							fill="#939393"
						/>
					</svg>
				</div>
			</div>
			<div>
				<div>
					<div>{t("table.quantity")}</div>
					<div>{numberFormat(parseFloat(data?.amount))}</div>
				</div>
				<div>
					<div>{t("table.priceInUSD")}</div>
					<div>{numberFormat(parseFloat(data?.inUSD))}</div>
				</div>
				{withPNL ? (
					<>
						<div>
							<div>{t("pnl.changeValue")}</div>
							<div
								className={
									data.extraData < 0 ? "priceDown" : "priceUp"
								}
							>
								<span>{`${
									data.extraData < 0 ? "-" : "+"
								}${numberFormat(
									Math.abs(data.extraData)
								)}`}</span>
								<span>{`(${
									data.extraData < 0 ? "-" : "+"
								}${numberFormat(
									Math.abs(data.extraData1)
								)}%)`}</span>
							</div>
						</div>
						<div>
							<div>{t("pnl.changeAmount")}</div>
							<div
								className={
									data.extraData < 0 ? "priceDown" : "priceUp"
								}
							>
								<span>{`${
									data.extraData2 < 0 ? "-" : "+"
								}${numberFormat(
									Math.abs(data.extraData2)
								)}`}</span>
								<span>{`(${
									data.extraData3 < 0 ? "-" : "+"
								}${numberFormat(
									Math.abs(data.extraData3)
								)}%)`}</span>
							</div>
						</div>
					</>
				) : null}
			</div>
		</div>
	);
};

export default AssetsTable;
