export const insertSorted = (arr, item, comparator) => {
	if (comparator == null) {
		// emulate the default Array.sort() comparator
		comparator = function (a, b) {
			if (typeof a !== "string") a = String(a);
			if (typeof b !== "string") b = String(b);
			return a > b ? 1 : a < b ? -1 : 0;
		};
	}

	// get the index we need to insert the item at
	let min = 0;
	let max = arr.length;
	let index = Math.floor((min + max) / 2);
	while (max > min) {
		if (comparator(item, arr[index]) < 0) {
			max = index;
		} else {
			min = index + 1;
		}
		index = Math.floor((min + max) / 2);
	}

	// insert the item
	arr.length++;
	arr.copyWithin(index + 1, index);
	arr[index] = item;
	return arr;
};
