import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { useGetUserInfoQuery } from "../../services/auth";
import { numberFormat } from "../../utils/formatNumber";
import AssetsChart from "./AssetsChart";
import { useGetSpotAssetsQuery } from "../../services/spot";
import AssetsTable from "./AssetsTable";
import TradingHistory from "./TradingHistory";
import TransactionHistory from "./TransactionHistory";
import TooltipComponent from "../Common/TooltipComponent";
import LoadingDot from "../Animation/LoadingDot";

const SpotWallet = () => {
	const [t] = useTranslation("wallet");
	const { data: user } = useGetUserInfoQuery();
	const { data: assets } = useGetSpotAssetsQuery();

	const [labels, setLabels] = useState([""]);
	const [series, setSeries] = useState([0]);

	const [totalBalance, setTotalBalance] = useState(0);

	useEffect(() => {
		if (assets?.length > 0) {
			let total = 0;
			for (let i = 0; i < assets?.length; i++) {
				total += assets[i]?.inBTC;
			}
			setTotalBalance(total);
		}
	}, [assets]);

	useEffect(() => {
		if (assets) {
			let filtered = assets
				.filter((item) => parseFloat(item?.amount) > 0)
				.sort((a, b) => parseFloat(b.inUSD) - parseFloat(a.inUSD));

			if (filtered?.length > 8) {
				let temp = [];

				temp = filtered.splice(7, filtered.length - 7);

				const reducedTemp = temp.reduce((a, b) => {
					return {
						tokenSymbol: t("others"),
						inUSD: parseFloat(a.inUSD) + parseFloat(b.inUSD),
					};
				});

				filtered = [...filtered, reducedTemp];
			}
			setLabels(filtered.map((item) => item?.tokenSymbol));
			setSeries(filtered.map((item) => parseFloat(item?.inUSD)));
		}
	}, [assets, t]);

	return (
		<>
			{assets ? (
				<>
					<div className="contentContainer">
						<div className="defaultContainer">
							<div className={styles.contentHead}>
								{t("spotWallet")}
							</div>
							<div className={styles.contentBody}>
								<div className={styles.walletDetail}>
									<div>
										<div>
											<div>{t("balance") + ":"}</div>
											<div>
												{numberFormat(
													totalBalance.toFixed(6)
												) + " BTC"}
											</div>
										</div>
										<div>
											<div>{t("value") + ":"}</div>
											<div>
												{"$" +
													numberFormat(
														user?.spotBalance?.toFixed(
															2
														)
													)}
											</div>
										</div>
									</div>
									<AssetsChart
										labels={labels}
										series={series}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="contentContainer">
						<div className="defaultContainer">
							<div className={styles.contentHead}>
								{t("assetsList")}
							</div>
							<div className={styles.contentBody}>
								{assets && (
									<AssetsTable
										assets={assets.filter(
											(item) =>
												parseFloat(item?.amount) > 0
										)}
										withPNL={true}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="contentContainer">
						<div className="defaultContainer">
							<div className={styles.contentHead}>
								{t("tradingHistory")}
							</div>
							<div className={styles.contentBody}>
								<TradingHistory />
							</div>
						</div>
					</div>
					<div className="contentContainer">
						<div className="defaultContainer">
							<div className={styles.contentHead}>
								<TooltipComponent
									text={t("tooltip.depositHistory")}
								>
									<div className={styles.headText}>
										<span>{t("depositHistory")}</span>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2Z"
												fill="#FDFCFF"
												stroke="#363638"
											/>
											<circle
												cx="12"
												cy="7"
												r="1"
												fill="#363638"
											/>
											<rect
												x="11"
												y="10"
												width="2"
												height="8"
												rx="1"
												fill="#363638"
											/>
										</svg>
									</div>
								</TooltipComponent>
							</div>

							<div className={styles.contentBody}>
								<TransactionHistory type="IN" />
							</div>
						</div>
					</div>
				</>
			) : (
				<LoadingDot />
			)}
		</>
	);
};

export default SpotWallet;
