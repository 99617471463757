import React, { useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import LitePriceDetail from "../../Component/Exchange/LitePriceDetail";
import LiteChart from "../../Component/Exchange/LiteChart";
import TradeComponent from "../../Component/Exchange/TradeComponent";
import { useWindowSize } from "@react-hook/window-size";
import MobileTradeComponent from "../../Component/Exchange/MobileTradeComponent";
import autoAnimate from "@formkit/auto-animate";

const Lite = ({ openSelectMarket }) => {
	const [width] = useWindowSize();
	const parent = useRef(null);

	useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent]);
	return (
		<div className={styles.tradeContainer} ref={parent}>
			<div>
				<LitePriceDetail openSelectMarket={openSelectMarket} />
				<LiteChart />
			</div>
			<>
				{width > 1200 ? (
					<div className={styles.liteRight}>
						<TradeComponent />
					</div>
				) : (
					<MobileTradeComponent />
				)}
			</>
		</div>
	);
};

export default Lite;
