import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Tabs, Tab } from "react-bootstrap";
import NavButtons from "../../Component/WalletPage/NavButtons";
import OverviewWallet from "../../Component/WalletPage/OverviewWallet";
import SpotWallet from "../../Component/WalletPage/SpotWallet";
import FundingWallet from "../../Component/WalletPage/FundingWallet";
import autoAnimate from "@formkit/auto-animate";
function WalletPage() {
	const [t] = useTranslation("wallet");

	const [activeTab, setActiveTab] = useState();
	const parent = useRef(null);

	useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent]);

	useEffect(() => {
		setActiveTab(localStorage.getItem("walletTab") || "OVERVIEW");
	}, []);

	useEffect(() => {
		localStorage.setItem("walletTab", activeTab);
	}, [activeTab]);

	return (
		<div className={styles.walletConatainer} ref={parent}>
			<div className="defaultContainer">
				<Tabs
					activeKey={activeTab}
					onSelect={(key) => setActiveTab(key)}
					className={styles.walletTabs}
				>
					<Tab eventKey={"OVERVIEW"} title={t("overview")} />
					<Tab eventKey={"SPOT"} title={t("spotWallet")} />
					<Tab eventKey={"FUNDING"} title={t("fundingWallet")} />
				</Tabs>
				<NavButtons />
			</div>
			{activeTab === "OVERVIEW" ? <OverviewWallet /> : null}
			{activeTab === "SPOT" ? <SpotWallet /> : null}
			{activeTab === "FUNDING" ? <FundingWallet /> : null}
		</div>
	);
}
export default WalletPage;
