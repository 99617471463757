import React from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import PriceIntro1 from "../../assets/images/price-intro1.svg";
import PriceIntro2 from "../../assets/images/price-intro2.svg";
import PriceIntro3 from "../../assets/images/price-intro3.svg";
import PriceIntro4 from "../../assets/images/price-intro4.svg";

const Guide = () => {
	const [t] = useTranslation("common");
	return (
		<div className={styles.guide}>
			<div className="contentContainer">
				<div className="contentHead">{t("luckyWheel.guide")}</div>
				<div className="contentBody">
					<div className={styles.guideContent}>
						<div>
							<div>
								<div>01</div>
								<div>{t("luckyWheel.intro1")}</div>
							</div>
							<div>
								<div>{t("luckyWheel.intro1Text.text1")}</div>
								<div>{t("luckyWheel.intro1Text.text2")}</div>
								<div>{t("luckyWheel.intro1Text.text3")}</div>
							</div>
						</div>
						<div>
							<div>
								<div>02</div>
								<div>{t("luckyWheel.intro2")}</div>
							</div>
							<div className={styles.rewardIntro}>
								<div>
									<img alt="" src={PriceIntro1} />
									<div>
										{t("luckyWheel.intro2Text.text1")}
									</div>
								</div>
								<div>
									<img alt="" src={PriceIntro2} />

									<div>
										{t("luckyWheel.intro2Text.text2")}
									</div>
								</div>
								<div>
									<img alt="" src={PriceIntro3} />

									<div>
										{t("luckyWheel.intro2Text.text3")}
									</div>
								</div>
								<div>
									<img alt="" src={PriceIntro4} />
									<div>
										{t("luckyWheel.intro2Text.text4")}
									</div>
								</div>
							</div>
						</div>
						<div>
							<div>
								<div>03</div>
								<div>{t("luckyWheel.howToJoin")}</div>
							</div>
							<div>
								<div>{t("luckyWheel.howToJoinText.text1")}</div>
								<div>{t("luckyWheel.howToJoinText.text2")}</div>
								<div>{t("luckyWheel.howToJoinText.text3")}</div>
							</div>
						</div>
						<div>
							<div>
								<div>04</div>
								<div>{t("luckyWheel.notice")}</div>
							</div>
							<div>
								<div>{t("luckyWheel.noticeText.text1")}</div>
								<div>{t("luckyWheel.noticeText.text2")}</div>
								<div>{t("luckyWheel.noticeText.text3")}</div>
								<div>{t("luckyWheel.noticeText.text4")}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Guide;
