import React from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import styles from "./styles.module.scss";

const TooltipComponent = ({ text, content, place, children }) => {
	const id = "element" + Math.floor(Math.random() * 1000000000);
	return (
		<div>
			<div id={id} data-tooltip-content={text}>
				{children}
			</div>
			<Tooltip
				anchorId={id}
				place={place || "bottom"}
				className={styles.tooltipDefault}
			>
				{content}
			</Tooltip>
		</div>
	);
};

export default TooltipComponent;
