import React from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router";
import TooltipComponent from "../Common/TooltipComponent";
import { useTranslation } from "react-i18next";

const NavButtons = () => {
	const navigate = useNavigate();
	const [t] = useTranslation("wallet");
	return (
		<div className={styles.navBtnContainer}>
			<TooltipComponent text={t("tooltip.deposit")}>
				<button
					className="btnRoundSmall"
					onClick={() => navigate("/deposit-page")}
				>
					<svg
						width="14"
						height="14"
						viewBox="0 0 14 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M7 14C6.71667 14 6.47933 13.904 6.288 13.712C6.096 13.5207 6 13.2833 6 13V8H1C0.716667 8 0.479 7.904 0.287 7.712C0.0956668 7.52067 0 7.28333 0 7C0 6.71667 0.0956668 6.479 0.287 6.287C0.479 6.09567 0.716667 6 1 6H6V1C6 0.716667 6.096 0.479 6.288 0.287C6.47933 0.0956668 6.71667 0 7 0C7.28333 0 7.521 0.0956668 7.713 0.287C7.90433 0.479 8 0.716667 8 1V6H13C13.2833 6 13.5207 6.09567 13.712 6.287C13.904 6.479 14 6.71667 14 7C14 7.28333 13.904 7.52067 13.712 7.712C13.5207 7.904 13.2833 8 13 8H8V13C8 13.2833 7.90433 13.5207 7.713 13.712C7.521 13.904 7.28333 14 7 14Z"
							fill="currentColor"
						/>
					</svg>
				</button>
			</TooltipComponent>
			<TooltipComponent text={t("tooltip.withdraw")}>
				<button
					className="btnRoundSmall"
					onClick={() => navigate("/withdraw-page")}
				>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8 11.9998C7.71667 11.9998 7.47933 11.9038 7.288 11.7118C7.096 11.5205 7 11.2831 7 10.9998V3.8498L5.125 5.7248C4.925 5.9248 4.69167 6.0248 4.425 6.0248C4.15833 6.0248 3.91667 5.91647 3.7 5.6998C3.5 5.4998 3.40433 5.26214 3.413 4.9868C3.421 4.71214 3.51667 4.48314 3.7 4.2998L7.3 0.699804C7.4 0.599804 7.50833 0.528804 7.625 0.486804C7.74167 0.445471 7.86667 0.424805 8 0.424805C8.13333 0.424805 8.25833 0.445471 8.375 0.486804C8.49167 0.528804 8.6 0.599804 8.7 0.699804L12.3 4.2998C12.5 4.4998 12.5957 4.73714 12.587 5.0118C12.579 5.28714 12.4833 5.51647 12.3 5.6998C12.1 5.8998 11.8627 6.0038 11.588 6.0118C11.3127 6.02047 11.075 5.9248 10.875 5.7248L9 3.8498V10.9998C9 11.2831 8.90433 11.5205 8.713 11.7118C8.521 11.9038 8.28333 11.9998 8 11.9998ZM2 15.9998C1.45 15.9998 0.979333 15.8041 0.588 15.4128C0.196 15.0208 0 14.5498 0 13.9998V11.9998C0 11.7165 0.0956668 11.4788 0.287 11.2868C0.479 11.0955 0.716667 10.9998 1 10.9998C1.28333 10.9998 1.521 11.0955 1.713 11.2868C1.90433 11.4788 2 11.7165 2 11.9998V13.9998H14V11.9998C14 11.7165 14.096 11.4788 14.288 11.2868C14.4793 11.0955 14.7167 10.9998 15 10.9998C15.2833 10.9998 15.5207 11.0955 15.712 11.2868C15.904 11.4788 16 11.7165 16 11.9998V13.9998C16 14.5498 15.8043 15.0208 15.413 15.4128C15.021 15.8041 14.55 15.9998 14 15.9998H2Z"
							fill="currentColor"
						/>
					</svg>
				</button>
			</TooltipComponent>
			<TooltipComponent text={t("tooltip.transfer")}>
				<button
					className="btnRoundSmall"
					onClick={() => navigate("/transfer-page")}
				>
					<svg
						width="20"
						height="16"
						viewBox="0 0 20 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M14.5977 9.24111C14.4143 9.05778 14.3227 8.82445 14.3227 8.54111C14.3227 8.25778 14.4143 8.02445 14.5977 7.84111L16.4727 5.96611H10.2977C10.0143 5.96611 9.77699 5.87011 9.58566 5.67811C9.39366 5.48678 9.29766 5.24945 9.29766 4.96611C9.29766 4.68278 9.39366 4.44511 9.58566 4.25311C9.77699 4.06178 10.0143 3.96611 10.2977 3.96611H16.4727L14.5977 2.09111C14.4143 1.90778 14.3227 1.67011 14.3227 1.37811C14.3227 1.08678 14.4143 0.849446 14.5977 0.666113C14.781 0.48278 15.0143 0.391113 15.2977 0.391113C15.581 0.391113 15.8143 0.48278 15.9977 0.666113L19.5977 4.26611C19.6977 4.36611 19.7687 4.47445 19.8107 4.59111C19.852 4.70778 19.8727 4.83278 19.8727 4.96611C19.8727 5.09945 19.852 5.22445 19.8107 5.34111C19.7687 5.45778 19.6977 5.56611 19.5977 5.66611L15.9977 9.26611C15.8143 9.44945 15.581 9.53678 15.2977 9.52811C15.0143 9.52011 14.781 9.42445 14.5977 9.24111ZM4.59766 15.2661L0.997656 11.6661C0.897656 11.5661 0.82699 11.4578 0.785656 11.3411C0.743656 11.2244 0.722656 11.0994 0.722656 10.9661C0.722656 10.8328 0.743656 10.7078 0.785656 10.5911C0.82699 10.4744 0.897656 10.3661 0.997656 10.2661L4.59766 6.66611C4.78099 6.48278 5.01432 6.39111 5.29766 6.39111C5.58099 6.39111 5.81432 6.48278 5.99766 6.66611C6.18099 6.84945 6.27266 7.08678 6.27266 7.37811C6.27266 7.67011 6.18099 7.90778 5.99766 8.09111L4.12266 9.96611H10.2977C10.581 9.96611 10.8187 10.0618 11.0107 10.2531C11.202 10.4451 11.2977 10.6828 11.2977 10.9661C11.2977 11.2494 11.202 11.4868 11.0107 11.6781C10.8187 11.8701 10.581 11.9661 10.2977 11.9661H4.12266L5.99766 13.8411C6.18099 14.0244 6.27266 14.2578 6.27266 14.5411C6.27266 14.8244 6.18099 15.0578 5.99766 15.2411C5.81432 15.4244 5.58099 15.5201 5.29766 15.5281C5.01432 15.5368 4.78099 15.4494 4.59766 15.2661Z"
							fill="currentColor"
						/>
					</svg>
				</button>
			</TooltipComponent>
		</div>
	);
};

export default NavButtons;
