import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./config";

export const luckyWheelService = createApi({
	reducerPath: "luckyWheelService",
	baseQuery: baseQuery,
	endpoints: (builder) => ({
		getRewardList: builder.query({
			query: () => `wheel/config `,
		}),
		getRewardHistory: builder.query({
			query: ({ take = 10, skip = 0 }) =>
				`wheel/rewards?` + new URLSearchParams({ take, skip }),
		}),

		spin: builder.mutation({
			query: () => ({
				url: `wheel/spin`,
				method: "POST",
			}),
		}),

		createInviteLink: builder.mutation({
			query: () => ({
				url: `wheel/invitation/generate`,
				method: "POST",
			}),
		}),

		spinFromInviteLink: builder.mutation({
			query: (inviteId) => ({
				url: `wheel/invitation/spin?id=${inviteId}`,
				method: "POST",
			}),
		}),

		claimRewardFromInviteLink: builder.mutation({
			query: (data) => ({
				url: `wheel/invitation/claim`,
				method: "POST",
				body: data,
			}),
		}),

		getRemainingSpinFromInviteLink: builder.query({
			query: (inviteId) => `wheel/invitation/available/${inviteId}`,
		}),
	}),
});

export const {
	useGetRewardListQuery,
	useGetRewardHistoryQuery,
	useSpinMutation,
	useCreateInviteLinkMutation,
	useSpinFromInviteLinkMutation,
	useClaimRewardFromInviteLinkMutation,
	useGetRemainingSpinFromInviteLinkQuery,
} = luckyWheelService;
